import React, {useState, useEffect} from 'react';
import HeaderPage from "../../components/Header";
import axios from "axios";
import store from "store";
import {observer} from "mobx-react-lite";
import {Map, YMaps, Placemark, Circle} from "react-yandex-maps";
import { Row, Col, Button, Input, message, Modal } from 'antd';
import ImageGallery from 'react-image-gallery';
import Zoom from "../../components/modules/Zoom";
import pin from "../../images/pin.svg";
import advert from "../../store/advert";
import userStore from "../../store/userStore";
import {ReactComponent as HeartFilledIcon} from "../../images/heart_filled.svg";
import {ReactComponent as ProfileIcon} from "../../images/profile.svg";
import {ReactComponent as ShareIcon} from "../../images/share.svg";
import {ReactComponent as HomeIcon} from "../../images/home.svg";
import noPhoto from "../../images/placeholder-image.png";
import CategorySlider from "../../components/modules/CategorySlider";
import YandexShare from 'react-yandex-share';

const sitemes = message;

const UserAdvert = observer((props) => {

    const [adv, setAdv] = useState({});
    const [upload, setUpload] = useState(0);
    const [message, setMessage] = useState({
        display: false,
        message: ""
    });
    const [share, setShare] = useState({
        display: false
    })

    useEffect(() => {
        userStore.getContact(props.userID);
        if(props.advertID && Number(props.advertID) > 0) {
            axios.get('/object/object/' + props.advertID + '/')
                .then(success => {
                    setAdv(success.data)
                    success.data.filteradvert.map(c => {
                        advert.getFilterCategory(c.filtercategoryid)
                    })
                })
                .catch(error => {
                    console.log(error)
                });
        }
        advert.getFavorite()
    }, [props, upload])

    const formatPrice = price => {
        if(price === 0) return "Бесплатно"
        else return new Intl.NumberFormat('ru-RU').format(price) + " ₽"
    }

    const getPrice = () => {
        let type = Number(adv.typeid)
        let s = Number(adv.stringroom2)
        let price = ""
        let duration = ""
        let priceTo = ""
        if(type === 1){
            price = (s === 1) ? "Цена договорная" : formatPrice(adv.pricefrom)
        } else if (type === 2 || type === 5){
            price = formatPrice(adv.pricefrom)
            duration = (s === 1) ? "посуточно" : "долгосрочно"
        } else if (type === 3){
            price = ""
        } else if (type === 8){
            price = formatPrice(adv.pricefrom)
            priceTo = price = formatPrice(adv.priceto)
        } else {
            price = formatPrice(adv.pricefrom)
        }

        return (
            <>
                <div className="object-price">
                    {priceTo.length > 0 &&
                        <>от </>
                    }
                    {price}
                </div>
                {priceTo.length > 0 &&
                    <div className="object-price">&nbsp;до {priceTo}</div>
                }
                {duration.length > 0 &&
                    <div className="object-price-square">({duration})</div>
                }
            </>
        )
    }

    const sendMessage = () => {
        if(message.message !== "") {
            let facts = new FormData();
            facts.append("text", message.message);
            facts.append("advertid", adv.id);
            facts.append("touserid", adv.userid);
            facts.append("fromuserid", store.get("user").id);
            facts.append("usertype", "user");

            axios.post("/user/message", facts)
                .then(success => {
                    console.log(success);
                    sitemes.success("Сообщение успешно отправлено");
                    closeModal();
                })
                .catch(error => console.log(error.response));
        }
    };

    const published = (advertID) => {
        let facts = new FormData();
        facts.append("status", 1);
        axios.post('/object/objectdmin/' + advertID + '/', facts)
            .then(success => {
                setUpload(upload + 1);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const archive = (advertID) => {
        let facts = new FormData();
        facts.append("status", 2);
        axios.post('/object/objectdmin/' + advertID + '/', facts)
            .then(success => {
                setUpload(upload + 1);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const closeModal = () => {
        setMessage({ display: false, message: "" });
    };

    const openModal = () => {
        setMessage({ display: true, message: "" });
    };

    let galleryItems = [];

    if(!adv.photo || !adv.photo.length){
        galleryItems = [{original: noPhoto, thumbnail: noPhoto}]
    } else {
        galleryItems = adv.photo.map(p => {
            return {original: p, thumbnail: p}
        });
    }

    const renderGallery = (item) => {
        let zoom = 1.3;
        if(item.original === noPhoto) zoom = 1;
        return <Zoom
            image={item.original}
            zoomScale={zoom}
        />
    }

    let advertName = advert.getAdvertName(adv);

    return(
        <>
            <Modal
                title={advertName}
                visible={advert.showModal}
                onCancel={() => advert.showModal = false}
                footer={null}
                width={800}
                centered
            >
                <ImageGallery
                    items={galleryItems}
                    infinite={false}
                    showThumbnails={false}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    disableKeyDown={true}
                    showNav={true}
                />
            </Modal>
            <HeaderPage type={0}/>
            <Row gutter={[48, 0]} className="object-page">
                <Col xs={24} md={12} lg={10} className="section-header">
                    <h2 className="category-heading">{advertName}</h2>
                </Col>
                <Col xs={10} md={6} lg={4} className="section-header object-buttons">
                    {advert.favorite.find(el => el.id == props.advertID)
                        ? <div
                            onClick={(e) => advert.like(e, Number(props.advertID))}
                            className="object-button"
                        >
                            <HeartFilledIcon className="filled" />
                        </div>
                        :
                        <div
                            onClick={(e) => advert.like(e, Number(props.advertID))}
                            className="object-button"
                        >
                            <HeartFilledIcon />
                        </div>
                    }

                    <div className="object-button" onClick={() => setShare({ display: true })}>
                        <ShareIcon />
                    </div>
                    <a href={"/user/" + adv.userid} className="object-button">
                        <ProfileIcon />
                    </a>
                </Col>
                <Col xs={14} md={18} lg={10} className="section-header object-prices">
                    {getPrice()}
                    {/*<div className="object-price-square">*/}
                    {/*    {new Intl.NumberFormat('ru-RU').format("100000")} ₽ за м<sup>2</sup>*/}
                    {/*</div>*/}
                </Col>
                <Col xs={24} lg={12} xl={12} className="object-block">
                    <div className="object-images">
                        <ImageGallery
                            items={galleryItems}
                            infinite={false}
                            thumbnailPosition="left"
                            showFullscreenButton={false}
                            showPlayButton={false}
                            disableKeyDown={true}
                            showNav={false}
                            renderItem={renderGallery}
                            onClick={e => advert.showModal = true}
                        />
                    </div>
                </Col>
                <Col xs={24} lg={12} xl={12} className="object-block">
                    <div className="object-contacts">
                        {adv.viewphone > 0 && userStore.contactsView !== true &&
                            <div onClick={() => userStore.getContacts()} className="object-button"><span>Показать номер</span></div>
                        }
                        {userStore.contactsView === true &&
                            <div className="object-button gray"><span>{userStore.contactInfo.phone}</span></div>
                        }
                        {store.get("user") && store.get("user").id > 0 && adv.viewchat > 0 &&
                            <div onClick={openModal} className="object-button inverted">Написать сообщение</div>
                        }
                        {!store.get("user") &&
                            <a href={"/signin"} className="object-button inverted">Написать сообщение</a>
                        }
                    </div>
                    <div className="object-location">
                        <HomeIcon />{adv.address}
                    </div>
                    <div className="object-map">
                        <YMaps>
                            <Map defaultState={{ center: [adv.latitude, adv.longitude], zoom: 12 }} width={100 + "%"}
                                 height={280}
                            >
                                <Placemark
                                    geometry={[adv.latitude, adv.longitude]}
                                    options={{
                                        iconLayout: 'default#image',
                                        iconImageOffset: [-15, -28],
                                        iconImageHref: pin
                                    }}
                                />
                                <Circle
                                    geometry={[[adv.latitude, adv.longitude], adv.radius * 1000]}
                                    options={{
                                        draggable: false,
                                        fillColor: '#DB709377',
                                        strokeColor: '#990066',
                                        strokeOpacity: 0.8,
                                        strokeWidth: 1,
                                    }}
                                />
                            </Map>
                        </YMaps>
                    </div>
                    {adv.category &&
                        <CategorySlider category={adv.category.id} />
                    }
                </Col>
                <Col xs={24} lg={12} xl={14} className="object-block">
                    <div className="object-subheading">Описание</div>
                    <div className="object-description" dangerouslySetInnerHTML={{ __html: adv.text }} />
                </Col>
                {adv.filteradvert &&
                    adv.filteradvert.map(f => {
                        return <Col xs={24} lg={12} xl={14} className="object-block">
                            <div className="object-subheading">{f.label}</div>
                            <div className="object-description">{advert.getFilterName(f.filtervalueid)}</div>
                        </Col>
                    })
                }
            </Row>
            <Modal title="Написать сообщение" visible={message.display} onOk={sendMessage} onCancel={closeModal}>
                <Input.TextArea
                    value={message.message}
                    onChange={e => setMessage({ ...message, message: e.target.value })}
                />
            </Modal>
            <Modal
                title="Поделиться объявлением"
                visible={share.display}
                onCancel={() => setShare({ display: false })}
                footer={null}
            >
                <YandexShare
                    content={{ title: advertName }}
                    theme={{ size: 'l', services: 'vkontakte,telegram,whatsapp,odnoklassniki,viber', shape: 'round' }}
                />
            </Modal>
        </>
    )
});

export default UserAdvert;