import {observer} from "mobx-react-lite";
import HeaderPage from "./Header";
import React from "react";
import {Row, Col} from "antd";

const offerPage = observer(() => {

    return(
        <>
            <HeaderPage type={0}/>
            <Row className="advert" gutter={[30, 0]}>
                <Col xs={24} sm={8} className="page-header-block">
                    <h1 className="favorites-h1">Оферта о заключении договора оказания услуг</h1>
                </Col>
                <Col xs={24}>
                    <p>Настоящий документ является предложением ООО «НЕДВИЖЕР» (далее – «Компания») в адрес зарегистрированных пользователей НЕДВИЖЕР (далее – «пользователи», «Вы» ) заключить рамочный договор оказания услуг (далее – «Договор»,) на указанных ниже условиях (далее – «оферта»).</p>
                    <p>В оферте могут встречаться термины, как они определены в Условия использования НЕДВИЖЕР.</p>
                    <p className="boldtext">1. Предмет Договора</p>
                    <p>1.1. Договор определяет общие условия обязательственных отношений, возникающих между Компанией и пользователем при оказании Компанией услуг информационно-технического или рекламного характера (далее – «услуги»). Данные условия могут быть дополнены и уточнены в дальнейшем путем заключения сторонами на основании Договора отдельных соглашений (сделок) об оказании услуг на НЕДВИЖЕР или других сайтах, на которых Компания предоставляет услуги.</p>
                    <p>1.2. Заключаемый Договор представляет собой договор с открытыми условиями. Существенные условия каждой совершаемой на НЕДВИЖЕР сделки по оказанию услуг формируются онлайн индивидуально для пользователя с помощью интерфейса НЕДВИЖЕР, посредством которого пользователь выбирает услугу и ее параметры, сторонами согласовываются условия сделки.7</p>
                    <p className="boldtext">2. Заключение Договора. Заказ услуг</p>
                    <p>2.1. Оферта считается акцептованной пользователем, а Договор между Компанией и пользователем заключенным с момента оплаты пользователем услуг по соответствующей сделке (в этом случае она совершается одновременно с заключением Договора) или с момента внесения аванса в счет оплаты заказываемых в будущем услуг. Условия заключенного Договора применяются ко всем последующим сделкам между пользователем и Компанией. Договор может быть заключен только с пользователем, являющимся дееспособным физическим лицом либо юридическим лицом или индивидуальным предпринимателем, зарегистрированным в Российской Федерации.</p>
                    <p>2.2. Каждый заказ конкретных услуг в рамках Договора является самостоятельной сделкой по оказанию услуг информационно-технического характера или рекламного характера. Сделка во исполнение настоящего Договора может быть заключена в отношении услуг, представленных на НЕДВИЖЕР и доступных для заказа и оплаты в момент обращения пользователя к тому или иному сервису НЕДВИЖЕР.</p>
                    <p>2.3. Оплата заказа признается акцептом пользователя предложения о заключении сделки на согласованных сторонами условиях.</p>
                    <p>2.4. Пользователь оформляет каждый заказ услуг в соответствии с условиями действующей редакции оферты и условиями соответствующего сервиса. Условия всех сервисов НЕДВИЖЕР, которые размещены на НЕДВИЖЕР в момент заказа услуг, являются неотъемлемой частью оферты. Заказ оформляется в режиме онлайн.</p>
                    <p>2.5. Совершая акцепт оферты, Вы выражаете согласие с тем, что факт выполнения определенных действий на НЕДВИЖЕР, в том числе с использованием профессиональных технических инструментов, выполнение команд через интерфейс НЕДВИЖЕР (нажатие кнопок, клики), совершение платежа и иные подобные действия означают ваше волеизъявление в отношении заказа и/или активации услуг в соответствии с указанными на НЕДВИЖЕР параметрами услуг и их ценой.</p>
                    <p className="boldtext">3. Общие условия оказания услуг</p>
                    <p>3.1. Услуги оказываются на территории Российской Федерации.</p>
                    <p>3.2. Условиями получения пользователем услуг Компании является оплата услуг и соблюдение пользователем правил НЕДВИЖЕР, установленных в документах, перечисленных в данном пункте, а также в инструкциях, представленных в интерфейсе НЕДВИЖЕР (далее – «правила НЕДВИЖЕР»):
                        • <a href="/terms">Условия использования НЕДВИЖЕР</a>.
                    </p>
                    <p>3.3. Обязательства Компании по предоставлению услуг согласно заказам пользователя являются встречными по отношению к обязательствам пользователя по соблюдению правил НЕДВИЖЕР и оплате услуг. При невыполнении пользователем данных обязательств Компания может приостановить или отказаться от оказания услуг в соответствующей части на основании правомочий, предусмотренных гражданским законодательством Российской Федерации.</p>
                    <p>3.4. Компания оказывает услуги при наличии возможности их предоставления, которая определяется, в том числе, соблюдением пользователями правил НЕДВИЖЕР. Несоблюдение Правил НЕДВИЖЕР может привести к невозможности исполнения обязательств Компании по оказанию соответствующих услуг.</p>
                    <p>3.5. В силу характера предлагаемых Компанией услуг, направленных на удовлетворение индивидуальных потребностей пользователей по продвижению товаров, работ, услуг пользователей и иных предложений, в рамках Договора Компания не принимает на себя обязанности по оказанию услуг каждому лицу, которое к ней обратится и может отказать в оказании услуг пользователю. Например, в случаях когда предоставление услуг не соответствует правилам НЕДВИЖЕР, его тематике, категориям и пр.</p>
                    <p>3.6. Некоторые услуги на НЕДВИЖЕР могут быть доступны только для отдельных категорий пользователей, например, использующих НЕДВИЖЕР для осуществления своей предпринимательской деятельности.</p>
                    <p>3.7. Условия предоставления конкретных услуг в рамках Договора, в том числе их содержание, объем и сроки определяются в соответствующих Условиях сервисов и других правилах НЕДВИЖЕР.</p>
                    <p>3.8. Предоставляемые на НЕДВИЖЕР услуги могут изменяться, дополняться, обновляться, в связи с чем их использование предлагается в режиме «как есть», то есть в том виде и объеме, в каком они предоставляются Компанией в момент обращения пользователей к услугам и сервисам НЕДВИЖЕР.</p>
                    <p>3.9. Компания вправе направлять вам по имеющимся в вашем профиле контактным данным уведомления, связанные с исполнением настоящего Договора или любой из заключенных в рамках Договора сделок, включая оповещения о статусе оказания услуги, способах продления, изменениях условий оказания услуг и иные информационные сообщения, посредством смс- и push-сообщений, электронной почты и телефонных звонков.</p>
                    <p>3.10. Вы обязуетесь соблюдать при пользовании услугами требования действующего законодательства Российской Федерации, положения Договора, соответствующие условия выбранной услуги или сервиса и все иные правила НЕДВИЖЕР.</p>
                    <p>3.11. По требованию Компании Вы обязаны предоставить информацию и документы, необходимые для идентификации пользователя в качестве стороны Договора, в частности при направлении в адрес Компании заявлений, запросов и прочих обращений, связанных с оказанием услуг на НЕДВИЖЕР. В целях идентификации частных пользователей Компанией могут быть запрошены ФИО, серия и номер паспорта, скан-копия второй и третьей страницы паспорта (второй разворот), адрес электронной почты и номер телефона пользователя, используемые для доступа к профилю. Кроме того, Компания вправе самостоятельно проводить верификацию пользователя в качестве стороны по Договору путем проведения сверки указанных вами в профиле реквизитов с реквизитами плательщика по Договору, а также иными способами.</p>
                    <p>3.12. Стороны признают действия, совершенные с использованием логина и пароля пользователя, действиями, совершенными пользователем и имеющими силу простой электронной подписи.</p>
                    <p className="boldtext">4. Стоимость услуг и порядок расчетов</p>
                    <p>4.1. Стоимость выбранной пользователем услуги при заключении сделки в рамках Договора определяется исходя из характеристик услуги (вид услуги, состав пакета услуг и иные параметры). Стоимость выбранной услуги отображается в рублях в интерфейсе НЕДВИЖЕР при оформлении заказа, если иное не предусмотрено условиями соответствующего сервиса или услуги. Оплата услуг осуществляется по ценам, действующим на момент совершения платежа.</p>
                    <p>4.2.Вы можете совершить оплату услуг одним из способов, представленных в интерфейсе НЕДВИЖЕР в момент оформления заказа услуг.</p>
                    <p>4.3. Пользователь с профилем юридического лица или индивидуального предпринимателя должен осуществлять оплату услуг по Договору безналичным переводом денежных средств на расчетный счет Компании на основании выставленного через интерфейс НЕДВИЖЕР счета. Допускается также оплата с использованием корпоративной банковской карты, оформленной на соответствующее юридическое лицо или индивидуального предпринимателя.</p>
                    <p>4.4. Оплата безналичным переводом на основании выставленного счета для пользователей с профилем юридического лица или индивидуального предпринимателя, и/или сформированной квитанции для частных пользователей должна производиться с указанием номера профиля пользователя, а также иных реквизитов, идентифицирующих платеж, в том числе наименования и ИНН для пользователей с профилем юридического лица или индивидуального предпринимателя.</p>
                    <p>При оплате таким способом пользователь обязан внести в платежный документ точные данные, указанные в назначении платежа в выставленном ему счете на оплату или квитанции. При отсутствии или неправильном указании перечисленных в настоящем пункте данных, Компания вправе считать, что обязательства по оплате не выполнены пользователем надлежащим образом, либо самостоятельно идентифицировать платеж согласно имеющимся данным (в том числе данным собственного учета). Компания не несет ответственности за убытки, которые могут возникнуть у пользователя или третьих лиц в случае неправильного указания назначения платежа.</p>
                    <p>4.5. Заказ услуг должен быть оплачен полностью одним способом платежа. Оплата одного заказа одновременно разными способами не допускается.</p>
                    <p>4.6. В случае отмены услуги до начала ее предоставления, прекращения услуги по любому иному основанию, Компания вправе зачислить неиспользованную сумму (при её наличии), на указанные пользователем реквизиты независимо от того, каким способом была совершена ранее оплата услуг.</p>
                    <p>4.7. Факт предоставления пользователю услуг на НЕДВИЖЕР и их объем определяются на основании статистических данных учетной системы Компании.</p>
                    <p>4.8. В отношении услуг, которые Компания оказывает пользователям с профилями юридического лица или индивидуального предпринимателя в целях исполнения требований законодательства Российской Федерации о бухгалтерском учете, Компания ежемесячно направляет по адресу электронной почты пользователя или предоставляет возможность выгрузки в профиле копии универсального передаточного документа, включающего в себя счет-фактуру и акт (далее – «УПД») за отчетный месяц. Оригинал такого документа может быть направлен почтой по адресу, указанному в профиле.</p>
                    <p>Стороны могут договориться об обмене документами в электронном виде с помощью систем электронного документооборота (далее – «ЭДО»). Предложение об использовании ЭДО может быть направлено любой из сторон в адрес другой стороны, через используемую систему ЭДО. С момента акцепта такого предложения направление7</p>
                    <p>4.9. Если Компания не получает письменные мотивированные возражения пользователя относительно оказанных услуг в течение 5 дней с даты завершения отчетного месяца (в том числе по причине отказа представителя пользователя от получения электронной копии УПД или других документов или указания пользователем ненадлежащего адреса для получения документов) услуги за отчетный период, указанные в УПД, считаются оказанными Компанией надлежащим образом и принятыми пользователем в полном объеме.</p>
                    <p>4.10. Ответственность за получение любых документов (в том числе УПД, сообщений, уведомлений) вышеуказанными способами лежит на пользователе. Компания не несет ответственности за задержку или неполучение пользователем документов, если это явилось результатом неисправности систем связи, действия/бездействия провайдеров, операторов связи, утраты пользователем доступа к профилю или иных обстоятельств, находящихся вне зоны контроля Компании.</p>
                    <p>4.11. Наименование, юридический адрес, почтовый адрес, ИНН, КПП, контактная и прочая информация в платежных документах, УПД и других документах, формируемых Компанией, указываются в соответствии с реквизитами пользователя, указанными в его профиле.</p>
                    <p>4.12. Пользователь самостоятельно несет ответственность за правильность данных, указанных в профиле пользователя на НЕДВИЖЕР. При изменении реквизитов, Вы обязаны незамедлительно внести соответствующие изменения в вашем профиле. Если Вы не можете самостоятельно изменить данные в профиле, Вы обязаны уведомить Компанию о таких изменениях по установленной Компанией форме с приложением, по запросу Компании, подтверждающих изменения документов.</p>
                    <p>4.13. Компания вправе проверять правильность указанных в профиле реквизитов пользователя путем сверки с данными, содержащимися в едином государственном реестре юридических лиц (индивидуальных предпринимателей) и, при наличии расхождений, корректировать соответствующие данные, используя в качестве идентификатора пользователя указанный им ИНН.</p>
                    <p className="boldtext">5. Ответственность</p>
                    <p>5.1. Компания обязуется предпринимать все разумные усилия для надлежащего оказания услуг, однако не отвечает и не компенсирует убытки пользователя в случае, если пользователь не может воспользоваться услугами по следующим причинам:</p>
                    <p>• технологические неисправности каналов связи общего пользования, посредством которых осуществляется доступ к услугам, утраты пользователем доступа в интернет по любой причине, ошибки, пропуски, перерывы в работе или передаче данных, дефекты линий связи и иные технические сбои;</p>
                    <p>• несанкционированное вмешательство в работу НЕДВИЖЕР третьих лиц, включая хакерские, DDoS-атаки, действия вирусных программ и иные нарушения работы НЕДВИЖЕР.</p>
                    <p>• утрата пользователем доступа к профилю, в том числе при наличии признаков несанкционированного его использования;</p>
                    <p>• при наступлении обстоятельств непреодолимой силы.</p>
                    <p>5.2. В случае причинения убытков пользователю по вине Компании ответственность определяется в порядке, предусмотренном законодательством Российской Федерации. При этом ответственность Компании перед пользователями, использующими НЕДВИЖЕР для предпринимательской и иной профессиональной деятельности, определяется в размере, не превышающем стоимость заказанной и оплаченной пользователем, но не полученной по вине Компании услуги.</p>
                    <p>5.3. Компания не отвечает перед пользователем за убытки, возникшие у пользователя не по вине Компании, в том числе в связи с нарушением пользователем правил НЕДВИЖЕР.</p>
                    <p>5.4. Компания не несет ответственности за перевод или поступление денежных средств пользователя в оплату услуг. Безопасность, конфиденциальность, а также иные условия использования выбранных вами способов оплаты определяются соглашениями между пользователем и соответствующими организациями по приему платежей.</p>
                    <p>5.5. Вы отвечаете за любые действия, совершенные на НЕДВИЖЕР с использованием ваших данных для входа на НЕДВИЖЕР (в том числе за действия работников и третьих лиц) и за убытки, которые могут возникнуть по причине несанкционированного использования вашего профиля и простой электронной подписи.</p>
                    <p>5.6. В случае кражи, утери данных для входа Вы самостоятельно предпринимаете необходимые меры для смены пароля для доступа к профилю. Компания не несет ответственность за действия третьих лиц, повлекшие кражу, утерю ваших данных для входа, а также любые расходы, ущерб или упущенную выгоду, возникшие у пользователя в связи с несанкционированным доступом третьих лиц к профилю пользователя.</p>
                    <p>5.7. Компания не гарантирует соответствие сервисов НЕДВИЖЕР ожиданиям пользователя и получение пользователем отклика посетителей на размещенные им объявления в связи с получением услуг на НЕДВИЖЕР.</p>
                    <p className="boldtext">6. Рассмотрение претензий</p>
                    <p>6.1. Споры, возникшие в рамках настоящего Договора, должны быть переданы на рассмотрение в Арбитражный суд г. Екатеринбурга, а если спор не подлежит рассмотрению в арбитражном суде в соответствии с его компетенцией — в иные суды согласно положениям законодательства Российской Федерации.</p>
                    <p>6.2. До обращения в суд пользователь с профилем юридического лица или индивидуального предпринимателя, должен соблюсти внесудебный порядок разрешения споров, направив в адрес Компании письменную претензию, с приложением документов, подтверждающих обстоятельства и требования, на которые ссылается пользователь. Срок рассмотрения таких претензий составляет не более 30 дней с момента получения претензии Компанией.</p>
                    <p className="boldtext">7. Прочие условия</p>
                    <p>7.1. Договор вступает в силу с момента его заключения в порядке, указанном в разделе 2 оферты, и действует в течение неопределенного срока до момента его прекращения в соответствии с Договором и законодательством.</p>
                    <p>7.2. Компания вправе изменять и/или дополнять оферту в той мере, в какой это допускается законодательством, а также отозвать оферту в любое время. Датой изменения оферты является дата опубликования на НЕДВИЖЕР новой редакции оферты. Вы должны ознакомиться с действующей редакцией оферты, размещенной на НЕДВИЖЕР, и с условиями каждой сделки до заключения Договора и совершения сделки.</p>
                    <p>7.3. Заказ услуг и совершение пользователем сделки в рамках Договора после вступления в силу изменений в оферте означает, что стороны пришли к согласию о внесении соответствующих изменений и/или дополнений в Договор. Такие изменения в Договоре не применяются к услугам, заказанным и оплаченным пользователем до вступления в силу изменений в оферте.</p>
                    <p>7.4. Компания вправе расторгнуть Договор в порядке, предусмотренном законодательством, в том числе в случае однократного нарушения пользователем законодательства, условий Договора или правил НЕДВИЖЕР, с уведомлением об этом пользователя через интерфейс НЕДВИЖЕР, по адресу электронной почты или иным способом.</p>
                    <p>7.5. Договор может быть прекращен досрочно по инициативе пользователя. Если пользователь не воспользовался профилем на НЕДВИЖЕР в течение 3 лет, Договор считается прекращенным по инициативе пользователя по истечении указанного срока.</p>
                    <p>7.6. При обращении за возвратом неиспользованного остатка денежных средств пользователь по требованию Компании обязан идентифицировать себя в качестве стороны по Договору, совершив указанные в инструкциях Компании действия (направить заявление с адреса электронной почты, указанного в профиле, использовать при обращении номер телефона, указанный в профиле и подтвержденный в порядке, предусмотренном НЕДВИЖЕР, и др.), а также предоставить необходимые документы и информацию (в том числе документы, требуемые для идентификации пользователя, документы, подтверждающие факт внесения пользователем платежей и др.).</p>
                    <p>7.7. Компания вправе указывать наименование, товарный знак и общее описание услуг пользователя с профилем юридического лица или индивидуального предпринимателя в своих маркетинговых материалах.</p>
                    <p>7.8. Договор представляет все условия, согласованные между сторонами в отношении его предмета, и заменяет собой все прежние договоренности, заверения и любого рода соглашения между сторонами в отношении его предмета, если иное прямо не согласовано сторонами.</p>
                    <p>7.9. Обязательства сторон по Договору, которые в силу своей природы должны продолжать действовать (включая обязательства в отношении предоставленных гарантий, конфиденциальности, проведения взаиморасчетов, но, не ограничиваясь указанным), остаются в силе после окончания срока действия Договора до полного исполнения.</p>
                    <p className="boldtext">8. Реквизиты Компании</p>
                    <p>Общество с ограниченной ответственностью «НЕДВИЖЕР»</p>
                    <p>ИНН 6671105877 КПП 667101001</p>
                    <p>620014, Свердловская обл, г. Екатеринбург, ул. Вайнера, 21-309</p>
                    <p>р/с 40702810738440000256 в филиал «Екатеринбурский» АО «Альфа-Банк»</p>
                    <p>БИК 046577964 к/с 30101810100000000964</p>
                </Col>
            </Row>
        </>
    )
});

export default offerPage;