import {observer} from "mobx-react-lite";
import HeaderPage from "./Header";
import React from "react";
import {Row, Col} from "antd";

const detailsPage = observer(() => {

    return(
        <>
            <HeaderPage type={0}/>
            <Row className="advert" gutter={[30, 0]}>
                <Col xs={24} sm={8} className="page-header-block">
                    <h1 className="favorites-h1">Реквизиты компании ООО "НЕДВИЖЕР"</h1>
                </Col>
                <Col xs={24}>
                    <p>Общество с ограниченной ответственностью «НЕДВИЖЕР»</p>
                    <p>ИНН 6671105877 КПП 667101001</p>
                    <p>620014, Свердловская обл, г. Екатеринбург, ул. Вайнера, 21-309</p>
                    <p>р/с 40702810738440000256 в филиал «Екатеринбурский» АО «Альфа-Банк»</p>
                    <p>БИК 046577964 к/с 30101810100000000964</p>
                </Col>
            </Row>
        </>
    )
});

export default detailsPage;