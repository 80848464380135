import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import Slider from "react-slick";
import axios from "../../store/axios";

const SingleSlide = observer(({item}) => {
    return(
        <>
            <a href={item.link} className="home-slide" target="_blank">
                <img src={item.photo} alt={item.name} />
                <div className="slide-meta">
                    <div className="meta-header">

                    </div>
                    <div className="meta-footer">
                        <div className="meta-footer-heading">{item.name}</div>
                        <div className="meta-footer-description">{item.text}</div>
                    </div>
                </div>
            </a>
        </>
    )
});

const HomeSlider = observer(() => {
    const [sliders, setSliders] = useState(null);

    useEffect(() => {
        axios.get("/object/slider/?filter[active]=1&filter[place]=home")
            .then(success => {
                setSliders(success.data[0]);
            });
    }, []);

    const sliderProps = {
        arrows: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    }

    return(
        <>
            {sliders &&
                sliders.map(slider => {
                    sliderProps.autoplay = slider.typeview === 1;
                    return <>
                        <Slider {...sliderProps} className="home-slider main-slider">
                            {slider.baner[0].map(e => {
                                if(e.active === 1) return <SingleSlide item={e} />
                            })}
                        </Slider>
                    </>
                })
            }
        </>
    );
});

export default HomeSlider;