import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Col, Row, Radio, Select, Input, InputNumber} from "antd";
import HeaderPage from "../components/Header";
import advert from "../store/advert";
import SingleAdvert from "../components/modules/SingleAdvert";
import AdvertsMap from "../components/modules/AdvertsMap";
import {ReactComponent as ListIcon} from "../images/list-list.svg";
import {ReactComponent as MapIcon} from "../images/list-map.svg";
import vkbannerwide from "../images/vk-link-wide.png";
import store from "store";
import config from "../config.json";
import categoryStore from "../store/categoryStore";
import NullList from "../components/NullList";
import handleViewport from "react-in-viewport";
import CategorySlider from "../components/modules/CategorySlider";
const { Option } = Select;

const Category = observer((props) => {

    useEffect(() => {
        if(props.categoryID > 0) advert.editFilter("category", Number(props.categoryID))
        advert.getAdvertsByFilters(advert.filter.category);
    }, []);

    const triggerBlock = (props) => {
        const {forwardedRef} = props;
        return (
            <div className="loadmore-products" ref={forwardedRef} />
        );
    };

    const ViewportBlock = handleViewport(triggerBlock);

    const loadMoreProducts = () => {
        if(advert.lastPage === false) {
            advert.page++;
            advert.getAdvertsByFilters(advert.filter.category);
        }
    }

    return(
        <>
            <HeaderPage type={0}/>
            <Row gutter={[48, 0]}>
                <Col xs={24} className="section-header">
                    <h2 className="category-heading">Все объявления</h2>
                    <Radio.Group
                        defaultValue="list"
                        onChange={(e) => advert.editTypeSearch(e.target.value)}
                        className="section-selector"
                    >
                        <Radio.Button value="list"><ListIcon />Списком</Radio.Button>
                        <Radio.Button value="map"><MapIcon />На карте</Radio.Button>
                    </Radio.Group>
                </Col>
                <Col xs={24} lg={8}>
                    <div className="category-filters create-item-block">
                        <Select
                            value={advert.filter.category}
                            onChange={e => advert.editFilter("category", e)}
                        >
                            <Option value="0">Все категории</Option>
                            {categoryStore.list.map(e => <Option value={e.id}>{e.name}</Option>)}
                        </Select>
                        <Select
                            placeholder="Тип сделки"
                            value={advert.filter.type_advert}
                            onChange={(e) => advert.editFilter("type_advert", e)}
                        >
                            <Option value="0">Все типы</Option>
                            {advert.types.type.length > 0 && advert.types.type.map(item => (
                                <Option value={item.id}>{item.name}</Option>
                            ))}
                        </Select>
                        {advert.filters.length > 0 &&
                            advert.filters.map(filter => {
                                if(filter.type === "price"){
                                    return(<>
                                        <Row gutter={[15,0]}>
                                            <Col xs={12}>
                                                <InputNumber
                                                    controls={false}
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                                    parser={value => value.replace(/\$\s?|( *)/g, '')}
                                                    placeholder="Цена от"
                                                    onChange={e => advert.editFilter("pricefrom", e)}
                                                    value={advert.filter.pricefrom}
                                                />
                                            </Col>
                                            <Col xs={12}>
                                                <InputNumber
                                                    controls={false}
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                                    parser={value => value.replace(/\$\s?|( *)/g, '')}
                                                    placeholder="Цена до"
                                                    onChange={e => advert.editFilter("priceto", e)}
                                                    value={advert.filter.priceto}
                                                />
                                            </Col>
                                        </Row>
                                    </>)
                                }
                                if(filter.type === "radio"){
                                    return(<>
                                        <Select
                                            placeholder={filter.name}
                                            defaultValue={advert.filter.filteradvert[filter.namevalue]}
                                            onChange={(e) => advert.editFilter(filter.namevalue, e, 1)}
                                        >
                                            {filter.filtervalue.map(f => (
                                                <Option value={f.id}>{f.label}</Option>
                                            ))}
                                        </Select>
                                    </>)
                                }
                                if(filter.type === "checked"){
                                    return(<>
                                        <Radio.Group
                                            defaultValue={advert.filter.filteradvert[filter.namevalue]}
                                            onChange={(e) => advert.editFilter(filter.namevalue, e.target.value, 1)}
                                        >
                                            {filter.filtervalue.map(f => (
                                                <Radio.Button value={f.id}>{f.label}</Radio.Button>
                                            ))}
                                        </Radio.Group>
                                    </>)
                                }
                            })
                        }
                    </div>
                    <div className="category-nadvert-image">
                        <CategorySlider category={props.categoryID} />
                    </div>
                </Col>
                <Col xs={24} lg={16}>
                    {advert.adverts !== [] && advert.adverts.length > 0 ? (
                        <>
                            {advert.typeSearch === "list" &&
                                <Row gutter={[0, 25]} className="nadverts-list four-row">
                                    {advert.adverts.map(item =>
                                        <Col>
                                            <SingleAdvert item={item}/>
                                        </Col>
                                    )}
                                    <ViewportBlock onEnterViewport={loadMoreProducts} />
                                </Row>
                            }
                            {advert.typeSearch === "map" &&
                                <AdvertsMap/>
                            }
                        </>
                        ) : (
                            <>
                                <NullList title="Нет объявлений по заданным параметрам" />
                            </>
                    )}
                </Col>
            </Row>
        </>
    )
});

export default Category;