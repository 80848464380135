import NewsList from "../../components/NewsList";
import HeaderPage from "../../components/Header";

import BreadcrumbPage from "../../components/Breadcrumb"

export default function AllNews() {

    return(
        <>
            <HeaderPage type={0}/>
            <BreadcrumbPage items={ [ {name: "Новости", href: ""} ] } />
            <div>
                <NewsList all={false} pagination={false} count={33}/>
            </div>
        </>
    )
}