import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import Slider from "react-slick";
import axios from "../../store/axios";

const SingleSlide = observer(({item}) => {
    return(
        <>
            <a href={item.link} className="home-slide" target="_blank">
                <img src={item.photo} alt={item.name} />
            </a>
        </>
    )
});

const CategorySlider = observer(({category}) => {
    const [slider, setSlider] = useState(null);

    useEffect(() => {
        axios.get("/object/slider/?filter[active]=1&filter[place]=category" + category + "s")
            .then(success => {
                setSlider(success.data[0][0]);
                if(success.data[0].length > 0 && success.data[0][0].typeview !== null) sliderProps.autoplay = success.data[0][0].typeview === 1;
            });
    }, []);

    const sliderProps = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 5000
    }

    return(
        <>
            {slider &&
                <>
                    <Slider {...sliderProps} className="side-slider">
                        {slider.baner[0].map(e => {
                            if(e.active === 1) return <SingleSlide item={e} />
                        })}
                    </Slider>
                </>
            }
        </>
    );
});

export default CategorySlider;