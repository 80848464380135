import {useRoutes} from 'hookrouter';
import Routes from "./Routes";
import axios from 'axios'
import {Row, Col} from 'antd'
import Footer from "./components/Footer";
import React from "react";
import store from "store";
import config from "./config.json";
import "./App.less";
import { YMInitializer } from 'react-yandex-metrika';

axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.common['Type-Device'] = config.typeDevice;

if(store.get("user") && store.get("user").token) {
    axios.defaults.headers.common['Usertoken'] = store.get("user").token;
}

function App() {
    const routeResult = useRoutes(Routes);
    return (
        <>
            <YMInitializer accounts={[74794417]} options={{webvisor: true}} />
            <Row className="template">
                <Col span={24}>
                    {routeResult}
                </Col>
            </Row>
            <Footer />
        </>
    );
}

export default App;
