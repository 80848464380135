import { Breadcrumb } from 'antd';

export default function BreadcrumbPage(props) {

    return(
        <>
            <Breadcrumb separator={">"} className="breadcrumbs">
                <Breadcrumb.Item href={"/"}>Главная</Breadcrumb.Item>
                {props.items && props.items.length > 0 &&
                    <>
                        {props.items.map(item => (
                            <Breadcrumb.Item href={item.href}>{item.name}</Breadcrumb.Item>
                        ))}
                    </>
                }
            </Breadcrumb>
        </>
    )
}