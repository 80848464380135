import {observer} from "mobx-react-lite";
import InputMask from 'react-input-mask';
import {Button, Col, Input, Row, Checkbox} from "antd";
import store from "store";
import HeaderPage from "../../components/Header";
import registerStore from "../../store/registerStore";
import React from "react";


const Register = observer(({fromHome}) => {
    if(store.get("user")) document.location.href = "/";

    return(
        <>
            {fromHome !== true &&
                <HeaderPage type={2}/>
            }
            <div className="register">
                <form onSubmit={e => registerStore.registerUser(e)}>
                    <Row justify={fromHome !== true ? "center" : "start"} align="top">
                        <Col xs={24} sm={12} md={9} lg={8} xl={7} className="register-column">

                            <div className="register-row">
                                <h3>Регистрация</h3>
                            </div>

                            <div className="register-row">
                                <label className="label">Ваше имя</label>
                                <Input
                                    disabled={registerStore.waitForServer}
                                    value={registerStore.reg.name}
                                    onChange={(e) => registerStore.onChangeReg("name", e.target.value)}
                                />
                                <span className="require">Обязательное поле</span>
                            </div>

                            <div className="register-row">
                                <label className="label">Выберите тип регистрации:</label>
                                <div className="reg-buttons">
                                    <Button

                                        disabled={registerStore.waitForServer}
                                        className={registerStore.reg.type === 1 ? "white active" : "white"}
                                        onClick={_ => registerStore.reg.type = 1}
                                    >
                                        По номеру телефона
                                    </Button>
                                    <Button
                                        disabled={registerStore.waitForServer}
                                        className={registerStore.reg.type === 0 ? "white active" : "white"}
                                        onClick={_ => registerStore.reg.type = 0}
                                    >
                                        По адресу email
                                    </Button>
                                </div>
                            </div>

                            {registerStore.reg.type === 1 &&
                                <div className="register-row">
                                    <label className="label">Ваш номер телефона</label>
                                    <InputMask
                                        disabled={registerStore.waitForServer}
                                        mask="+7 999-999-99-99"
                                        type="tel"
                                        className="ant-input"
                                        value={registerStore.reg.phone}
                                        onChange={(e) => registerStore.onChangeReg("phone", e.target.value)}
                                    />
                                    <span className="require">Обязательное поле</span>
                                </div>
                            }

                            {registerStore.reg.type === 0 &&
                                <div className="register-row">
                                    <label className="label">Ваш email</label>
                                    <Input
                                        disabled={registerStore.waitForServer}
                                        value={registerStore.reg.email}
                                        onChange={(e) => registerStore.onChangeReg("email", e.target.value)}
                                    />
                                    <span className="require">Обязательное поле</span>
                                </div>
                            }

                            {(registerStore.reg.type === 0 || registerStore.reg.type === 1) &&
                                <div className="register-row">
                                    <Checkbox
                                        disabled={registerStore.waitForServer}
                                        value={registerStore.reg.check}
                                        onChange={(e) => registerStore.onChangeReg("check", e.target.checked)}
                                        className="checkbox">
                                        Согласен на <a href="/privacy">обработку персональных данных</a>
                                    </Checkbox>
                                </div>
                            }

                            {registerStore.waitForCode === true &&
                                <div className="register-row">
                                    <label className="label">Код подтверждения</label>
                                    <Input
                                        disabled={registerStore.waitForServerReg}
                                        value={registerStore.reg.code}
                                        onChange={(e) => registerStore.onChangeReg("code", e.target.value)}
                                    />
                                    {registerStore.reg.type === 1 ? (
                                        <span className="require">Введите последние 4 цифры номера телефона, с которого поступит звонок</span>
                                    ) : (
                                        <span className="require">Введите код, полученный на ваш email</span>
                                    )}
                                </div>
                            }

                            {(registerStore.reg.type === 0 || registerStore.reg.type === 1) &&
                                <div className="register-row">
                                    <div className="reg-buttons">
                                        <Button
                                            disabled={registerStore.waitForServerReg}
                                            htmlType="submit"
                                        >
                                            Отправить
                                        </Button>
                                        {registerStore.waitForCode === true &&
                                            <Button
                                                disabled={registerStore.waitForServerReg}
                                                className="white"
                                                onClick={_ => {
                                                    registerStore.waitForServer = false
                                                    registerStore.waitForCode = false
                                                }}
                                            >
                                                Изменить данные
                                            </Button>
                                        }
                                    </div>
                                </div>
                            }

                        </Col>
                    </Row>
                </form>
            </div>
        </>
    );
});

export default Register;