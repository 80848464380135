import React from 'react';
import {Col} from "antd";

function NewsElementList(props) {

    let item = props.item;
    let date = new Date(item.date * 1000);
    let style = {
        backgroundImage: `url(${item.photo})`
    }

    return(
        <Col xs={24} sm={12} md={6} className="news-detail" onClick={() => document.location.href="/news/" + item.id}>
            <div className="img" style={style} />
            <div className="content">
                <h4>{item.name}</h4>
                <span className="date">{date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()}</span>
            </div>
        </Col>
    )
}

export default NewsElementList;