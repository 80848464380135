import {useEffect} from 'react';
import {Button, Col, Row} from "antd";
import advert from "../store/advert";
import userStore from "../store/userStore";
import {observer} from "mobx-react-lite";
import {ReactComponent as MetroIcon} from "../images/metro.svg";
import config from "../config.json";
import noPhoto from "../images/placeholder-image.png";


const AdvertsByList = observer((props) => {

    const nowTime = Math.round(new Date().getTime()/1000);

    let reqClass = "";
    if(props.item.timepay > 0 && props.item.timepay > nowTime) {
        reqClass = " selected";
    }
    if(props.item.status === 0){
        reqClass += " red"
    }

    let typeIcon;
    if(!props.item.photo || props.item.photo.length === 2){
        typeIcon = noPhoto
    } else {
        typeIcon = JSON.parse(props.item.photo)[0]
    }

    let reqText;
    if(props.item.text) {
        let textLength = 100;
        reqText = props.item.text.replace(/(<([^>]+)>)/ig, "").replace(/(&nbsp;)/ig, " ").slice(0, textLength);
        if(reqText.length >= textLength) {
            reqText += "...";
        }
    }

    let listKey = "listAdverts" + props.item.id;
    if(props.my === true){
        listKey = "myAdvert" + props.item.id;
    }

    return(
        <>
            {advert.types.room.length > 0 &&
                <a href={"/user/" + props.item.userid + "/entity/" + props.item.id} target="_blank" key={listKey}>
                    <Row className={"single-request" + reqClass} align="middle">
                        <Col xs={24} lg={2}>
                            <div className="type-icon-container">
                                <div className="type-icon">
                                    <img src={typeIcon} alt=""/>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} lg={22}>
                            <Row justify="space-between" className="req-first-line">
                                <Col className="req-name">
                                    {advert.getAdvertName(props.item)}
                                </Col>
                                <Col className="req-price">
                                    {props.item.pricefrom}&nbsp;₽
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className="req-text">{reqText}</Col>
                                {props.my === true &&
                                    <Col span={24} className="req-text">
                                        <Button href={"/entity/" + props.item.id}>
                                            Редактировать объявление
                                        </Button>
                                    </Col>
                                }
                            </Row>
                            <Row justify="space-between" align="middle">
                                <Col className="req-address">
                                    {props.item.metro &&
                                        <span className="metro"><MetroIcon />{props.item.metro.name}</span>
                                    }
                                    {props.item.address &&
                                        <span className="address">{props.item.address}</span>
                                    }
                                </Col>
                                <Col className="req-date">
                                    Дата добавления: {advert.getDatePublic(props.item.date)} г.
                                </Col>
                            </Row>
                            {props.my === true &&
                                <Row justify="space-between" align="middle">
                                    {props.item.status === 3 &&
                                    <>
                                        {props.item.timepay > 0 && props.item.timepay > nowTime
                                            ? <Col className="req-offer">Объявление выделено</Col>
                                            : (
                                            <Col className="req-offer active">
                                                <Button
                                                    href="#"
                                                    type="primary"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        userStore.makePayment(e, 1, config.pricePlace, props.item.id)
                                                    }}
                                                >
                                                    Выделить за {config.pricePlace}&nbsp;₽
                                                </Button>
                                            </Col>
                                            )
                                        }
                                        <Col>
                                            <Button
                                                href="#"
                                                type="primary"
                                                danger
                                                onClick={e => {
                                                    e.preventDefault()
                                                    advert.setAdvertArchive(props.item.id)
                                                }}
                                            >
                                                Снять с публикации
                                            </Button>
                                        </Col>
                                    </>
                                    }
                                    {props.item.status === 0 && props.item.errortext !== "" &&
                                        <Col className="req-error">{props.item.texterror}</Col>
                                    }
                                </Row>
                            }
                        </Col>
                    </Row>
                </a>
            }
        </>
    )
});

export default AdvertsByList;