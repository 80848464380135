import {observer} from "mobx-react-lite";
import HeaderPage from "./Header";
import React from "react";
import {Row, Col} from "antd";

const AboutPage = observer(() => {

    return(
        <>
            <HeaderPage type={0}/>
            <Row className="advert" gutter={[30, 0]}>
                <Col xs={24} sm={8} className="page-header-block">
                    <h1 className="favorites-h1">О компании</h1>
                </Col>
                <Col xs={24}>
                    <p className="boldtext">Для чего мы создали НЕДВИЖЕР.РФ?</p>
                    <p>В 2020 году у нас родилась идея, как мы можем упростить людям поиск и подбор движимого, недвижимого и любого другого имущества.</p>
                    <p>Обычно, продавцы публикуют свои объявления на площадках с объявлениями в социальных сетях и других ресурсах, а покупатели ищут то, что им требуется, в этих опубликованных объявлениях по регионам и по всему интернету. Это классический подход, когда есть свободное время заниматься поиском.</p>
                    <p className="boldtext">Мы создали платформу НЕДВИЖЕР.РФ для экономии Вашего времени.</p>
                    <p>На платформе основное внимание уделено заявкам на подбор любых товаров и услуг, движимого и недвижимого имущества, заявкам на аренду имущества и обмен любых категорий.</p>
                    <p>Все созданные заявки обрабатываются нашими менеджерами вручную, время на обработку 1-2 рабочих дня. Наши менеджеры будут смотреть варианты по всему рунету и все подходящие варианты будут отправлять Вам, пока Вы не найдете то, что Вам требуется.</p>
                    <p>Так же на платформе НЕДВИЖЕР.РФ можно опубликовать обычное бесплатное объявление на продажу, сдачу в аренду и предложение услуг.</p>
                    <p className="boldtext">Пример взаимодействия с нами, когда Вы хотите найти себе товар, услугу, движимое или недвижимое имущество:</p>
                    <p>1. Заходите на сайт НЕДВИЖЕР.РФ или nedvizher.ru</p>
                    <p>2. Кликаете кнопку – «Добавить объявление»</p>
                    <p>3. Заполняете все требуемые данные на поиск недвижимости</p>
                    <p>4. Вводите имя, телефон, e-mail, нажимаете «отправить»</p>
                    <p>5. Как только Ваше объявление - заявка опубликуется на сайте, наши менеджеры начнут поиск максимально подходящей для Вас недвижимости.</p>
                    <p>6. Все варианты отправим Вам в чат, либо на почту.</p>
                    <p>7. Как только Вы найдете подходящий для себя вариант недвижимости, наша работа будет завершена.</p>
                    <p className="boldtext">Наша цель:</p>
                    <p>Мы каждый день стараемся сделать для вас подбор более удобным и быстрым.</p>
                    <p className="boldtext">Наши ценности:</p>
                    <p>Ваша безопасность и доверие – мы помогаем вам найти то, что Вам нужно максимально быстро, прозрачно и безопасно.</p>
                </Col>
            </Row>
        </>
    )
});

export default AboutPage;