import React from 'react';
import {Col, Row} from "antd";
import HeaderPage from "../components/Header";
import {observer} from "mobx-react-lite";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import pin from "../images/pin.svg";

const Contacts = observer(() => {

    return(
        <>
            <HeaderPage type={0}/>
            <div className="home">
                <Row justify="space-around" align="middle" className="homeHead">
                    <Col span={24}>
                        <h1>Контакты</h1>
                    </Col>
                </Row>
            </div>
            <Row className="contacts">
                <Col span={24}>
                    <h3>Адрес</h3>
                    <p>620014, Свердловская область, Екатеринбург, ул. Вайнера 21, офис 309</p>
                </Col>
                <Col xs={24} sm={12} md={8} lg={5}>
                    <h3>Телефоны</h3>
                    <p><a href="tel:+79501958555">+7-950-19-58-555</a></p>
                    <p><a href="tel:+73432138555">+7-343-21-38-555</a></p>
                </Col>
                <Col xs={24} sm={12} md={8} lg={5}>
                    <h3>E-mail</h3>
                    <p><a href="mailto:nedvizher@gmail.com">nedvizher@gmail.com</a></p>
                </Col>
                <Col xs={24} md={18}>
                    <YMaps>
                        <Map defaultState={{ center: [56.831172, 60.597385], zoom: 16 }} width={100 + "%"} height={570}>
                            <Placemark
                                geometry={[56.831172, 60.597385]}
                                options={{
                                    iconLayout: 'default#image',
                                    iconImageOffset: [-15, -28],
                                    draggable: true,
                                    iconImageHref: pin
                                }}
                            />
                        </Map>
                    </YMaps>
                </Col>
            </Row>
        </>
    )
});

export default Contacts;