import React from 'react';
import Home from "./public/Home";
import LogIn from "./public/enter/SignIn";
import Forgot from "./public/enter/Forgot";
import CheckInObjectCreate from "./public/enter/CheckInObjectCreate";
import UserAdvert from "./public/user/UserAdvert";
import UserAdverts from "./public/user/UserAdverts";

import MyAdverts from "./public/user/MyAdverts";
import MyAdvert from "./public/user/MyAdvert";

import Chat from "./public/Chat";
import Setting from "./public/user/Setting";
import AllNews from "./public/news/NewsList";
import NewsDetail from "./public/news/NewsDetail";
import Favorites from "./public/user/Favorites";
import Contacts from "./public/Contacts";
import Map from "./public/Map";
import Privacy from "./components/Privacy";
import About from "./components/About";
import Details from "./components/Details";
import Terms from "./components/Terms";
import Offer from "./components/Offer";
import Price from "./components/Price";
import ForOwners from "./public/ForOwners";
import RegObjectTemp from "./public/enter/RegObjectTemp";
import Register from "./public/enter/Register";
import SetPassword from "./public/enter/SetPassword";
import Category from "./public/Category";
import SearchList from "./public/search/SearchList";

const Routes = {
    "/": () => <Home />,
    "/entities": () => <ForOwners />,
    "/register": () => <Register />,
    "/signin": () => <LogIn />,
    "/forgot": () => <Forgot />,
    "/checkin": () => <CheckInObjectCreate new={1} />,
    "/setpassword": () => <SetPassword />,
    "/objectcreate": () => <RegObjectTemp />,
    "/entity": () => <MyAdverts />,
    "/entity/new": () => <MyAdvert />,
    "/entity/:id": ({id}) => <MyAdvert advertID={id} />,
    "/chat": () => <Chat />,
    "/user/:user/entity/:id": ({user, id}) => <UserAdvert advertID={id} userID={user} />,
    "/favorites": () => <Favorites />,
    "/user/:userID": ({ userID }) => <UserAdverts userID={userID} />,
    "/setting": () => <Setting />,
    "/news": () => <AllNews />,
    "/news/:id": ({id}) => <NewsDetail newsID={id} />,
    "/contacts": () => <Contacts />,
    "/map": () => <Map />,
    "/privacy": () => <Privacy />,
    "/about": () => <About />,
    "/details": () => <Details />,
    "/terms": () => <Terms />,
    "/offer": () => <Offer />,
    "/price": () => <Price />,
    "/category/:id": ({id}) => <Category categoryID={id} />,
    "/search/:city/:category/:type/": ({city, type, category}) => <SearchList query="" city={city} type={type} category={category} />,
    "/search/:city/:category/:type/:query": ({city, category, type, query}) => <SearchList query={query} city={city} type={type} category={category} />
};
export default Routes;