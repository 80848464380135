import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Col, Row, Radio} from "antd";
import HeaderPage from "../components/Header";
import advert from "../store/advert";
import userStore from "../store/userStore";
import HomeSlider from "../components/modules/HomeSlider";
import HomeCategoriesSlider from "../components/modules/HomeCategoriesSlider";
import SingleAdvert from "../components/modules/SingleAdvert";
import AdvertsMap from "../components/modules/AdvertsMap";
import {ReactComponent as ListIcon} from "../images/list-list.svg";
import {ReactComponent as MapIcon} from "../images/list-map.svg";
import vkbanner from "../images/vk-link.png";
import vklogo from "../images/vk-icon.png";
import handleViewport from "react-in-viewport";
import HomeAdvertsSlider from "../components/modules/HomeAdvertsSlider";
import searchStore from "./search/searchStore";
import NullList from "../components/NullList";

const Home = observer(() => {

    useEffect(() => {
        searchStore.getSearch(-1);
    }, []);

    const triggerBlock = (props) => {
        const {forwardedRef} = props;
        return (
            <div className="loadmore-products" ref={forwardedRef} />
        );
    };

    const ViewportBlock = handleViewport(triggerBlock);

    const loadMoreProducts = () => {
        if(advert.lastPage === false) {
            advert.page++;
            if(searchStore.searchOnMain === true){
                searchStore.getSearch(-1, 0, searchStore.searchOnMainID)
            } else {
                searchStore.getSearch(-1)
            }

        }
    }

    let typesCounter = 0

    return(
        <>
            <HeaderPage type={0}/>
            <HomeSlider />
            <HomeCategoriesSlider />
            <Row gutter={[20, 0]}>
                <Col xs={24} lg={20} className="section-header">
                    <h2 className="home-heading">Все объявления</h2>
                    {advert.types.type.length > 0 &&
                        <div className="home-filters">
                            {advert.types.type &&
                                advert.types.type.map(item => {
                                    if(item.viewmain === 1 && typesCounter < 3){
                                        typesCounter++
                                        return <div
                                            className="nadvert-badge"
                                            style={{background: item?.color ?? "#fff"}}
                                            onClick={_ => {
                                                searchStore.searchOnMain = true
                                                searchStore.searchOnMainID = item.id
                                                advert.limit = 15
                                                advert.page = 1
                                                advert.count = -1
                                                advert.lastPage = false
                                                searchStore.getSearch(-1, 0, item.id)
                                            }}
                                        >
                                            {item?.name}
                                        </div>
                                    }
                                })
                            }
                        </div>
                    }
                    <div>
                        {advert.adverts !== [] && advert.adverts.length > 0 &&
                            <Radio.Group
                                defaultValue="list"
                                onChange={(e) => advert.editTypeSearch(e.target.value)}
                                className="section-selector"
                            >
                                <Radio.Button value="list"><ListIcon />Списком</Radio.Button>
                                <Radio.Button value="map"><MapIcon />На карте</Radio.Button>
                            </Radio.Group>
                        }
                    </div>
                </Col>
                <Col xs={24} lg={20}>
                    {advert.adverts !== [] && advert.adverts.length > 0 ? (
                        <>
                            {advert.typeSearch === "list" &&
                                <Row className="nadverts-list">
                                    {advert.adverts.length > 0 && advert.adverts.map(item =>
                                        <Col>
                                            <SingleAdvert item={item}/>
                                        </Col>
                                    )}
                                    <ViewportBlock onEnterViewport={loadMoreProducts} />
                                </Row>
                            }
                            {advert.typeSearch === "map" &&
                                <AdvertsMap />
                            }
                        </>
                    ) : (
                        <>
                            <NullList title="Нет объявлений по заданным параметрам" />
                        </>
                    )}
                </Col>
                <Col xs={24} lg={4}>
                    <HomeAdvertsSlider />
                    <a href="https://vk.com/nedvizher" className="vk-link-text">
                        <img src={vklogo} alt="Недвижер ВКонтакте" />
                        Мы Vkontakte
                    </a>
                </Col>
            </Row>
        </>
    )
});

export default Home;