import React, { useEffect } from "react"
import {observer} from "mobx-react-lite"
import HeaderPage from "../../components/Header"
import { Col, Row, Input, Button } from "antd"
import userStore from "../../store/userStore"
import store from "store"
import config from "../../config.json"
import advert from "../../store/advert"

const Setting = observer(() => {

    return(
        <>
            <HeaderPage type={0}/>
            <div className="user">
                <Row justify="space-around" align="middle">
                    <Col span={24}>
                        <h1>Редактирование профиля</h1>
                        <p className="yourID">Ваш ID: <span>{userStore.user.id ?? ""}</span></p>
                    </Col>
                </Row>
                <form onSubmit={e => userStore.saveUser(e)}>
                    <Row>
                        <Col xs={24} sm={12} md={6} className="inputs">
                            <label className="label">ФИО</label>
                            <Input
                                value={userStore.user.firstname}
                                onChange={(e) => userStore.onChangeUser("firstname", e.target.value)}
                            />
                            <span className="require">Обязательное поле</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={12} md={6} className="inputs">
                            <label className="label">Контактный телефон</label>
                            <Input
                                value={userStore.user.phone}
                                disabled={userStore.codePhoneSend}
                                onChange={(e) => userStore.onChangeUser("phone", e.target.value)}
                            />
                            <span className="require">Обязательное поле</span>
                        </Col>
                        {userStore.codePhoneSend === true &&
                        <Col xs={24} sm={6} md={4} className="inputs">
                            <label className="label">Код подтверждения</label>
                            <Input
                                value={userStore.phoneCode}
                                onChange={(e) => userStore.onChangeCode("phone", e.target.value)}
                            />
                            <span className="require">Обязательное поле</span>
                        </Col>
                        }
                        <Col xs={24} sm={12} md={6} className="inputs">
                            <label className="label">&nbsp;</label>
                            <Button
                                onClick={e => userStore.changeCredintails("phone")}
                            >
                                Сменить телефон
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={12} md={6} className="inputs">
                            <label className="label">Email</label>
                            <Input
                                value={userStore.user.email}
                                disabled={userStore.codeEmailSend}
                                onChange={(e) => userStore.onChangeUser("email", e.target.value)}
                            />
                            <span className="require">Обязательное поле</span>
                        </Col>
                        {userStore.codeEmailSend === true &&
                            <Col xs={24} sm={6} md={4} className="inputs">
                                <label className="label">Код подтверждения</label>
                                <Input
                                    value={userStore.emailCode}
                                    onChange={(e) => userStore.onChangeCode("email", e.target.value)}
                                />
                                <span className="require">Обязательное поле</span>
                            </Col>
                        }
                        <Col xs={24} sm={12} md={6} className="inputs">
                            <label className="label">&nbsp;</label>
                            <Button
                                onClick={e => userStore.changeCredintails("email")}
                            >
                                Сменить Email
                            </Button>
                        </Col>
                    </Row>
                    <h3>Сменить пароль</h3>
                    <Row>
                        <Col xs={24} sm={12} md={6} className="inputs">
                            <label className="label">Введите новый пароль</label>
                            <Input.Password
                                placeholder="Необязательно"
                                value={userStore.user.password}
                                onChange={(e) => userStore.onChangeUser("password", e.target.value)}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={6} className="inputs">
                            <label className="label">Повторите введенный пароль</label>
                            <Input.Password
                                placeholder="Необязательно"
                                value={userStore.user.confirm}
                                onChange={(e) => userStore.onChangeUser("confirm", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Button type="primary" htmlType="submit" className="save">Сохранить</Button>
                </form>
                <h3>Премиум аккаунт</h3>
                <div>
                    <span
                        className={store.get("user").datepay ? "pay yes" : "pay no"}
                    >
                    {
                        store.get("user").datepay !== 0
                            ? "Оплачен до " + advert.dateConverter(store.get("user").datepay)
                            : "Не оплачен"
                    }
                    </span>
                </div>
                <Button
                    type="primary"
                    className="save"
                    onClick={_ => userStore.makePayment(_, 2, config.price)}
                >
                    Продлить на 1 мес за {config.price} руб.
                </Button>
                {store.get("user").datepay === 0 &&
                    <p className="description">
                        Премиум аккаунт покупается на 1 мес. Дает возможность просматривать неограниченное количество контактов в объявлениях
                    </p>
                }
            </div>
        </>
    )
})

export default Setting