import React, { useState, useEffect } from 'react';
import HeaderPage from "../../components/Header";
import axios from "axios";
import {Typography, Button, Col, Row, Space} from 'antd';
import store from "store";
import AdvertByList from "../../components/AdvertByList";
import userStore from "../../store/userStore";
import {observer} from "mobx-react-lite";
import config from "../../config.json"
const { Title } = Typography;

const UserAdverts = observer((props) => {

    const [adverts, setAdverts] = useState([]);

    useEffect(() => {
        userStore.getContact(props.userID);
        axios.get("/user/userbyid/" + props.userID)
            .then(success => {
                console.log(success);
                let url = "/object/object?status=3&filter[userid]=" + props.userID;

                axios.get(url)
                    .then(success => {
                        console.log(success);
                        setAdverts(success.data);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => console.log(error.response));
    }, []);

    return(
        <>
            <HeaderPage type={0}/>

            <Row justify="space-around" className="advert">
                <Col span={24}>
                    <div>
                        <h1 className="favorites-h1">Карточка пользователя</h1>
                    </div>
                </Col>
                <Col span={24}>
                    <Space>
                        <span>{userStore.contactInfo.firstname ? userStore.contactInfo.firstname : ""}</span>
                        <>
                            {userStore.contactsView === null &&
                                <Button onClick={() => userStore.getContacts()}>Показать контакты</Button>
                            }
                        </>
                    </Space>
                    <br/><br/>
                    {/*{userStore.contactsView === false &&*/}
                    {/*    <div className="payed-block">*/}
                    {/*        <p>Вы превысили лимит просмотра в день.</p>*/}
                    {/*        <a href="#">Оплатить на 1 мес {config.price} руб.</a>*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {userStore.contactsView === true &&
                        <div className="contacts-block">
                            {userStore.contactInfo.email !== "" && <p>email: {userStore.contactInfo.email}</p> }
                            {userStore.contactInfo.email !== "" && <p>Телефон: {userStore.contactInfo.phone}</p> }
                        </div>
                    }
                </Col>
                <Col span={24}>
                    {adverts.length > 0 && adverts.map(item =>
                        <AdvertByList item={item} />
                    )}
                </Col>
            </Row>


        </>
    )
});

export default UserAdverts;