import {observer} from "mobx-react-lite";
import {useEffect} from "react";
import createAdvertStore from "../../store/createAdvertStore";

const RegObjectTemp = observer(() => {
    useEffect(() => {
        createAdvertStore.getAdvertLocal();
        if(createAdvertStore.advert.city !== 0){
            createAdvertStore.sendForm("reg");
        }
    }, []);

    if(createAdvertStore.advert.city === 0){
        return document.location.href="/";
    }

    return null;
});

export default RegObjectTemp;