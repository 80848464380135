import axios from "axios";
import config from "../config.json";
import store from "store";

export default axios.create({
    baseURL: config.apiUrl,
    headers: {
        "Type-Device": config.typeDevice,
        "Usertoken": store.get("user") && store.get("user").token !== "" ? store.get("user").token : ""
    },
});