import { makeAutoObservable } from "mobx";
import axios from "./axios";
import {message} from "antd";
import createAdvertStore from "./createAdvertStore";

class categoryStore {
    list = [];
    sublist = [];
    filters = [];
    tree = [];
    categoryFilterList = []
    viewType = 1

    constructor() {
        makeAutoObservable(this);
    }

    getAllCategories() {
        axios.get("/object/categoryobject/0")
            .then(success => {
                //console.log(success.data);
                this.categoryFilterList = this.list = success.data.subcategory[0].sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            });
    }

    getCategoriesTree() {
        axios.get("/object/categoryobjectree/")
            .then(success => {
                this.tree = success.data.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            });
    }

    getSubCategories(id) {
        axios.get("/object/categoryobject/" + id)
            .then(success => {
                this.sublist = success.data.subcategory[0].sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            });
    }

    getCategoryFilters(id){
        axios.get("/object/filterbycategory/" + id).then(success => {
            this.filters = success.data;
            console.log(success.data);
        });
    }

    categoryChange(id){
        this.getSubCategories(id);
        createAdvertStore.onChange("subcategory", null);
        createAdvertStore.onChange("category", id);
        this.getCategoryFilters(id);
        createAdvertStore.advert.filteradvert = [];
    }

    filterCategoryList(search) {
        this.categoryFilterList = this.list.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
    }
}

export default new categoryStore();