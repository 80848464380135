import {observer} from "mobx-react-lite";
import {Button, Col, Input, Row, Upload, message} from "antd";
import userStore from "../../store/userStore";
import InputMask from "react-input-mask";
import {ReactComponent as ClipIcon} from "../../images/clip.svg";
import config from "../../config.json";
import formBg from "../../images/rieltor.png";
const {TextArea} = Input;

const ContactForm = observer(() => {
    const props = {
        name: "file",
        action: config.apiUrl + "/upload/upload",
        accept: ".png, .jpg, .jpeg, .pdf, .doc, .docx",
        maxCount: 3,
        multiple: true,
        data: {
            path: "document"
        },
        headers: {
            "Type-Device": config.typeDevice
        },
        beforeUpload(file, fileList) {
            if(fileList.length > 3 || userStore.form.files.length >= 3){
                message.error("Не более 3 файлов");
                return false;
            }
            if(file.size / 1024 / 1024 > 2){
                message.error("Размер файла не более 2 мегабайт");
                return false;
            }
        },
        onChange(info) {
            if(info.file.status === 'done'){
                userStore.setContactField("files", info.file.response.data.url);
            } else if (info.file.status === 'error'){
                message.error(`Загрузка файла провалилась.`);
            }
        },
        onRemove(file) {
            if(file.percent > 0){
                userStore.removeFormFile(file.response.data.url);
            }
        }
    }

    return(
        <>
            <Row className="home-block-head contact-form-container" align="middle" id="contactForm">
                {/*<div className="form-background">*/}
                {/*    <img src={formBg} alt="Недвижер"/>*/}
                {/*</div>*/}
                <Col xs={24}>
                    <h2>Остались вопросы?</h2>
                    <h3>Получите бесплатную консультацию специалистов «Недвижер»</h3>
                </Col>
                {/*<Col xs={24} md={18} lg={16} xl={13}>*/}
                <Col xs={24}>
                    <form onSubmit={e => userStore.sendContactForm(e)}>
                        <div className="contact-form">
                            <div className="form-row">
                                <div className="styled-input">
                                    <Input
                                        placeholder="0"
                                        id="form-name"
                                        value={userStore.form.name}
                                        onChange={e => userStore.setContactField("name", e.target.value)}
                                    />
                                    <label htmlFor="form-name">Имя</label>
                                </div>
                                <div className="styled-input">
                                    <Input
                                        placeholder="0"
                                        id="form-email"
                                        type="email"
                                        value={userStore.form.email}
                                        onChange={e => userStore.setContactField("email", e.target.value)}
                                    />
                                    <label htmlFor="form-email">Email</label>
                                </div>
                                <div className="styled-input">
                                    <InputMask
                                        mask="+7 999-999-99-99"
                                        type="tel"
                                        className="ant-input"
                                        placeholder="0"
                                        id="form-phone"
                                        value={userStore.form.phone}
                                        onChange={e => userStore.setContactField("phone", e.target.value)}
                                    />
                                    <label htmlFor="form-phone">Номер телефона</label>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="styled-input w100">
                                    <TextArea
                                        id="form-message"
                                        placeholder="0"
                                        value={userStore.form.message}
                                        onChange={e => userStore.setContactField("message", e.target.value)}
                                    />
                                    <label htmlFor="form-message">Сообщение</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-row form-footer">
                            <div className="agreement">
                                Нажимая на кнопку «Отправить» Вы даете согласие на <a href="/privacy" target="_blank">обработку персональных данных</a>
                            </div>
                            <Upload
                                {...props}
                                className="upload-files"
                            >
                                <ClipIcon />Прикрепить файлы
                            </Upload>
                            <Button
                                htmlType="submit"
                                type="primary"
                            >
                                Отправить
                            </Button>
                        </div>
                    </form>
                </Col>
            </Row>
        </>
    )
});

export default ContactForm;