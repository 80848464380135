import {useState, useEffect} from "react"
import HeaderPage from "../../components/Header";
import axios from "axios";
import BreadcrumbPage from "../../components/Breadcrumb";


export default function NewsDetail(props) {

    const [item, setItem] = useState({});

    const [date, setDate] = useState(0);

    useEffect(() => {
        axios.get("news/news/" + props.newsID)
            .then(success => {
                setItem(success.data);

                let dateTime = new Date(success.data.date * 1000);
                setDate(dateTime);
            })
            .catch(error => console.log(error));
    }, []);



    return(
        <>
            <HeaderPage type={0}/>
            {item.id && item.id !== "" && date !== 0 &&
                <>
                    <BreadcrumbPage items={ [ {name: "Новости", href: "/news"}, {name: item.name, href: ""} ] } />
                    <div className="news single-news">
                        <h1>{item.name}</h1>
                        <span className="date">{date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()}</span>
                        <p dangerouslySetInnerHTML={{ __html: item.text }} />
                    </div>
                </>
            }
        </>
    )
}