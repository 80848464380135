import {observer} from "mobx-react-lite";
import {useEffect} from "react";
import Slider from "react-slick";
import categoryStore from "../../store/categoryStore";

const SingleSlide = observer(({item}) => {

    return(
        <>
            <a href={"/category/"+item.id} className="home-slide">
                <div className="slide-image">
                    <img src={item.photo} alt={item.name} />
                </div>
                <div className="slide-heading">{item.name}</div>
            </a>
        </>
    )
});

const HomeCategoriesSlider = observer(() => {
    useEffect(() => {

    }, []);

    const sliderProps = {
        arrows: true,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 767,
                settings: {
                    infinite: true,
                    slidesToShow: 2
                }
            }
        ]
    }

    return(
        <>
            {categoryStore.list &&
                <>
                    <div className="section-header">
                        <h3 className="home-heading">Выберите категорию</h3>
                        <div
                            className="select-category-view"
                            onClick={_ => categoryStore.viewType = categoryStore.viewType === 1 ? 0 : 1}
                        >
                            {categoryStore.viewType === 1 ? (
                                "Показать список"
                            ) : (
                                "Показать слайдер"
                            )}
                        </div>
                    </div>
                    {categoryStore.viewType === 0 ? (
                        <div className="categories-list">
                            {categoryStore.list.map(e => {
                                return (
                                    <div className="item">
                                        <a href={"/category/"+e.id}>{e.name}</a>
                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                        <Slider {...sliderProps} className="home-slider categories-slider">
                            {categoryStore.list.map(e => <SingleSlide item={e} />)}
                        </Slider>
                    )}
                </>
            }
        </>
    );
});

export default HomeCategoriesSlider;