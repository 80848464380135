import { makeAutoObservable } from "mobx"
import axios from "./axios"
import store from "store"
import {message} from "antd"
import sha1 from "js-sha1"

class registerStore {
    constructor() {
        makeAutoObservable(this)
    }

    auth = {
        email: "",
        phone: "",
        password: "",
        phoneAuth: null,
        code: ""
    }
    reg = {
        name: "",
        phone: "",
        email: "",
        check: false,
        code: "",
        type: null
    }
    pass = {
        password: "",
        confirm: ""
    }
    restoreCodeSend = false
    // codeTimeout = false
    // timer = {
    //     minutes: "03",
    //     seconds: "00"
    // }

    waitForServer = false
    waitForServerCode = false
    waitForServerReg = false
    waitForCode = false


    onChangeAuth(name, value) {
        if(name === "phone") value = value.replace(/[^\d]/g, '');
        this.auth[name] = value;
    }

    onChangeReg(name, value) {
        this.reg[name] = value;
    }

    onChangePass(name, value) {
        this.pass[name] = value;
    }

    registerUser(e) {
        e.preventDefault()
        let type = this.reg.type

        if(this.reg.name.length < 1){
            return message.error("Пожалуйста, введите имя")
        }

        let facts = new FormData()
        let login

        if(type === 1){
            if(this.reg.phone.indexOf('_') + 1) {
                return message.error("Неверный формат телефона")
            }
            if(this.reg.phone.length < 16){
                return message.error("Необходимо указать корректный номер телефона")
            }
            login = this.reg.phone.replace(/[^\d]/g, '')
            facts.append("phone", login)
        } else if(type === 0) {
            if(this.reg.email.length < 7){
                return message.error("Необходимо указать корректный email")
            }
            login = this.reg.email
            facts.append("email", login)
        } else {
            return message.error("Не выбран тип регистрации")
        }

        if(this.reg.check === false){
            return message.error("Вы должны принять условия обработки персональных данных")
        }

        this.waitForServer = true

        axios.post("/user/userexist/", facts).then(success => {
            if(success.data === true){
                this.waitForServer = false
                return message.error("Пользователь с такими данными уже существует")
            } else {
                this.waitForCode = true

                facts.append("login", login)
                facts.append("type", type)

                if(this.waitForServerCode === false){
                    this.waitForServerCode = true
                    axios.post("user/codebefore/", facts).then(success => {
                        return message.success("Код подтверждения успешно отправлен")
                    }).catch(error => console.log(error))
                } else {
                    if(this.reg.code.length === 4){
                        facts.append("code", this.reg.code)
                        facts.append("name", this.reg.name)

                        this.waitForServerReg = true

                        axios.post('/user/register/', facts).then(success => {
                            if(Number(success.data.id) > 0) {
                                store.set('user', success.data)
                                store.set('setpassword', true)
                                document.location.href="/setpassword"
                            } else {
                                message.error("Произошла ошибка")
                            }
                        }).catch(error => {
                            this.waitForServerReg = false
                            message.error(error.response.data.message)
                        });
                    } else {
                        return message.error("Введите код подтверждения")
                    }
                }
            }
        }).catch(error => console.log(error))
    }

    setPasword(e) {
        e.preventDefault();
        let facts = new FormData()
        let user = store.get("user")

        if(this.pass.password !== "") {
            if(this.pass.password !== this.pass.confirm) {
                return message.error("Пароли не совпадают")
            }
            facts.append("password", sha1(this.pass.password))
        } else {
            return message.error("Пожалуйста, укажите пароль")
        }

        facts.append("firstname", user.firstname)
        facts.append("email", user.email)
        facts.append("phone", user.phone)

        let url = "/"
        if(store.get("advert")){
            url = "/objectcreate"
        }

        axios.post("/user/edituser/", facts)
            .then(success => {
                store.set('setpassword', false)
                store.set("user", success.data)
                document.location.href = url
            })
    }

    logIn(e) {
        e.preventDefault()

        if(this.auth.phone.indexOf('_') + 1) {
            return message.error("Неверный формат телефона")
        }

        let facts = new FormData()

        if(this.auth.phoneAuth === false){
            facts.append("email", this.auth.email)
        } else {
            facts.append("phone", this.auth.phone.replace(/[^\d]/g, ''))
        }
        facts.append("password", sha1(this.auth.password))

        axios.post('/user/user/', facts).then(success => {
            if(Number(success.data.id) > 0) {
                store.set("user", success.data)
                document.location.href = "/"
            } else {
                message.error("Произошла ошибка")
            }
        }).catch(error => message.error(error.response.data.message))
    }

    restorePassword(e) {
        e.preventDefault()

        let facts = new FormData()

        if(this.auth.phoneAuth === false){
            facts.append("email", this.auth.email)
        } else {
            if(this.auth.phone.indexOf('_') + 1) {
                return message.error("Неверный формат телефона")
            }

            facts.append("phone", this.auth.phone.replace(/[^\d]/g, ''))
        }

        if(this.restoreCodeSend === true){
            if(this.pass.password !== "") {
                if(this.pass.password !== this.pass.confirm) {
                    return message.error("Пароли не совпадают")
                }
                facts.append("password", sha1(this.pass.password))
            } else {
                return message.error("Пожалуйста, укажите пароль")
            }
            facts.append("code", this.auth.code)
        }

        this.waitForCode = true

        axios.post('/user/forgot', facts).then(success => {
            if(success.data === true) {
                if(this.restoreCodeSend === false){
                    this.restoreCodeSend = true;
                    return message.success("Код успешно отправлен")
                } else {
                    document.location.href = "/signin"
                }
            } else {
                if(this.restoreCodeSend === false){
                    message.error("Аккаунт не найден")
                    this.waitForCode = false
                } else {
                    message.error("Неправильный код")
                    this.waitForCode = false
                }
            }
        }).catch(error => message.error(error.response.data.message))
    }

    // checkRemainTime() {
    //     let codeSend = new Date(store.get("codeSend")).getTime();
    //     if(codeSend < Date.now()) {
    //         let timeout = 3 * 60000;
    //         let codeSendExpires = new Date(codeSend + timeout);
    //         let now = new Date().getTime();
    //         let diff = Math.abs(codeSendExpires - now);
    //         let minutes = Math.floor(diff / 60000);
    //         let seconds = Math.floor((diff / 1000 - minutes * 60));
    //         if ((minutes >= 0 && seconds >= 0) && (!Number.isNaN(minutes) && !Number.isNaN(seconds))) {
    //             this.codeTimeout = true;
    //             this.timer = {
    //                 minutes: ("0" + minutes).slice(-2),
    //                 seconds: ("0" + seconds).slice(-2)
    //             }
    //             if ((minutes === 0 && seconds === 0) || diff > timeout) {
    //                 store.remove("codeSend");
    //                 this.codeTimeout = false;
    //             }
    //         } else {
    //             store.remove("codeSend");
    //             this.codeTimeout = false;
    //         }
    //     } else {
    //         store.remove("codeSend");
    //         this.timer = {
    //             minutes: "03",
    //             seconds: "00"
    //         }
    //         this.codeTimeout = false;
    //     }
    // }
}

export default new registerStore();