import {observer} from "mobx-react-lite";
import React, {useEffect} from "react";
import advert from "../../store/advert";
import createAdvertStore from "../../store/createAdvertStore";
import noPhoto from "../../images/placeholder-image.png";

const SingleAdvert = observer(({item}) => {

    useEffect(() => {
        createAdvertStore.getCityList();
        if(item.filteradvert){
            item.filteradvert.map(c => {
                advert.getFilterCategory(c.filtercategoryid);
            });
        }
    }, []);

    let cityName = (createAdvertStore.cityList.length > 0) ? createAdvertStore.cityList.find(c => c.id === item.cityid).name : "";
    let price = item.pricefrom;
    if(item.pricefrom === 0) price = item.priceto;

    let itemPhoto
    if(!item.photo || item.photo.length === 2 || item.photo.length === 0){
        itemPhoto = noPhoto
    } else {
        itemPhoto = JSON.parse(item.photo)[0]
    }

    let advertType = advert.types.type.find(i => i.id === item.typeid)

    return(
        <>
            <a href={"/user/" + item.userid + "/entity/" + item.id} target="_blank" className="single-nadvert">
                <div className="nadvert-image">
                    {advert.types.type.length > 0 &&
                        <div className="nadvert-badge" style={{background: advertType?.color ?? "#fff"}}>{advertType?.name}</div>
                    }
                    <img src={itemPhoto} alt="123" />
                </div>
                <div className="nadvert-meta">
                    <h3 className="nadvert-heading">{advert.getAdvertName(item)}</h3>
                    <div className="nadvert-price">{price}&nbsp;₽</div>
                    <div className="nadvert-footer">
                        <div className="nadvert-location">
                            {cityName}
                        </div>
                        <div className="nadvert-date">{advert.dateConverter(item.date)}</div>
                    </div>
                </div>
            </a>
        </>
    )
});

export default SingleAdvert;