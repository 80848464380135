import React, {useState, useEffect} from 'react';
import NewsElementList from './NewsElementList';
import {Button, Col, Row} from "antd";
import axios from "axios";

export default function NewsList(props) {
    const [list, setList] = useState([]);

    const [data, setData] = useState({
        count: 10,
        pagination: false,
        all: false
    });

    useEffect(() => {
        let count = data.count;
        if(props.count) {
            count = props.count;
        }
        if(props.pagination) {
            setData({ ...data, pagination: props.pagination });
        }
        if(props.all) {
            setData({ ...data, all: props.all });
        }

        axios.get("/news/news?limit=" + count)
            .then(success => {
                setList(success.data);
            })
            .catch(error => console.log(error.response));
    }, [props]);

    return(
        <>
            {list.length > 0 &&
                <>
                    <Row className="home-block-head" align="middle">
                        <Col xs={24} md={12}>
                            <h2>Новости</h2>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        {list.map(item => (
                            <NewsElementList item={item} key={"news" + item.id} />
                        ))}
                    </Row>
                </>
            }
        </>
    )
}