import {observer} from "mobx-react-lite";
import CreateAdvert from "../../components/CreateAdvert";
import HeaderPage from "../../components/Header";
import createAdvertStore from "../../store/createAdvertStore";
import {Col, Row} from "antd";
import store from "store";
import Register from "./Register";
import React from "react";

const CheckInObjectCreate = observer(() => {
    if(store.get("user") && store.get("user").id > 0) {
        document.location.href="/";
    }

    let step;
    if(createAdvertStore.getRegForm === false) step = 1;
    else step = 2;

    return(
        <>
            <HeaderPage type={0}/>
            <Row className="register">
                <Col xs={24} className="section-header">
                    <h2 className="category-heading">Разместить объявление</h2>
                    <h3 className="category-subheading">Шаг {step}</h3>
                </Col>
                {createAdvertStore.getRegForm === false ?
                    (
                        <CreateAdvert />
                    ) : (
                        <Register fromHome={true} />
                    )
                }
            </Row>
        </>
    )
});

export default CheckInObjectCreate;