import {Input, Col, Row, Button, Space} from 'antd';
import HeaderPage from "../../components/Header";
import {observer} from "mobx-react-lite";
import registerStore from "../../store/registerStore";
import InputMask from "react-input-mask";
import React from "react";

const LogIn = observer(() => {

    return(
        <>
            <HeaderPage type={2}/>
            <div className="register">
                <form onSubmit={e => registerStore.logIn(e)}>
                    <Row justify="center" align="top">
                        <Col xs={24} sm={12} md={9} lg={8} xl={6} className="register-column">

                            <div className="register-row">
                                <h3>Авторизация</h3>
                            </div>

                            <div className="register-row">
                                <div className="reg-buttons centered">
                                    <Button
                                        className={registerStore.auth.phoneAuth === true ? "white active" : "white"}
                                        onClick={() => registerStore.onChangeAuth("phoneAuth", true)}
                                    >
                                        По номеру телефона
                                    </Button>
                                    <Button
                                        className={registerStore.auth.phoneAuth === false ? "white active" : "white"}
                                        onClick={() => registerStore.onChangeAuth("phoneAuth", false)}
                                    >
                                        По email
                                    </Button>
                                </div>
                            </div>

                            {registerStore.auth.phoneAuth === false &&
                                <div className="register-row">
                                    <label className="label">Ваш email</label>
                                    <Input
                                        placeholder="email@domain.ru"
                                        value={registerStore.auth.email}
                                        onChange={e => registerStore.onChangeAuth("email", e.target.value)}
                                        required
                                    />
                                </div>
                            }

                            {registerStore.auth.phoneAuth === true &&
                                <div className="register-row">
                                    <label className="label">Ваш номер телефона</label>
                                    <InputMask
                                        mask="+7 999-999-99-99"
                                        type="tel"
                                        className="ant-input"
                                        value={registerStore.auth.phone}
                                        onChange={(e) => registerStore.onChangeAuth("phone", e.target.value)}
                                    />
                                </div>
                            }

                            {registerStore.auth.phoneAuth !== null &&
                                <>
                                    <div className="register-row">
                                        <label className="label">Пароль</label>
                                        <Input.Password
                                            placeholder="Введите пароль"
                                            value={registerStore.auth.password}
                                            onChange={e => registerStore.onChangeAuth("password", e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="register-row">
                                        <div className="reg-buttons centered">
                                            <Button htmlType="submit">Войти</Button>
                                            <Button
                                                className="white"
                                                href={'/forgot'}
                                            >
                                                Забыли пароль?
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="register-row">
                                <p>Нет аккаунта?</p>
                                <p className="bold">Мы вас зарегистрируем</p>
                                <div className="reg-buttons centered">
                                    <Button href={'/register'}>Регистрация</Button>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </form>
            </div>
        </>
    );
})

export default LogIn;