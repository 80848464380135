import { makeAutoObservable } from "mobx";
import axios from "./axios";
import store from "store";
import {message} from "antd";
import sha1 from "js-sha1";

class UserStore {

    user = {}
    contactsView = null;
    contactInfo = {};
    adminPhone = false;
    qnaList = [];
    documentsList = [];
    form = {
        name: "",
        email: "",
        phone: "",
        message: "",
        files: []
    }
    reviews = [];
    documentsPage = 1;
    documentsTotal = 10;
    reviewsPage = 1;
    reviewsTotal = 10;

    codePhoneSend = false
    codeEmailSend = false
    phoneCode = ""
    emailCode = ""

    constructor() {
        makeAutoObservable(this);
    }

    onChangeUser(name, value) {
        this.user[name] = value;
    }

    onChangeCode(type, value) {
        if(type === "phone"){
            this.phoneCode = value
        } else if(type === "email") {
            this.emailCode = value
        }
    }

    getUser(){
        axios.get("/user/get").then(success => {
            this.user = success.data
            store.set("user", success.data)
            this.user.password = ""
            this.user.confirm = ""
        })
    }

    saveUser(e){
        e.preventDefault()

        let facts = new FormData();
        facts.append("firstname", this.user.firstname)

        if(this.user.password !== "") {
            if(this.user.password !== this.user.confirm) {
                return message.error("Пароли не совпадают")
            }
            facts.append("password", sha1(this.user.password))
        }

        axios.post("/user/edituser/", facts).then(success => {
            console.log(success.data)
            store.set("user", success.data)
            message.success('Данные успешно изменены')
        });
    }

    changeCredintails(type) {
        let facts = new FormData()
        if(type === "phone"){
            if(this.user.phone === store.get("user").phone) return message.error("Сначала укажите новый номер телефона")
            facts.append("phone", this.user.phone)
            facts.append("login", this.user.phone)
            facts.append("type", 1)
        } else if(type === "email") {
            if(this.user.email === store.get("user").email) return message.error("Сначала укажите новый email")
            facts.append("email", this.user.email)
            facts.append("login", this.user.email)
            facts.append("type", 0)
        } else {
            return console.log("Неверный тип")
        }

        if(this.codePhoneSend === false && this.codeEmailSend === false){
            axios.post("/user/codebefore/", facts).then(success => {
                if(success.data === true){
                    if(type === "phone"){
                        this.codePhoneSend = true
                    } else if(type === "email") {
                        this.codeEmailSend = true
                    }
                } else {
                    message.error("Произошла ошибка")
                }
                console.log(success.data)
            }).catch(error => console.log(error.response))
        } else {
            if(type === "phone"){
                facts.append("code", this.phoneCode)
            } else if(type === "email") {
                facts.append("code", this.emailCode)
            }
            axios.post("/user/changeuser/", facts).then(success => {
                store.set("user", success.data)
                window.location.reload()
            }).catch(error => console.log(error.response))
        }
    }

    getContact(userID) {
        axios.get("/user/userbyid/" + userID).then(success => {
            this.contactInfo = success.data
        }).catch(error => console.log(error.response))
    }

    getContacts() {
        if(store.get("user") && store.get("user").id > 0){
            let datepay = store.get("user").datepay;
            let now = Number((Date.now() / 1000).toFixed(0));
            let views = store.get("views");
            if(!views || datepay > now){
                views = 0;
                store.set("views", 0);
            } else {
                views++;
            }
            if(views <= 3) {
                axios.post("/user/userview").then(success => {
                    store.set("views", views);
                    console.log(views++);
                    this.contactsView = success.data;
                }).catch(error => console.log(error.response));
            } else {
                message.error("Бесплатные просмотры закончились. Для просмотра номера телефона оплатите расширенный доступ");
            }
        } else {
            return message.error("Для просмотра номера телефона зарегистрируйтесь или войдите в аккаунт");
        }
    }

    getQna() {
        axios.get("/user/questansw").then(success => {
            this.qnaList = success.data;
        }).catch(error => console.log(error.response));
    }

    getDocuments(page = 1) {
        this.documentsPage = page;
        if(this.documentsList.length >= this.documentsTotal) return;
        axios.get("/document/document?page=" + page).then(success => {
            this.documentsTotal = success.data[1];
            this.documentsList = success.data[0];
        }).catch(error => console.log(error.response));
    }

    setContactField(name, value) {
        if(name === "files"){
            this.form.files.push(value);
        } else {
            this.form[name] = value;
        }
    }

    removeFormFile(name) {
        const index = this.form.files.indexOf(name);
        if(index > -1) {
            this.form.files.splice(index, 1);
        }
    }

    sendContactForm(e) {
        e.preventDefault();
        if(this.form.phone.indexOf('_') + 1) {
            return message.error("Неверный формат телефона");
        }

        let facts = new FormData();

        if(this.form.name.length < 2){
            return message.error("Имя не должно быть короче 2 символов");
        }
        if(this.form.email.length < 1 && this.form.phone.length < 1){
            return message.error("Необходимо указать как минимум один способ связи");
        }
        if(this.form.message.length < 10){
            return message.error("Сообщение не должно быть короче 10 символов");
        }
        facts.append("name", this.form.name);
        facts.append("email", this.form.email);
        facts.append("phone", this.form.phone);
        facts.append("message", this.form.message);
        facts.append("files", JSON.stringify(this.form.files));

        this.form = {
            name: null,
            email: null,
            phone: "",
            message: null,
            files: []
        }

        axios.post("/user/form", facts).then(success => {
            if(success.data === true){
                message.success("Сообщение успешно отправлено");
                this.form = {
                    name: null,
                    email: null,
                    phone: null,
                    message: null,
                    files: []
                }
            } else {
                message.error("Произошла ошибка, попробуйте позже")
            }
        }).catch(error => console.log(error.response));
    }

    getReviews() {
        let page = this.reviewsPage;
        if(this.reviews.length >= this.reviewsTotal) return;
        axios.get("/review/review?page=" + page).then(success => {
            this.reviewsTotal = success.data[1];
            if(this.reviewsPage > 1){
                this.reviews.push(...success.data[0]);
            } else {
                this.reviews = success.data[0];
            }
            this.reviewsPage++;
        }).catch(error => console.log(error.response));
    }

    makePayment(e, type, summ, advertid = 0){
        e.preventDefault();
        let facts = new FormData();
        facts.append("typepay", type);
        facts.append("summ", summ);
        if(type === 1 && advertid > 0) facts.append("advertid", advertid);

        axios.post("/user/pay/", facts).then(success => {
            window.open(success.data.url);
        }).catch(error => console.log(error.response));
    }
}

export default new UserStore();