import React, {useEffect} from 'react';
import {Button, Checkbox, Col, Input, InputNumber, message, Modal, Radio, Row, Select, Space, Upload} from "antd";
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import {Map, Placemark, YMaps, Circle} from "react-yandex-maps";
import pin from "../images/pin.svg";
import errorIcon from "../images/error.svg";
import createAdvertStore from "../store/createAdvertStore";
import {observer} from "mobx-react-lite";
import advert from "../store/advert";
import config from "../config.json"
import store from "store";
import "quill/dist/quill.snow.css";
import categoryStore from "../store/categoryStore";
import YandexShare from 'react-yandex-share';
const { Option } = Select;
const { TextArea } = Input;

createAdvertStore.whatCity();

const CreateAdvert = observer((props) => {
    useEffect(() => {
        if(props.advertID && props.advertID > 0) {
            createAdvertStore.getAdvert(props.advertID);
        } else {
            createAdvertStore.getAdvertLocal();
            createAdvertStore.setCoordinatesByCity();
        }
        createAdvertStore.getMetro();

        createAdvertStore.getCityList();

        if(store.get("info") && store.get("info").city && store.get("info").city > 0) {
            createAdvertStore.advert.cityid = store.get("info").city;
        } else {
            createAdvertStore.advert.cityid = config.cityID;
        }
    }, []);

    const propsImage = {
        accept: ".jpg, .jpeg, .png",
        maxCount: 5,
        multiple: true,
        showUploadList: {showRemoveIcon: true, showPreviewIcon: false},
        listType: "picture-card",
        fileList: createAdvertStore.advert.photo,
        name: 'file',
        action: config.apiUrl + "/upload/upload",
        headers: {
            "Type-Device": config.typeDevice,
        },
        data: {
            path: "objects",
        },
        onChange: (info) => {
            createAdvertStore.advert.photo = info.fileList
        }
    };

    let canSetName = true;
    if(categoryStore.list.length > 0 && createAdvertStore.advert.category > 0){
        canSetName = Boolean(categoryStore.list.find(i => i.id === createAdvertStore.advert.category).nameobject)
    }
    canSetName = (canSetName !== true);

    return(
        <>
            <Modal
                title="Поделиться объявлением"
                visible={createAdvertStore.share}
                onCancel={() => document.location.href="/entity"}
                footer={null}
            >
                <p>Вы можете поделиться объявлением в своём аккаунте в социальных сетях или месседжерах</p>
                <YandexShare
                    content={{ title: 'My page', url: "https://nedvizher.ru/user/" + createAdvertStore.serverAnswer.userid + "/entity/" + createAdvertStore.serverAnswer.id }}
                    theme={{ size: 'l', services: 'vkontakte,telegram,whatsapp,odnoklassniki,viber', shape: 'round' }}
                />
            </Modal>
            {props.advertID && props.advertID > 0 && createAdvertStore.advert.status === 0 && createAdvertStore.advert.texterror !== "" &&
                <Row className="error">
                    <Col span={2}>
                        <img src={errorIcon} alt=""/>
                    </Col>
                    <Col span={22}>
                        <p className="title">Причина отказа</p>
                        <p className="text">{createAdvertStore.advert.texterror}</p>
                    </Col>
                </Row>
            }
            <form onSubmit={e => e.preventDefault()} style={{width: "100%"}}>
                <Row gutter={[28, 0]} className="create-item-block">
                    <Col xs={24} md={6} lg={4}>
                        <div className="item-label">Категория</div>
                    </Col>
                    <Col xs={24} md={9} lg={10}>
                        <Select
                            listHeight={400}
                            showSearch
                            value={createAdvertStore.advert.category}
                            placeholder="Выберите категорию"
                            onChange={e => categoryStore.categoryChange(e)}
                            filterOption={false}
                            autoClearSearchValue={false}
                            onSearch={e => categoryStore.filterCategoryList(e)}
                        >
                            {categoryStore.categoryFilterList.map((e, i) => <Option value={e.id} key={i}>{e.name}</Option>)}
                        </Select>
                        {createAdvertStore.error.category &&
                            <p className="text red">Поле обязательно для заполнения</p>
                        }
                    </Col>
                    <Col xs={24} md={9} lg={10}>
                        <Select
                            listHeight={400}
                            disabled={createAdvertStore.advert.category === 0 || categoryStore.sublist.length === 0}
                            value={createAdvertStore.advert.subcategory}
                            placeholder="Выберите подкатегорию"
                            onChange={e => createAdvertStore.onChange("subcategory", e)}
                        >
                            <Option value="0" disabled>{categoryStore.sublist.length > 0 ? "Выберите подкатегорию" : "Нет подкатегорий"}</Option>
                            {categoryStore.sublist.map(e => <Option value={e.id}>{e.name}</Option>)}
                        </Select>
                    </Col>
                </Row>
                <Row gutter={[28, 0]} className="create-item-block">
                    <Col xs={24} md={6} lg={4}>
                        <div className="item-label">Тип сделки</div>
                    </Col>
                    <Col xs={24} md={9} lg={10}>
                        <Select
                            value={createAdvertStore.advert.typeAdvert}
                            onChange={(e) => createAdvertStore.onChange("typeAdvert", e)}
                            required
                        >
                            {advert.types.type.map((item, i) => (
                                <Option key={i} value={item.id}>{item.name}</Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
                <Row gutter={[28, 0]} className="create-item-block">
                    <Col xs={24} md={6} lg={4}>
                        <div className="item-label">Название товара</div>
                    </Col>
                    <Col xs={24} md={18} lg={20}>
                        <Input
                            disabled={canSetName}
                            placeholder="Например: Уютная студия"
                            value={createAdvertStore.advert.name}
                            onChange={(e) => createAdvertStore.onChange("name", e.target.value)}
                            required
                        />
                        <p className="text">Не используйте слова продам, куплю и т.д. в названии объявления.</p>
                        {createAdvertStore.error.name &&
                            <p className="text red">Поле обязательно для заполнения</p>
                        }
                    </Col>
                </Row>
                {(createAdvertStore.advert.typeAdvert > 0 && [1, 2, 4, 5, 6, 8].includes(createAdvertStore.advert.typeAdvert)) &&
                    <Row gutter={[28, 0]} className="create-item-block">
                        <Col xs={24} md={6} lg={4}>
                            <div className="item-label">Цена, руб</div>
                        </Col>
                        {createAdvertStore.advert.typeAdvert === 1 &&
                            <>
                                <Col xs={24} md={6} xl={5} className="input-row">
                                    <span>от</span>
                                    <InputNumber
                                        disabled={Boolean(createAdvertStore.advert.stringroom2)}
                                        controls={false}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                        parser={value => value.replace(/\$\s?|( *)/g, '')}
                                        value={createAdvertStore.advert.priceFrom}
                                        onChange={(e) => createAdvertStore.onChange("priceFrom", e)}
                                    />
                                    <p className="text">Не обязательно для заполнения</p>
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={6} className="singleCheckbox">
                                    <Checkbox
                                        checked={Boolean(createAdvertStore.advert.stringroom2)}
                                        onChange={(e) => createAdvertStore.onChange("stringroom2", e.target.checked)}
                                    >
                                        Договорная
                                    </Checkbox>
                                </Col>
                            </>
                        }
                        {(createAdvertStore.advert.typeAdvert === 2 || createAdvertStore.advert.typeAdvert === 5) &&
                            <>
                                <Col xs={24} md={6} xl={5}>
                                    <InputNumber
                                        controls={false}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                        parser={value => value.replace(/\$\s?|( *)/g, '')}
                                        value={createAdvertStore.advert.priceFrom}
                                        onChange={(e) => createAdvertStore.onChange("priceFrom", e)}
                                    />
                                    {createAdvertStore.error.price &&
                                        <p className="text red">Поле обязательно для заполнения</p>
                                    }
                                </Col>
                                <Col xs={24} md={6} xl={5}>
                                    <Select
                                        value={(createAdvertStore.advert.stringroom2).toString()}
                                        onChange={(e) => createAdvertStore.onChange("stringroom2", e)}
                                    >
                                        <Option value="1">Посуточно</Option>
                                        <Option value="2">Долгосрочно</Option>
                                    </Select>
                                </Col>
                            </>
                        }
                        {createAdvertStore.advert.typeAdvert === 4 &&
                            <Col xs={24} md={6} xl={5}>
                                <InputNumber
                                    controls={false}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                    parser={value => value.replace(/\$\s?|( *)/g, '')}
                                    value={createAdvertStore.advert.priceFrom}
                                    onChange={(e) => createAdvertStore.onChange("priceFrom", e)}
                                />
                                {createAdvertStore.error.price &&
                                    <p className="text red">Поле обязательно для заполнения</p>
                                }
                            </Col>
                        }
                        {createAdvertStore.advert.typeAdvert === 6 &&
                            <Col xs={24} md={6} xl={5}>
                                <InputNumber
                                    controls={false}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                    parser={value => value.replace(/\$\s?|( *)/g, '')}
                                    value={createAdvertStore.advert.priceFrom}
                                    onChange={(e) => createAdvertStore.onChange("priceFrom", e)}
                                />
                                <p className="text">Не обязательно для заполнения</p>
                            </Col>
                        }
                        {createAdvertStore.advert.typeAdvert === 8 &&
                            <>
                                <Col xs={24} md={6} xl={5} className="input-row">
                                    <span>от</span>
                                    <InputNumber
                                        controls={false}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                        parser={value => value.replace(/\$\s?|( *)/g, '')}
                                        value={createAdvertStore.advert.priceFrom}
                                        onChange={(e) => createAdvertStore.onChange("priceFrom", e)}
                                    />
                                    <p className="text">Не обязательно для заполнения</p>
                                </Col>
                                <Col xs={24} md={6} xl={5} className="input-row">
                                    <span>до</span>
                                    <InputNumber
                                        controls={false}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                        parser={value => value.replace(/\$\s?|( *)/g, '')}
                                        value={createAdvertStore.advert.priceTo}
                                        onChange={(e) => createAdvertStore.onChange("priceTo", e)}
                                    />
                                    <p className="text">Не обязательно для заполнения</p>
                                </Col>
                            </>
                        }
                    </Row>
                }
                <Row gutter={[28, 0]} className="create-item-block">
                    <Col xs={24} md={6} lg={4}>
                        <div className="item-label">Описание</div>
                    </Col>
                    <Col xs={24} md={18} lg={20}>
                        <TextArea
                            rows={5}
                            value={createAdvertStore.advert.text}
                            onChange={(e) => createAdvertStore.onChange("text", e.target.value)}
                        />
                    </Col>
                </Row>
                <Row gutter={[28, 0]} className="create-item-block">
                    <Col xs={24} md={6} lg={4}>
                        <div className="item-label">Фотографии</div>
                    </Col>
                    <Col xs={24} md={18} lg={20}>
                        <Upload {...propsImage}>
                            <Button icon={<UploadOutlined />}>Нажмите для загрузки</Button>
                        </Upload>
                        <p className="text">Изображения формата jpg или png</p>
                    </Col>
                </Row>
                <Row gutter={[28, 0]} className="create-item-block">
                    <Col xs={24} md={6} lg={4}>
                        <div className="item-label">Местоположение</div>
                    </Col>
                    <Col xs={24} md={6} lg={4}>
                        <Select
                            showSearch
                            value={createAdvertStore.advert.cityid}
                            placeholder="Выберите город"
                            onChange={e => {
                                createAdvertStore.onChange("cityid", e);
                                createAdvertStore.getCoordinatesByCityID(e);
                            }}
                            filterOption={false}
                            autoClearSearchValue={false}
                            onSearch={e => createAdvertStore.filterCityList(e)}
                        >
                            {createAdvertStore.cityListFiltered.map((item) => <Option value={item.id}>{item.name}</Option>)}
                        </Select>
                        {createAdvertStore.error.city &&
                            <p className="text red">Поле обязательно для заполнения</p>
                        }
                    </Col>
                    <Col xs={24} md={12} lg={14}>
                        <Input
                            required
                            value={createAdvertStore.advert.address}
                            placeholder="Введите адрес"
                            id="suggest"
                            onBlur={() => createAdvertStore.getCoordinatesByAddress()}
                            onChange={(e) => createAdvertStore.onChangeAddress(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row gutter={[28, 0]} className="create-item-block">
                    <Col xs={24} md={6} lg={4}>

                    </Col>
                    <Col xs={24} md={18} lg={18}>
                        <div className="item-map">
                            <YMaps
                                query={{
                                    apikey: "b9e75516-9bc0-46b3-a32d-9c219e8c409a",
                                }}
                                version={'2.1'}
                            >
                                <Map
                                    state={{ center: createAdvertStore.coordinate, zoom: 9 }} width={100 + "%"}
                                    modules={["geolocation", "geocode", "SuggestView", "Placemark"]}
                                    onLoad={ymaps => {}}
                                    height={540}
                                    onClick={e => {
                                        createAdvertStore.coordinate = e.get("coords")
                                        createAdvertStore.getAddressByCoordinates()
                                    }}
                                >
                                    <Placemark
                                        id="place"
                                        geometry={createAdvertStore.coordinate}
                                        options={{
                                            iconLayout: 'default#image',
                                            iconImageOffset: [-15, -28],
                                            iconImageHref: pin
                                        }}
                                    />
                                </Map>
                            </YMaps>
                        </div>
                    </Col>
                </Row>
                <Row gutter={[28, 0]} className="create-item-block">
                    <Col xs={24}>
                        <div className="item-label-big">Уточните характеристики</div>
                    </Col>
                </Row>
                {categoryStore.filters.length > 0 &&
                    categoryStore.filters.map(item => {
                        return (<>
                            <Row gutter={[28, 0]} className="create-item-block">
                                <Col xs={24} md={6} lg={4}>
                                    <div className="item-label">{item.name}</div>
                                </Col>
                                {item.type === "radio" &&
                                    <Col xs={24} md={9} lg={10}>
                                        <Select
                                            defaultValue={createAdvertStore.advert.filteradvert[item.namevalue]}
                                            onChange={(e) => createAdvertStore.onChangeFilter(item.namevalue, e)}
                                        >
                                            {item.filtervalue.map(f => (
                                                <Option value={f.id}>{f.label}</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                }
                                {item.type === "checked" &&
                                    <Col xs={24} md={9} lg={10}>
                                        <Radio.Group
                                            defaultValue={createAdvertStore.advert.filteradvert[item.namevalue]}
                                            onChange={(e) => createAdvertStore.onChangeFilter(item.namevalue, e.target.value)}
                                        >
                                            {item.filtervalue.map(f => (
                                                <Radio.Button value={f.id}>{f.label}</Radio.Button>
                                            ))}
                                        </Radio.Group>
                                    </Col>
                                }
                                {/*{item.type === "range" &&*/}
                                {/*    <Col xs={24} md={9} lg={10}>*/}
                                {/*        <InputNumber*/}
                                {/*            controls={false}*/}
                                {/*            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}*/}
                                {/*            parser={value => value.replace(/\$\s?|( *)/g, '')}*/}
                                {/*            value={createAdvertStore.advert.filteradvert[item.namevalue]}*/}
                                {/*            onChange={(e) => createAdvertStore.onChangeFilter(item.namevalue, e.target.value)}*/}
                                {/*        />*/}
                                {/*    </Col>*/}
                                {/*}*/}
                            </Row>
                        </>)
                    })
                }
                <Row gutter={[28, 0]} className="create-item-block">
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} className="singleCheckbox">
                        <Checkbox
                            checked={createAdvertStore.advert.viewPhone}
                            onChange={(e) => createAdvertStore.setViewPhone(e.target.checked)}
                        >
                            Показывать телефон
                        </Checkbox>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} className="singleCheckbox">
                        <Checkbox
                            checked={createAdvertStore.advert.viewChat}
                            onChange={(e) => createAdvertStore.setViewChat(e.target.checked)}
                        >
                            Разрешить писать в чат
                        </Checkbox>
                    </Col>
                    {createAdvertStore.error.contact &&
                        <Col xs={24}>
                            <p className="text red">Необходимо выбрать хотя бы один способ связи</p>
                        </Col>
                    }

                </Row>
                <Row gutter={[28, 0]} className="create-item-block">
                    <Col xs={24}>
                        <div className="btn-animated" onClick={(e) => createAdvertStore.sendForm(e)} style={{cursor: "pointer"}}>
                            <Button
                                id="btnSubmit"
                                htmlType="button"
                                type="primary"
                                disabled={createAdvertStore.buttonDisable}
                            >
                                <span>{store.get("user") && store.get("user").id > 0 ? ("Опубликовать") : ("Продолжить")}</span>
                            </Button>
                        </div>
                        {Object.values(createAdvertStore.error).indexOf(true) > -1 &&
                            <p className="text red">Некоторые поля требуют вашего внимания</p>
                        }
                    </Col>
                </Row>
            </form>
        </>
    )
});

export default CreateAdvert;