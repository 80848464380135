import {observer} from "mobx-react-lite";
import React, {useEffect} from "react";
import searchStore from "./searchStore";
import HeaderPage from "../../components/Header";
import {Col, Radio, Row, Select} from "antd";
import {ReactComponent as ListIcon} from "../../images/list-list.svg";
import {ReactComponent as MapIcon} from "../../images/list-map.svg";
import SingleAdvert from "../../components/modules/SingleAdvert";
import AdvertsMap from "../../components/modules/AdvertsMap";
import advert from "../../store/advert";
import NullList from "../../components/NullList";
import handleViewport from "react-in-viewport";


const SearchList = observer((props) => {

    useEffect(() => {
        searchStore.getSearch(props.city, props.category, props.type, props.query);
        searchStore.onChange("city", Number(props.city));
        searchStore.onChange("type", Number(props.type));
        searchStore.onChange("category", Number(props.category));
        searchStore.onChange("string", decodeURIComponent(props.query));
    }, []);

    const triggerBlock = (props) => {
        const {forwardedRef} = props;
        return (
            <div className="loadmore-products" ref={forwardedRef} />
        );
    };

    const ViewportBlock = handleViewport(triggerBlock);

    const loadMoreProducts = () => {
        if(advert.lastPage === false) {
            advert.page++;
            searchStore.getSearch(props.city, props.category, props.type, props.query);
        }
    }

    return(
        <>
            <HeaderPage type={0}/>
            <Row gutter={[48, 0]}>
                <Col xs={24} className="section-header">
                    <h2 className="category-heading">Объявления по запросу{props.query !== "" ? ' "' + decodeURIComponent(props.query) + '"' : ""}</h2>
                    {advert.adverts !== [] && advert.adverts.length > 0 &&
                        <Radio.Group
                            defaultValue="list"
                            onChange={(e) => advert.editTypeSearch(e.target.value)}
                            className="section-selector"
                        >
                            <Radio.Button value="list"><ListIcon />Списком</Radio.Button>
                            <Radio.Button value="map"><MapIcon />На карте</Radio.Button>
                        </Radio.Group>
                    }
                </Col>
                <Col xs={24}>
                    {advert.adverts !== [] && advert.adverts.length > 0 ? (
                        <>
                            {advert.typeSearch === "list" &&
                                <Row gutter={[0, 25]} className="nadverts-list">
                                    {advert.adverts.length > 0 && advert.adverts.map(item =>
                                        <Col flex="0 0 20%">
                                            <SingleAdvert item={item}/>
                                        </Col>
                                    )}
                                    <ViewportBlock onEnterViewport={loadMoreProducts} />
                                </Row>
                            }
                            {advert.typeSearch === "map" &&
                                <AdvertsMap />
                            }
                        </>
                    ) : (
                        <>
                            <NullList title="Нет объявлений по заданным параметрам" />
                        </>
                    )}
                </Col>
            </Row>
        </>
    )
});

export default SearchList;