import React, {useState, useEffect} from 'react';
import HeaderPage from "../../components/Header";

import CreateAdvert from "../../components/CreateAdvert";

import { Typography } from 'antd';

export default function MyAdvert(props) {

    return(
        <>
            <HeaderPage type={0}/>

            <div className="myAdvertsHead">
                <Typography.Title>Объявление</Typography.Title>
            </div>
            <CreateAdvert advertID={props.advertID} />
        </>
    )
}