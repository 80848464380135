import {observer} from "mobx-react-lite";
import HeaderPage from "./Header";
import React from "react";
import {Row, Col} from "antd";

const termsPage = observer(() => {

    return(
        <>
            <HeaderPage type={0}/>
            <Row className="advert" gutter={[30, 0]}>
                <Col xs={24} sm={8} className="page-header-block">
                    <h1 className="favorites-h1">Условия использования НЕДВИЖЕР</h1>
                </Col>
                <Col xs={24}>
                    <p>Добро пожаловать на НЕДВИЖЕР — сервис заявок (объявлений), который объединяет покупателей и продавцов.</p>
                    <p>Условия использования НЕДВИЖЕР («Условия») регулируют отношения между ООО «НЕДВИЖЕР» («Компания», «мы») и пользователями интернета, которые осуществили доступ к НЕДВИЖЕР («пользователи», «Вы»). Вы можете использовать НЕДВИЖЕР в соответствии с положениями Условий и <a href="/privacy">политики конфиденциальности</a>. Если Вы с ними не согласны, вам необходимо прекратить использование НЕДВИЖЕР.</p>
                    <p className="boldtext">1. Что такое НЕДВИЖЕР</p>
                    <p>НЕДВИЖЕР — это интернет-сайт, доступный по адресу НЕДВИЖЕР.РФ (nedvizher.ru) (включая все уровни домена) через полную и мобильную версии и мобильное приложение, представляющий собой совокупность содержащихся в информационной системе объектов интеллектуальной собственности Компании и информации (административного и пользовательского контента) («НЕДВИЖЕР»). НЕДВИЖЕР представляет собой электронный каталог заявок (объявлений) о товарах, услугах и других предложениях (вместе — «товары»), которые пользователи могут искать и предлагать на НЕДВИЖЕР с целью заключения сделок.</p>
                    <p>Компания предоставляет авторизованным пользователям техническую возможность размещать информацию на НЕДВИЖЕР в формате заявок (объявлений) в представленных категориях товаров. Компания предоставляет всем пользователям (независимо от авторизации) техническую возможность искать и просматривать заявки (объявления) на НЕДВИЖЕР в целях, предусмотренных Условиями. Автоматические алгоритмы НЕДВИЖЕР обрабатывают запросы, чтобы предоставлять релевантную информацию в результатах поиска.</p>
                    <p>Компания не является участником, организатором сделки, покупателем, продавцом, работодателем, посредником, агентом, представителем какого-либо пользователя, выгодоприобретателем или иным заинтересованным лицом в отношении сделок между пользователями. Пользователи используют размещённую на НЕДВИЖЕР информацию, чтобы заключать сделки на свой риск без прямого или косвенного участия или контроля со стороны Компании.</p>
                    <p className="boldtext">2. Регистрация на НЕДВИЖЕР</p>
                    <p>Вы можете зарегистрироваться на НЕДВИЖЕР с помощью номера мобильного телефона или с помощью адреса электронной почты, представленной в форме входа на НЕДВИЖЕР. После регистрации мы создадим ваш уникальный профиль. Одновременно на НЕДВИЖЕР может быть только один профиль, привязанный к одному номеру телефона или электронной почты.</p>
                    <p>Для доступа ко всем возможностям сервиса мы можем попросить вас указать номер телефона и адрес электронной почты.</p>
                    <p>Регистрируясь на НЕДВИЖЕР и при каждом случае входа на НЕДВИЖЕР, Вы гарантируете, что обладаете всеми правомочиями, необходимыми для заключения и исполнения Условий.</p>
                    <p>Дополнительная информация:</p>
                    <p><a href="/checkin">Регистрация</a></p>
                    <p className="boldtext">3. Вход на НЕДВИЖЕР</p>
                    <p>Вы можете войти в свой профиль на НЕДВИЖЕР (авторизоваться) либо с помощью номера мобильного телефона/адреса электронной почты, указанных в вашем профиле, связанной с вашим профилем («данные для входа»). Для этого вам нужно ввести пароль.</p>
                    <p>Вы обязаны сохранять конфиденциальность своих данных для входа на НЕДВИЖЕР. Если у вас есть основания полагать, что кто-то получил к ним несанкционированный доступ, немедленно сообщите нам об этом. Все действия, совершённые на НЕДВИЖЕР лицом, авторизованным с помощью ваших данных для входа, будут считаться совершёнными вами. Ответственность за такие действия будете нести вы.</p>
                    <p className="boldtext">4. Сведения о пользователях</p>
                    <p>Сведения, которые Вы предоставляете Компании о себе, должны быть достоверными, актуальными и не должны нарушать законодательство и права третьих лиц. Вы обязаны по мере необходимости обновлять их в своём профиле на НЕДВИЖЕР. Мы можем запросить у вас документы или информацию для верификации или подтверждения полномочий. Если Вы их не предоставите, мы будем вправе ограничить доступ к вашему профилю.</p>
                    <p>Мы не можем гарантировать, что вся информация, которую пользователи указывают на НЕДВИЖЕР, соответствует действительности. Будьте осмотрительны при совершении сделок.</p>
                    <p>В отдельных случаях мы можем попросить вас пройти верификацию либо предоставить вам возможность сделать это по вашей собственной инициативе.</p>
                    <p>Верификация необходима для исполнения договора с вами и обеспечения безопасности пользователей НЕДВИЖЕР. В рамках проведения верификации мы вправе провести проверку благонадёжности пользователей, основываясь на представленных нам данных. Это необходимо, поскольку мы стремимся предоставлять сервис, в рамках которого пользователям будет проще доверять друг другу и заключать сделки.</p>
                    <p>Документы, которые могут быть предоставлены в целях верификации, включают паспорт, водительское удостоверение и иное. В рамках НЕДВИЖЕР могут существовать различные виды верификации, пользователю может быть предложено пройти один или больше вариантов верификации.</p>
                    <p>Факт успешного прохождения пользователем верификации не означает гарантии достоверности и актуальности информации, указанной пользователем в объявлениях и в профиле, или рекомендации другим пользователям совершать с ним сделки. Информация о верификации носит справочный характер.</p>
                    <p>Мы вправе отказать вам в прохождении верификации без объяснения причин.</p>
                    <p>В случае неуспешного прохождения верификации вам могут быть недоступны отдельные функции на НЕДВИЖЕР либо доступ к вашему профилю может быть ограничен.</p>
                    <p>Вы можете пройти верификацию только в одном профиле на НЕДВИЖЕР. Верификация доступна только для совершеннолетних пользователей.</p>
                    <p>Мы не раскрываем третьим лицам сведения о пользователях, которые не размещены в открытом доступе, за исключением случаев, предусмотренных законом (например, по запросу некоторых государственных органов). При этом мы не можем нести ответственность за сторонние неправомерные действия в отношении информации, которую Вы самостоятельно разместили в открытом доступе на НЕДВИЖЕР.</p>
                    <p>Регистрируясь на НЕДВИЖЕР и при каждом случае входа на НЕДВИЖЕР даете свое согласие:</p>
                    <p>Передачу своего номера телефона и адрес электронной почты Компании в подтверждение вашей благонадёжности, это необходимо для того, чтобы Компания могла предоставлять вам техническую возможность создать профиль на НЕДВИЖЕР и размещать какую-либо информацию, равно как для защиты интересов других пользователей, а также для предупреждения и пресечения нарушений законодательства, Условий использования НЕДВИЖЕР и договора оказания услуг.</p>
                    <p>Мы анализируем, как Вы используете НЕДВИЖЕР, чтобы делать НЕДВИЖЕР лучше и помогать вам измерять эффективность наших сервисов.</p>
                    <p className="boldtext">5. Пользовательский контент</p>
                    <p>Пользовательский контент — текст и изображения, которые пользователи НЕДВИЖЕР размещают в заявках (объявлениях), публичной части профиля и отзывах.</p>
                    <p>Компания не выступает распространителем пользовательского контента. Вы являетесь обладателем всей информации, которую размещаете на НЕДВИЖЕР. Вы самостоятельно создаете, изменяете, размещаете в открытом доступе для неограниченного круга лиц и удаляете контент (в том числе персональные данные) без нашего участия или согласования.</p>
                    <p>Вы обязаны самостоятельно обеспечить полное соответствие пользовательского контента законодательству, Условиям использования НЕДВИЖЕР, правилам размещения объявлений, требованиям к профилю и отзывам. Вы гарантируете, что ваш контент не нарушает права третьих лиц на результаты интеллектуальной деятельности.</p>
                    <p>НЕДВИЖЕР не занимается предварительной проверкой пользовательского контента. Мы предпринимаем действия в отношении контента на основании обращений уполномоченных лиц в установленном законом порядке.</p>
                    <p>Цель, с которой пользователи размещают информацию на НЕДВИЖЕР — установить контакт с потенциальным покупателем, который заинтересован в заключении сделки в отношении товара в объявлении. Запрещено размещать заведомо ложную информацию в отзывах, оставлять любые отзывы продавцам на НЕДВИЖЕР, если Вы не заинтересованы в заключении сделки по заявке (объявлению), в том числе недопустимо оставлять «взаимные отзывы» или отзывы за плату, способствующие улучшению рейтинга продавца. Запрещены сбор, копирование, использование и любые другие действия в отношении пользовательского контента в целях, не предусмотренных Условиями. Нельзя использовать номера телефонов пользователей для целей, не связанных непосредственно с приобретением товара у пользователя.</p>
                    <p>Вы предоставляете Компании право использовать ваш контент и включенные в него объекты интеллектуальной собственности на условиях неисключительной лицензии: бессрочно, без предоставления вознаграждения, любым способом, для действия во всем мире, как с указанием, так и без указания имени автора. Мы можем использовать ваш контент для исполнения Условий, улучшения НЕДВИЖЕР, в маркетинговых целях, в коммерческих и некоммерческих проектах. Мы вправе размещать пользовательский контент на других интернет-ресурсах. Мы вправе сохранять архивные копии пользовательского контента и не изымать из оборота материалы, которые его содержат.</p>
                    <p className="boldtext">6. Общение пользователей на НЕДВИЖЕР</p>
                    <p>Авторизованные пользователи могут писать сообщения через сайт НЕДВИЖЕР, чтобы обсуждать товары и условия сделки. Запрещено использовать сообщения и звонки для любых других целей.</p>
                    <p>Сообщения и звонки пользователям в целях, не предусмотренных Условиями, нарушают закон о персональных данных и причиняют убытки Компании.</p>
                    <p>Мы вправе записывать, анализировать и хранить сообщения, совершенные через сайт НЕДВИЖЕР. Сообщения на сайте НЕДВИЖЕР не являются личными. Мы вправе выборочно просматривать переписку, чтобы обеспечить соблюдение Условий использования НЕДВИЖЕР и делать НЕДВИЖЕР лучше.</p>
                    <p className="boldtext">7. Общение НЕДВИЖЕР с пользователями</p>
                    <p>Мы можем направлять вам:</p>
                    <p>• системные уведомления, от которых нельзя отписаться: например, чтобы подтвердить регистрацию, сообщить об ограничении доступа к профилю или объявлениям;</p>
                    <p>• сервисные оповещения, связанные с вашими действиями на НЕДВИЖЕР: например, о получении сообщения от другого пользователя, персональные подборки интересующих заявок (объявлений);</p>
                    <p>• маркетинговые уведомления: советы от НЕДВИЖЕР, приглашения поучаствовать в исследованиях, акции и новости Компании, НЕДВИЖЕР, и наших партнёров.</p>
                    <p>Мы можем направлять вам сообщения в центре уведомлений в вашем профиле, на адрес электронной почты или номер телефона, указанные в вашем профиле, на ваше устройство в виде пуш-уведомлений, а также в сообщениях на НЕДВИЖЕР. В некоторых случаях мы также можем обратиться к вам по телефону (например, для получения обратной связи и улучшения сервисов).</p>
                    <p className="boldtext">8. Удаление профиля</p>
                    <p>Вы вправе в любой момент удалить свой профиль на НЕДВИЖЕР, обратившись в службу поддержки. Ваш профиль будет удален при прекращении договора на условиях <a href="/offer">оферты</a>, если Вы заключали его. Мы удалим ваш профиль на НЕДВИЖЕР, если в течение трёх лет Вы им ни разу не воспользуетесь.</p>
                    <p>В течение 30 дней после удаления профиля Вы не сможете использовать для новой регистрации тот номер телефона, который был указан в удалённом профиле.</p>
                    <p className="boldtext">9. Защита интеллектуальных прав</p>
                    <p>Исключительные права на программы для ЭВМ, необходимые для использования НЕДВИЖЕР, базы данных, графическое оформление интерфейса (дизайна) и другие элементы сайта НЕДВИЖЕР, фирменное наименование, товарные знаки/логотипы и другие отличительные знаки Компании, в том числе используемые в составе доменного имени nedvizher.ru (НЕДВИЖЕР.РФ), принадлежат Компании.</p>
                    <p>Мы вправе автоматически наносить на загружаемые вами фотографии логотипы / водяные знаки НЕДВИЖЕР, чтобы защитить материалы в наших базах данных от неправомерного копирования.</p>
                    <p className="boldtext">10. Лицензия на использование баз данных и программ</p>
                    <p>Мы предоставляем пользователям неисключительную лицензию на использование баз данных и программ для ЭВМ, необходимых для использования НЕДВИЖЕР, для целей, предусмотренных в Условиях. Вознаграждение за лицензию не взимается.</p>
                    <p>Лицензия предоставляется с момента доступа к НЕДВИЖЕР и продолжает действовать, пока Вы используете НЕДВИЖЕР. Поиск и просмотр заявок (объявлений) доступен всем пользователям независимо от регистрации и авторизации. Для того, чтобы получить доступ к другим функциональным возможностям НЕДВИЖЕР, нужно авторизоваться.</p>
                    <p>Без согласия Компании запрещено использовать технические средства для взаимодействия с сервисом в обход обычного порядка использования баз данных и программ для ЭВМ. В том числе запрещено использовать автоматизированные скрипты для сбора информации на НЕДВИЖЕР.</p>
                    <p>Без согласия Компании запрещено использовать базы данных и программы для ЭВМ в целях, не предусмотренных Условиями. Это означает, что запрещено извлекать материалы из баз данных и/или осуществлять их последующее использование в любой форме и любым способом, независимо от их объема и согласования с третьими лицами, если это не связано непосредственно с заключением сделки в отношении товара в объявлении. Например, запрещено использовать материалы из баз данных НЕДВИЖЕР для наполнения баз данных третьих лиц. Также запрещено использовать номера телефонов из баз данных НЕДВИЖЕР для звонков пользователям НЕДВИЖЕР с предложениями услуг или товаров.</p>
                    <p className="boldtext">11. Обязанности пользователей</p>
                    <p>Используя НЕДВИЖЕР и взаимодействуя с Компанией, Вы обязуетесь:</p>
                    <p>• строго выполнять все требования законодательства;</p>
                    <p>• соблюдать Условия использования НЕДВИЖЕР и другие правила НЕДВИЖЕР и указания Компании;</p>
                    <p>• не звонить пользователям НЕДВИЖЕР, если Вы не потенциальный продавец и не заинтересованы в заключении сделки по заявке (объявлению), не слать пользователям спам;</p>
                    <p>• не использовать нецензурную лексику, изображения и высказывания, которые провоцируют жестокость, ненависть или неуважительное отношение, содержат угрозы или оскорбления, оправдывают незаконные действия, не соответствуют нравственным нормам или деловой практике;</p>
                    <p>• не использовать НЕДВИЖЕР или размещённую на НЕДВИЖЕР информацию в целях, не предусмотренных Условиями;</p>
                    <p>• не загружать и не использовать на НЕДВИЖЕР вредоносные программы;</p>
                    <p>• не предпринимать действия, которые могут помешать нормальной работе НЕДВИЖЕР.</p>
                    <p className="boldtext">12. Нарушения и последствия</p>
                    <p>Мы стремимся сделать НЕДВИЖЕР удобным и безопасным инструментом, поэтому сохраняем за собой право проводить выборочную проверку выполнения пользователями Условий использования НЕДВИЖЕР в любой момент и по своему усмотрению. Для этого мы можем использовать автоматизированные методы.</p>
                    <p>Мы вправе принять меры при наличии оснований полагать, что с использованием вашего профиля или ваших технических средств произошло или может произойти нарушение законодательства, прав третьих лиц или Условий. Мы вправе ограничить доступ к вашему профилю или какой-то функциональности НЕДВИЖЕР, отклонить или заблокировать ваш контент. Мы также вправе ограничить доступ к НЕДВИЖЕР с определённых IP-адресов и телефонных номеров.</p>
                    <p>Мы можем ограничить доступ к профилю в случае поступления в отношении такого профиля жалоб других пользователей, рассылки спама, неоднократных или злостных нарушений Условий использования, взлома профиля, непредставления пользователем документов или информации по нашему запросу, ликвидации пользователя – юридического лица, а также если мы заметим в поведении пользователя на НЕДВИЖЕР признаки мошенничества, либо при наличии иных подобных обстоятельств, либо если пользователь зарегистрировал новый профиль на НЕДВИЖЕР вместо заблокированного ранее.</p>
                    <p>Мы вправе ограничить и восстановить доступ к профилю, а также определять условия такого восстановления на свое усмотрение и без пояснения причин.</p>
                    <p>Мы не несём ответственности за возможные убытки, причинённые пользователям в связи с принятием мер для предотвращения и прекращения нарушений на НЕДВИЖЕР. Рекомендуем сохранять резервную копию вашего контента на вашем устройстве.</p>
                    <p>За нарушение законодательства или прав третьих лиц при использовании НЕДВИЖЕР Вы можете нести административную, уголовную или гражданско-правовую ответственность.</p>
                    <p className="boldtext">13. Заключительные положения</p>
                    <p>Положения разделов 2, 3, 6 - 8 Условий применяются к пользователям, зарегистрированным на НЕДВИЖЕР. Все остальные положения применяются ко всем пользователям НЕДВИЖЕР независимо от регистрации и авторизации. Любая форма взаимодействия с НЕДВИЖЕР (включая просмотр информации) подтверждает ваше согласие с Условиями. Обязательства Компании предоставить доступ к функциональности НЕДВИЖЕР – встречные по отношению к вашим обязательствам соблюдать Условия. Когда за использование НЕДВИЖЕР плата не предусмотрена, законодательство о защите прав потребителей не применимо к отношениям между Компанией и пользователями. Предоставление определенной функциональности НЕДВИЖЕР может регулироваться специальными условиями.</p>
                    <p>Функциональность НЕДВИЖЕР в любой момент может изменяться, дополняться или прекращаться без предварительного уведомления пользователей. Использование НЕДВИЖЕР предлагается в режиме «как есть», то есть в том виде и объёме, в каком Компания предоставляет функциональные возможности НЕДВИЖЕР в момент обращения к ним. Мы не несём ответственности за временные сбои и перерывы в работе НЕДВИЖЕР и вызванные ими потери информации. Мы не несем ответственность за любой косвенный, случайный, неумышленный ущерб, включая упущенную выгоду или потерянные данные, вред чести, достоинству или деловой репутации, причинённый в связи с использованием НЕДВИЖЕР. НЕДВИЖЕР предназначен для пользователей в Российской Федерации, поэтому некоторая функциональность может быть ограничена или недоступна за её пределами.</p>
                    <p>НЕДВИЖЕР может содержать ссылки на сайты третьих лиц. Мы не контролируем и не несём ответственность за доступность, содержание и законность таких сторонних сайтов.</p>
                    <p>При разрешении всех споров между Компанией и пользователями применяется законодательство Российской Федерации. Все споры должны быть переданы на рассмотрение в суд в соответствии с территориальной подсудностью по месту нахождения Компании (г. Екатеринбург), если иное не предусмотрено законодательством.</p>
                    <p>Мы обновляем Условия использования НЕДВИЖЕР по мере необходимости. Рекомендуем вам периодически посещать страницу, на которой они размещены: <a href="/terms">Условия использования НЕДВИЖЕР</a>.</p>
                    <p>Продолжая пользоваться НЕДВИЖЕР после изменения Условий, Вы подтверждаете согласие с внесёнными в них изменениями.</p>
                    <p><em>Если у вас остались вопросы об Условиях использования НЕДВИЖЕР, Вы можете поискать ответы в Помощи или обратиться к специалистам службы поддержки.</em></p>
                    <p><a href="/details">Реквизиты НЕДВИЖЕР</a></p>
                </Col>
            </Row>
        </>
    )
});

export default termsPage;