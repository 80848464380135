import { makeAutoObservable } from "mobx";
import axios from "./axios";
import store from "store";
import config from "../config.json";
import advert from "./advert";
import {message} from "antd"
import categoryStore from "./categoryStore";

let MultiGeocoder = require('multi-geocoder'),
    geocoder = new MultiGeocoder({ provider: 'yandex', coordorder: 'latlong', }),
    provider = geocoder.getProvider();

let getRequestParams = provider.getRequestParams;
provider.getRequestParams = function () {
    let result = getRequestParams.apply(provider, arguments);
    result.apikey = config.apiKeyYandexMaps;
    return result;
};


class CreateAdvertStore {

    advert = {
        id: 0,
        cityid: null,
        typeAdvert: 4,
        typeObject: 5,
        typeObject2: 5,
        room: 1,
        room2: 1,
        priceFrom: null,
        priceTo: null,
        text: "",
        region: null,
        address: "",
        radius: 0,
        status: null,
        texterror: "",
        stringroom: "",
        stringroom2: 1,
        valfrom: null,
        valto: null,
        valfrom2: null,
        valto2: null,
        metro: null,
        viewPhone: true,
        viewChat: true,
        category: null,
        subcategory: "0",
        name: "",
        photo: [],
        filteradvert: []
    }
    coordinate = [0, 0];
    displayMap = true;
    searchAddrress = 0;
    metroList = [];
    getRegForm = false;

    constructor() {
        makeAutoObservable(this);
    }

    onChangeFilter(name, value) {
        let index = this.advert.filteradvert.findIndex(e => e.name === name);
        if(index > -1){
            this.advert.filteradvert.splice(index, 1);
        }
        this.advert.filteradvert.push({name: name, value: value});
    }

    onChangePhoto(file) {
        this.advert.photo.push(file)
    }

    onRemovePhoto(id) {
        let index = this.advert.photo.findIndex(e => {return e.uid === id})
        console.log(index)
        this.advert.photo.splice(index, 1)
        console.log(this.advert.photo)
    }

    getCoordinatesByAddress() {
        let address;
        let city = this.cityList.find(c => c.id === this.advert.cityid).name;
        if(!city) city = store.get("info").cityName;
        address = city + ", " + this.advert.address;
        let _this = this;
        geocoder.geocode([address])
            .then(function (res) {
                console.log(res);
                _this.setCoordinates(res.result.features[0].geometry.coordinates);
                _this.onChangeAddress(res.result.features[0].properties.name)
            });
    }

    getAddressByCoordinates() {
        //console.log(this.coordinate[0] + "," + this.coordinate[1]);
        let url = "https://geocode-maps.yandex.ru/1.x/?format=json&results=1&apikey=" + config.apiKeyYandexMaps + "&geocode=" + this.coordinate[1] + "," + this.coordinate[0];
        //console.log(url);
        axios.get(url)
            .then(success => {
                //console.log(success.data.response);
                this.advert.address = success.data.response.GeoObjectCollection.featureMember[0].GeoObject.name
            });

    }

    whatCity() {
        let cityID = config.cityID;
        if(store.get("info") && store.get("info").city && store.get("info").city > 0) {
            console.log(store.get("info"));
            cityID = store.get("info").city;
        }
        this.advert.city = cityID;
    }

    getCoordinatesByCityID(cityID) {
        let array = [0, 0];
        axios.get('/user/city/' + cityID)
            .then(success => {
                //console.log(success);
                array = [success.data.latitude, success.data.longitude];
                this.setCoordinates(array);
            })
            .catch(error => {
                message.error(error.response.data.message);
            });
        return array;
    }

    setCoordinatesByCity() {
        let cityID = config.cityID;
        if(store.get("info") && store.get("info").city > 0) {
            cityID = store.get("info").city;
        }
        this.getCoordinatesByCityID(cityID);
    }

    setViewPhone(value) {
        this.advert.viewPhone = value;
    }

    setViewChat(value) {
        this.advert.viewChat = value;
    }

    getAdvert(advertID) {
        axios.get("/object/object/" + advertID)
            .then(success => {
                console.log(success.data);
                let categoryAdv = "0", subcategoryAdv = "0";
                if(success.data.category.parentid > 0){
                    categoryAdv = success.data.category.parentid;
                    categoryStore.categoryChange(success.data.category.parentid);
                    subcategoryAdv = success.data.category.id;
                } else {
                    categoryAdv = success.data.category.id;
                }
                this.advert = {
                    id: success.data.id,
                    address: success.data.address,
                    category: categoryAdv,
                    subcategory: subcategoryAdv,
                    name: success.data.name,
                    typeAdvert: success.data.typeid,
                    priceFrom: success.data.pricefrom,
                    priceTo: success.data.priceto,
                    viewPhone: success.data.viewphone,
                    viewChat: success.data.viewchat,
                    text: success.data.text,
                    cityid: success.data.cityid,
                    photo: [],
                    filteradvert: [],
                    stringroom2: success.data.stringroom2 ?? 2
                };
                success.data.photo.map((item, index)  => {
                    let f = {
                        uid: index,
                        status: "done",
                        url: item
                    }
                    this.onChangePhoto(f);
                });

                this.advert.viewPhone = success.data.viewphone > 0;
                this.advert.viewChat = success.data.viewchat > 0;
                this.coordinate = [ success.data.latitude, success.data.longitude ];
                if(this.advert.typeObject > 0) advert.filterRoom(this.advert.typeObject);
                if(this.advert.typeObject2 > 0) advert.filterRoom2(this.advert.typeObject2)
                this.setCoordinates(this.coordinate)
            }).catch(error => console.log(error.response));
    }

    getAdvertLocal() {
        if(store.get("advert")){
            this.advert = store.get("advert");
        }
    }

    onChange(name, value) {
        this.advert[name] = value;
    }

    setCoordinates(value) {
        this.coordinate = value;
    }

    setViewMap(value) {
        this.displayMap = value;
    }

    serverAnswer = {
        id: 0,
        userid: 0
    }
    share = false;

    buttonDisable = false;

    error = {}

    setErrors() {
        this.error = {
            category: false,
            name: false,
            price: false,
            city: false,
            contact: false
        }
    }

    sendForm(e) {
        if(e !== "reg") e.preventDefault();

        this.setErrors()

        let facts = new FormData();

        let category = 0;
        if(this.advert.subcategory > 0){
            category = this.advert.subcategory;
        } else {
            category = this.advert.category;
        }
        if(category > 0){
            facts.append("parentid", category);
        } else {
            this.error.category = true
        }

        let canSetName = 1;
        if(categoryStore.list.length > 0 && this.advert.category > 0){
            canSetName = categoryStore.list.find(i => i.id === this.advert.category).nameobject
        }
        let name = "";
        if(canSetName === 1) {
            name = this.advert.name;
        }
        if(canSetName === 1 && name === ""){
            this.error.name = true
        }
        facts.append("name", name);

        let advType = this.advert.typeAdvert
        let price = this.advert.priceFrom ?? 0
        if (advType === 1){
            if(this.advert.stringroom2 === true){
                facts.append("pricefrom", 0)
            } else {
                facts.append("pricefrom", price)
            }
            facts.append("stringroom2", parseInt(this.advert.stringroom2))
        } else if (advType === 2 || advType === 5){
            if(price > 0){
                facts.append("pricefrom", price)
                facts.append("stringroom2", this.advert.stringroom2)
            } else {
                this.error.price = true
            }
        } else if (advType === 3){
            facts.append("pricefrom", price)
        } else if (advType === 4) {
            if (price > 0) {
                facts.append("pricefrom", price)
            } else {
                this.error.price = true
            }
        }
        else if (advType === 8){
            facts.append("pricefrom", price)
            facts.append("priceto", this.advert.priceTo)
        } else {
            facts.append("pricefrom", price)
        }

        facts.append("text", this.advert.text)

        if(!this.advert.cityid){
            this.error.city = true
        }
        facts.append("cityid", this.advert.cityid);
        facts.append("typeid", this.advert.typeAdvert);
        facts.append("address", this.advert.address);
        facts.append("latitude", this.coordinate[0]);
        facts.append("longitude", this.coordinate[1]);
        if(this.advert.photo.length > 0) this.advert.photo.map(i => {
            if(i.response) facts.append("photo[]", i.response.data.url)
            else facts.append("photo[]", i.url)
        })
        this.advert.filteradvert.map(k => facts.append("filtervalid[]", k.value));

        let appendViewPhone;
        if(this.advert.viewPhone === true) {
            appendViewPhone = 1;
        } else {
            appendViewPhone = 0;
        }

        let appendViewChat;
        if(this.advert.viewChat === true) {
            appendViewChat = 1;
        } else {
            appendViewChat = 0;
        }

        if(appendViewChat === 0 && appendViewPhone === 0) {
            this.error.contact = true
        }

        facts.append("viewphone", appendViewPhone);
        facts.append("viewchat", appendViewChat);

        if(Object.values(this.error).indexOf(true) > -1) return false

        if(!store.get("user")){
            store.set("advert", this.advert);
            this.getRegForm = true;
        } else {
            let url = "/object/object";

            if(this.advert.id > 0) {
                url = "/object/object/" + this.advert.id;
                facts.append("status", 1);
            }

            this.buttonDisable = true;

            axios.post(url, facts).then(success => {
                this.share = true;
                this.serverAnswer = success.data;
                store.remove("advert");
            }).catch(error => console.log(error.response));
        }
    };

    onChangeAddress(address) {
        this.displayMap = false;
        this.onChange("address", address);
    }

    payToAdvert(e, advertID) {
        e.preventDefault();
        let facts = new FormData();
        facts.append("advertid", advertID);
        axios.post("/user/pay/", facts).then(success => {
            if(success.data.url) {
                document.location.href = success.data.url;
            }
        }).catch(error => console.log(error));
    }

    getMetro() {
        let city;
        if(store.get("info")) {
            city = store.get("info").city;
        } else {
            city = config.cityID;
        }
        axios.get("/user/metro/?filter[cityid]=" + city).then(success => {
            this.metroList = success.data;
        }).catch(error => console.log(error.response));
    }

    cityList = [];
    cityListFiltered = [];

    getCityList() {
        axios.get('/user/city/')
            .then(success => {
                this.cityList = success.data;
                this.cityListFiltered = success.data;
            })
            .catch(error => {
                message.error(error.response.data.message);
            });
    }

    filterCityList(search) {
        this.cityListFiltered = this.cityList.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
    }
}

export default new CreateAdvertStore();


