import React, {useState, useEffect} from 'react';
import HeaderPage from "../../components/Header";

import {Typography, Button, Row, Col, Result} from 'antd';
import axios from "axios";
import store from "store";
import NullList from "../../components/NullList";
import advert from "../../store/advert";
import createAdvertStore from "../../store/createAdvertStore";
import config from "../../config.json"
import AdvertByList from "../../components/AdvertByList";

export default function MyAdverts() {

    const [list, setList] = useState([]);
    const [types, setTypes] = useState([]);



    const ListAdvert = () => {
        let letTitle;
        let isSet = 0;
        let nameStatus;

        let elements = list.map(item => {
            if(letTitle !== item.status) {
                letTitle = item.status;
                isSet = 1;
                if(item.status === 0) {
                    nameStatus = "Отклонено";
                } else if(item.status === 1) {
                    nameStatus = "На модерации";
                } else if(item.status === 2) {
                    nameStatus = "Архивные";
                } else if(item.status === 3) {
                    nameStatus = "Опубликованы";
                }
            } else {
                isSet = 0;
            }

            return(
                <>
                    {isSet > 0 &&
                        <p className="title-adverts">{nameStatus}</p>
                    }
                    <AdvertByList item={item} my={true} />
                </>
            )
        });


        return <>{elements}</>
    };

    useEffect(() => {
        createAdvertStore.getAdvert(2);
        axios.get("/object/object?filter[userid]=" + store.get("user").id + "&sort=status DESC")
            .then(success => {
                if(success.data.items && success.data.items.length > 0) {
                    let items = success.data.items
                    let itemsNewOrder = [
                        [], [], [], []
                    ]
                    items.map((item, k) => {
                        if(item.status === 0){
                            itemsNewOrder[2].push(item)
                        } else if(item.status === 1){
                            itemsNewOrder[0].push(item)
                        } else if(item.status === 2){
                            itemsNewOrder[3].push(item)
                        } else if(item.status === 3){
                            itemsNewOrder[1].push(item)
                        }
                    });
                    itemsNewOrder.map(arr => {
                        arr.sort((a,b) => {
                            if(a.id < b.id){
                                return 1;
                            }
                            if(a.id > b.id){
                                return -1;
                            }
                            return 0;
                        })
                    })
                    items = itemsNewOrder[0].concat(itemsNewOrder[1]).concat(itemsNewOrder[2]).concat(itemsNewOrder[3])
                    setList(items)
                }
            })
            .catch(error => {
                console.log(error.response);
            });

        axios.get("/object/filterdata")
            .then(success => {
                let array = [];
                if(success.data.typeproperty.length > 0) {
                    success.data.typeproperty.map(item => {
                        item = {...item, name: JSON.parse(item.name)};
                        array = [...array, item];
                    });
                }
                setTypes([
                    success.data.region,
                    success.data.room,
                    success.data.type,
                    array,
                ]);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    return(
        <>
            <HeaderPage type={0}/>

            <Row justify="space-around" align="middle" className="element myAdvertsHead">
                <Col xs={24} md={12}>
                    <Typography.Title>Мои объявления</Typography.Title>
                </Col>
                <Col xs={24} md={12} align="right" className="add-cell">
                    <Button type="primary" href={"/entity/new"}>Добавить объявление</Button>
                </Col>
            </Row>

            {list.length === 0 &&
                <NullList title="У Вас пока нет объявлений" />
            }
            {list.length > 0 && types.length > 0 &&
                <div className="advertList">
                    <ListAdvert />
                </div>
            }

        </>
    )
}