import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/es/locale/ru_RU';

ReactDOM.render(
  <React.StrictMode>
      <ConfigProvider locale={ru_RU}>
            <App />
      </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
