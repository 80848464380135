import { Row, Col } from "antd"
import { InfoCircleTwoTone } from '@ant-design/icons'

const NullList = (props) => {

    return(
        <Row>
            <Col span={24} align="center">
                <InfoCircleTwoTone style={{ fontSize: 30 + "px", marginBottom: 15 + "px" }} />
            </Col>
            <Col span={24} align="center">
                {props.title}
            </Col>
        </Row>
    )
}

export default NullList;