import { makeAutoObservable } from "mobx";
import axios from "../../store/axios";
import {message} from "antd";
import advert from "../../store/advert";
import store from "store";
import config from "../../config.json"

class searchStore {
    searchQuery = {
        category: "0",
        string: "",
        city: "0",
        type: "0"
    };

    searchOnMain = false
    searchOnMainID = 0

    constructor() {
        makeAutoObservable(this);
    }

    onChange(name, value) {
        if(name === "category" || name === "city" || name === "type"){
            if(value === 0) value = "0";
        }
        this.searchQuery[name] = value;
    }

    toSearchPage(e) {
        e.preventDefault();
        return window.location.href = "/search/" + this.searchQuery.city + "/" + this.searchQuery.category + "/" + this.searchQuery.type + "/" + this.searchQuery.string
    }

    getSearch(city = 0, category = 0, type = 0, query = "") {
        let url = "/object/objectsearch/?status=3&viewmessage=0&limit=" + advert.limit + "&page=" + advert.page;
        if(city > 0){
            url += "&cityid=" + city;
        } else if(city === -1) {
            if(store.get("info") && store.get("info").city){
                url += "&cityid=" + store.get("info").city;
            } else {
                url += "&cityid=" + config.cityID;
            }
        }
        if(type > 0){
            url += "&typeid=" + type;
        }
        if(category > 0){
            url += "&categoryid=" + category;
        }

        url += "&search=" + query;

        axios.get(url)
            .then(success => {
                console.log(success);
                if(success.data.count < advert.limit) advert.lastPage = true;
                if(advert.page > 1) {
                    success.data[0].map(i => advert.adverts.push(i));
                } else {
                    advert.adverts = success.data[0];
                }
            });
    }

    cityList = [];
    cityListFiltered = [];

    getCityList() {
        axios.get('/user/city/')
            .then(success => {
                this.cityList = success.data;
                this.cityListFiltered = success.data;
            })
            .catch(error => {
                message.error(error.response.data.message);
            });
    }

    filterCityList(search) {
        this.cityListFiltered = this.cityList.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
    }
}

export default new searchStore;