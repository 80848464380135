import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {ReactComponent as ArrowDownIcon} from "../../images/arrowdown.svg";
import {Col} from "antd";

const SingleQuestion = observer((props) => {

    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };

    return(
        <>
            <Col xs={24} md={12}>
                <div
                    className={"single-question " + (isActive ? "expanded" : "")}
                    onClick={toggleClass}
                >
                    <div className="question-heading">
                        {props.item.question}
                        <ArrowDownIcon />
                    </div>
                    <div className="question-content">{props.item.answer}</div>
                </div>
            </Col>
        </>
    )
});

export default SingleQuestion;