import React from 'react';
import {Col, Row} from "antd";
import HeaderPage from "../components/Header";
import {observer} from "mobx-react-lite";
import { CopyOutlined,HomeOutlined,FormOutlined,LoginOutlined,ApartmentOutlined,PhoneOutlined,NotificationOutlined,UserOutlined,FileOutlined,ExceptionOutlined } from '@ant-design/icons';

const Map = observer(() => {

    return(
        <>
            <HeaderPage type={0}/>
            <div className="home">
                <Row justify="space-around" align="middle" className="homeHead">
                    <Col span={24}>
                        <h1>Карта сайта</h1>
                    </Col>
                </Row>
            </div>
            <Row className="sitemap">
                <Col xs={12} sm={10} md={8} lg={5}>
                    <p><HomeOutlined /><a href="/">Главная</a></p>
                    <p><NotificationOutlined /><a href="/">Заявки</a></p>
                    <p><FileOutlined /><a href="/news">Новости</a></p>
                    <p><PhoneOutlined /><a href="/contacts">Контакты</a></p>
                    <p><CopyOutlined /><a href="/details">Реквизиты</a></p>
                    <p><FileOutlined /><a href="/price">Стоимость услуг</a></p>
                </Col>
                <Col xs={12} sm={10} md={8} lg={5}>
                    <p><LoginOutlined /><a href="/signin">Вход</a></p>
                    <p><UserOutlined /><a href="/setting">Личный кабинет</a></p>
                    <p><FormOutlined /><a href="/advert/new">Разместить заявку</a></p>
                    <p><ApartmentOutlined /><a href="/map">Карта сайта</a></p>
                    <p><ExceptionOutlined /><a href="/terms">Условия использования</a></p>
                    <p><ExceptionOutlined /><a href="/offer">Оферта</a></p>
                </Col>
            </Row>
        </>
    )
});

export default Map;