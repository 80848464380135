import React, { useState, useEffect } from "react";
import HeaderPage from "../components/Header";
import {Col, Row, Avatar, Input, Button, Result, Menu, Dropdown} from "antd";
import axios from "axios";
import store from "store";
import { ReloadOutlined, ArrowLeftOutlined, LikeOutlined, DislikeOutlined } from '@ant-design/icons';
import NullList from "../components/NullList";
import advert from "../store/advert";

export default function Chat() {
    const [update, setUpdate] = useState(0);
    const [list, setList] = useState([]);
    const [active, setActive] = useState(0);
    const [activeAdvert, setActiveAdvert] = useState(0);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [typeChat, setTypeChat] = useState("user");

    useEffect(() => {
        if(active > 0) {
            console.log(active);
            axios.get("/user/message/" + active)
                .then(success => {
                    setMessages(success.data[0]);
                    document.getElementById("messages-block").scrollBy(0, document.getElementById("messages-block").scrollHeight);
                })
                .catch(error => console.log(error.response));
        }
        advert.getProperties();
    }, [update, active]);

    useEffect(() => {
        axios.get("/user/chat/" + store.get("user").id)
            .then(success => {
                setList(success.data);
                if(active === 0) {
                    setActive(success.data[0].chatid);
                    setTypeChat(success.data[0].usertype);
                    setActiveAdvert(success.data[0].advert.id);
                }
            })
            .catch(error => console.log(error.response));
    }, [update, active]);

    const setActiveChat = (id, type, advertID) => {
        setActive(id);
        setTypeChat(type);
        setActiveAdvert(advertID);
    }

    const sendMessage = () => {
        let userID;
        if(Number(messages[0].touserid) === Number(store.get("user").id)) {
            userID = messages[0].fromuserid;
        } else {
            userID = messages[0].touserid;
        }


        let facts = new FormData();
        facts.append("text", message);
        facts.append("advertid", activeAdvert);

        if(typeChat === "user") {
            facts.append("touserid", userID);
        } else {
            facts.append("touserid", 0);
        }

        facts.append("fromuserid", store.get("user").id);
        facts.append("usertype", typeChat);

        axios.post("/user/message", facts)
            .then(success => {
                console.log(success);
                setUpdate(update + 1);
                setMessage("");
            })
            .catch(error => console.log(error.response));
    };

    const sayHi = () => {
        console.log("Обновить");
        setUpdate(update + 1);
    };

    setTimeout(sayHi, 10000);
		
    const showChatArea = () => {
        document.getElementById("chatArea").classList.toggle("shown");
    }

    const dislike = (e, messageID, text, advertID) => {
        e.preventDefault();
        let facts = new FormData();
        facts.append("comment", text);
        if(text === "Не по теме") {
            facts.append("status", 1);
        } else if(text === "Не подходит") {
            facts.append("status", 3);
        } else {
            return false;
        }

        axios.post("/user/message/" + messageID, facts)
            .then(success => {
                console.log(success);
                setUpdate(update + 1);
            })
            .catch(error => console.log(error.response));
    }

    const like = (e, messageID) => {
        e.preventDefault();
        let facts = new FormData();
        facts.append("status", 2);

        axios.post("/user/message/" + messageID, facts)
            .then(success => {
                console.log(success);
                setUpdate(update + 1);
            })
            .catch(error => console.log(error.response));
    };

    const dislikeButton = (id, advertID) => (
        <Menu>
            <Menu.Item><span onClick={(e) => dislike(e, id, "Не подходит")}>Не подходит</span></Menu.Item>
            <Menu.Item><span onClick={(e) => dislike(e, id, "Не по теме")}>Не по теме</span></Menu.Item>
        </Menu>
    );


	  return(
        <>
            <HeaderPage type={0}/>
            <Row justify="space-around" align="middle" className="chatHead">
                <Col span={12}>
                    <h1>Сообщения</h1>
                </Col>
                <Col span={12} className="template-right">
                    <Button shape="round" icon={<ReloadOutlined />} onClick={() => setUpdate(update + 1)}>
                        Обновить
                    </Button>
                </Col>
            </Row>
            {list.length === 0 &&
                <NullList title="У Вас пока нет активных чатов" />
            }
            {list.length > 0 &&
                <Row className="chat">
                    <Col xs={24} sm={12} md={8} xl={6} className="left">
                        <div className="in">
                            {list.length > 0 &&
                            <>
                                {list.map(item => {
                                    let date = new Date(item.date * 1000);
                                    let text = item.text.substring(0, 30) + "...";
                                    return (
                                        <div
                                            className={active === item.chatid ? "people active" : "people"}
                                            key={"people-" + item.id}
                                            onClick={() => {
                                                setActiveChat(item.chatid, item.usertype, item.advertid);
                                                showChatArea()
                                            }}
                                        >
                                            <Avatar size={38}>USER</Avatar>
                                            <div className="in-in">
                                                <p className="name">{item.user.firstname} {item.user.lastname}</p>
                                                <a href={"/user/" + item.advert.userid + "/entity/" + item.advertid}>
                                                    {advert.getAdvertName(item.advert)}
                                                </a>
                                                <p className="text">{text}</p>
                                                <p className="date">{date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()} {String(date.getHours()).padStart(2, "0")}:{String(date.getMinutes()).padStart(2, "0")}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                            }
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={16} xl={18} className="right" id="chatArea">
                        <div id="backToChats" onClick={() => showChatArea()}><ArrowLeftOutlined
                            style={{fontSize: '16px', clor: '#fff'}}/> К списку диалогов
                        </div>
                        {messages.length > 0 &&
                        <div className="txt" id="messages-block">
                            {messages.map(item => {
                                let date = new Date(item.date * 1000);

                                return (
                                    <div
                                        className={Number(item.fromuserid) === Number(store.get("user").id) ? "chat-position-my" : "chat-position"}
                                        key={"message-" + item.id}
                                    >
                                        <div
                                            className={Number(item.fromuserid) === Number(store.get("user").id) ? "chat_dialog chat_dialog_message_my" : "chat_dialog chat_dialog_message"}>
                                            {item.type === 1
                                                ? <p className="text-message">{item.text}</p>
                                                : <a href={item.text} className="text-message">{item.text}</a>
                                            }
                                            <span className="time-message">{String(date.getHours()).padStart(2, "0")}:{String(date.getMinutes()).padStart(2, "0")}</span>
                                            {Number(item.fromuserid) !== Number(store.get("user").id) && item.type === 0 &&
                                                <>
                                                    <div className="like-message">
                                                        {item.status === 0 &&
                                                            <>
                                                                <Button
                                                                    shape="circle"
                                                                    size="small"
                                                                    icon={<LikeOutlined />}
                                                                    onClick={(e) => like(e, item.id)}
                                                                />
                                                                <Dropdown overlay={() => dislikeButton(item.id, item.advertid)}>
                                                                    <Button
                                                                        shape="circle"
                                                                        size="small"
                                                                        icon={<DislikeOutlined />}
                                                                    />
                                                                </Dropdown>
                                                            </>
                                                        }
                                                        {item.status === 2 &&
                                                            <Button
                                                                shape="circle"
                                                                size="small"
                                                                type="primary"
                                                                icon={<LikeOutlined />}
                                                            />
                                                        }
                                                        {item.status === 1 &&
                                                        <Button
                                                            shape="circle"
                                                            size="small"
                                                            type="primary"
                                                            danger
                                                            icon={<DislikeOutlined />}
                                                        />
                                                        }

                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        }
                        <Input.TextArea
                            className="textarea"
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                            onPressEnter={sendMessage}
                            rows={4}
                            autoSize={false}
                        />
                        <span className="enter">Нажмите <strong>Enter</strong> для отправки сообщения</span>
                    </Col>
                </Row>
            }
        </>
    )
}