import React, { useState, useEffect } from "react";
import HeaderPage from "../../components/Header";
import {Row, Col} from "antd";
import axios from "axios";
import advert from "../../store/advert";
import NullList from "../../components/NullList";
import AdvertByList from "../../components/AdvertByList";

function Favorites() {
    const [update, setUpdate] = useState(0);
    const [items, setItems] = useState([]);

    const [types, setTypes] = useState({
        type: [],
        region: [],
        room: [],
        typeProperty: []
    });

    useEffect(() => {
        axios.get("/user/favorite")
            .then(success => {
                let data = []
                success.data.map(item => {
                    item.photo = JSON.stringify(item.photo)
                    data.push(item)
                })
                setItems(data)
            });
    }, [update]);

    useEffect(() => {
        axios.get("/object/filterdata")
            .then(success => {
                //console.log(success.data);
                setTypes({
                    type: success.data.type,
                    region: success.data.region,
                    room: success.data.room,
                    typeProperty: success.data.typeproperty
                });
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    return(
        <>
            <HeaderPage type={0}/>

            <div className="page-header-block">
                <h1 className="favorites-h1">Избранное</h1>
            </div>

            {items.length > 0 &&
            <Row>
                <Col span={24}>
                    {items.map(item => <AdvertByList item={item}/>)}
                </Col>
            </Row>
            }
            {items.length === 0 &&
                <NullList title="Вы еще ничего не добавили в избранное" />
            }
        </>
    )
}

export default Favorites;