import { makeAutoObservable, makeObservable } from "mobx";
import axios from "./axios";
import store from "store";
import config from "../config.json";
import createAdvertStore from "./createAdvertStore";
import {message} from "antd";
import categoryStore from "./categoryStore";
import pinWin from "../images/pinWin.svg";
import pin from "../images/pin.svg";

class AdvertStore {
    user = {
        name: ""
    };
    favorite = [];
    adverts = [];
    types = {
        type: [],
        region: [],
        room: [],
        typeProperty: [],
        cities: []
    };

    filter = {
        category: "0",
        type_advert: null,
        region: null,
        pricefrom: null,
        priceto: null,
        filteradvert: []
    }

    filters = [];

    showMapList = false;
    pins = [];

    visibleAdverts = [];

    showParameters = false;

    typeSearch = "list";

    byFilterRoom = {
        type: 0,
        disabled: "disabled",
        filterName: "",
        list: []
    }

    byFilterRoom2 = {
        type: 0,
        disabled: "disabled",
        filterName: "",
        list: []
    }

    coordinates = [0, 0];

    limit = 15;
    page = 1;
    count = -1;
    lastPage = false;

    showModal = false

    constructor() {
        makeAutoObservable(this);
        this.refreshAll();
    }

    refreshAll() {
        this.getMyUser();
        this.getProperties();
    }

    getCoordinatesByCityID(cityID) {
        axios.get('/user/city/' + cityID)
            .then(success => {
                let array = [success.data.latitude, success.data.longitude];
                this.setCoordinates(array);
            })
            .catch(error => {
                message.error(error.response.data.message);
            });
    }

    getCenterMapByCity() {
        let cityID;
        if(store.get("info") && store.get("info").city && store.get("info").city > 0) {
            cityID = store.get("info").city;
        } else {
            cityID = config.cityID;
        }
        this.getCoordinatesByCityID(cityID);
    }

    setCoordinates(value) {
        this.coordinates = value;
    }

    filterRoom(typeID) {
        let array = this.types.room.filter(el => el.typepropertyid === typeID);
        this.byFilterRoom = {
            type: this.types.typeProperty.find(el => el.id === typeID).type,
            disabled: "",
            filterName: this.types.typeProperty.find(el => el.id === typeID).name.filter,
            list: array
        };
    }

    filterRoom2(typeID) {
        let array = this.types.room.filter(el => el.typepropertyid === typeID);
        this.byFilterRoom2 = {
            type: this.types.typeProperty.find(el => el.id === typeID).type,
            disabled: "",
            filterName: this.types.typeProperty.find(el => el.id === typeID).name.filter,
            list: array
        };
    }

    getUser(userID) {

    }

    getFilter(limit=0) {
        let url
        if(limit > 0) {
            url = "?filter[status]=3";
        } else {
            url = "?filter[status]=3&limit=" + this.limit + "&page=" + this.page;
        }

        return url;
    }

    getPins() {
        const nowTime = new Date();
        let url = "/object/pin" + this.getFilter(1);
        axios.get(url)
            .then(success => {
                let features = [];
                success.data.forEach(el => {
                    let pinItem = (el.timepay > 0 && el.timepay > nowTime) ? pinWin : pin;
                    features.push({
                        type: "Feature",
                        id: el.id,
                        geometry: {type: "Point", coordinates: [el.latitude, el.longitude]},
                        options: {
                            iconLayout: 'default#image',
                            iconImageOffset: [-15, -28],
                            draggable: false,
                            iconImageHref: pinItem
                        }
                    })
                })
                this.pins = {
                    type: "FeatureCollection",
                    features: features
                }
                this.getCenterMapByCity();
            })
    }

    getVisibleObjects(objects) {
        this.visibleAdverts = []
        objects.map(e => {
            axios.get('/object/object/' + e + '/').then(success => {
                let data = success.data
                data.photo = JSON.stringify(data.photo)
                this.visibleAdverts.push(data)
            })
        })
    }

    getAdvertsByFilters(category = 0) {
        let query = category;
        query += "?filter[status]=3&limit=" + this.limit + "&page=" + this.page;
        if(this.filter.pricefrom > 0){
            query += "&pricefrom=" + this.filter.pricefrom;
        }
        if(this.filter.priceto > 0){
            query += "&priceto=" + this.filter.priceto;
        }
        if(this.filter.filteradvert.length > 0){
            this.filter.filteradvert.map(e => query += "&filtervalueid[]=" + e.value)
        }
        if(this.filter.type_advert > 0) query += "&filter[typeid]=" + this.filter.type_advert;

        let url = "/object/filterobject/" + query;

        axios.get(url).then(success => {
            if(success.data.count < this.limit) this.lastPage = true;
            if(this.page > 1) {
                success.data.product.map(i => this.adverts.push(i));
            } else {
                this.adverts = success.data.product;
            }
            this.filters = success.data.filter;
        });
    }

    editFilter(name, value, type = 0) {
        this.filter[name] = value;

        if(name === "category") {
            categoryStore.getCategoryFilters(value);
        }

        if(type === 1){
            let index = this.filter.filteradvert.findIndex(e => e.name === name);
            if(index > -1){
                this.filter.filteradvert.splice(index, 1);
            }
            this.filter.filteradvert.push({name: name, value: value});
        }

        this.getAdvertsByFilters(this.filter.category);
    }

    getAdvertsByFilter() {
        let url = "/object/object" + this.getFilter();

        axios.get(url)
            .then(success => {
                if(success.data.items) {
                    this.adverts = success.data.items;
                    this.count = Number(success.data.pagination[1]);
                    this.page = Number(success.data.pagination[0]);
                } else {
                    this.adverts = [];
                    this.count = 0;
                    this.page = 1;
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    getMyUser() {
        axios.get("/user/get")
            .then(success => {
                if(success.data.favorite && success.data.favorite.length > 0) {
                    this.favorite = success.data.favorite;
                } else {
                    this.favorite = [];
                }
            });
    }

    getProperties() {
        axios.get("/object/filterdata")
            .then(success => {
                let array = [];
                if(success.data.typeproperty.length > 0) {
                    success.data.typeproperty.map(item => {
                        item = {...item, name: JSON.parse(item.name)};
                        array = [...array, item];
                    });
                }
                this.types = {
                    type: success.data.type.reverse(),
                    region: success.data.region,
                    room: success.data.room,
                    typeProperty: array,
                    cities: []
                };
                //this.filter.type_object = array[0].id;
                //this.filter.type_advert = success.data.type[0].id;
                this.filterRoom(array[0].id);

            })
            .catch(error => {
                console.log(error.response);
            });
    }

    getFavorite() {
        axios.get("/user/favorite")
            .then(success => {
                this.favorite = success.data;
            });
    }

    like(e, id) {
        e.preventDefault();
        if(store.get("user") && store.get("user").token) {
            axios.post("/user/favorite/" + id)
                .then(success => {
                    this.getMyUser();
                    this.getFavorite();
                })
                .catch(error => console.log(error));
        } else {
            window.location = "/signin";
        }
    }

    getTypeAdvert(typeID) {
        return this.types.type.find(el => el.id === typeID).name;
    }

    getTypeObject(typeID) {
        return this.types.typeProperty.find(el => el.id === typeID).name.name;
    }

    getTypeRoom(typeID) {
        return typeID > 0 ? this.types.room.find(el => el.id === typeID) : "";
    }

    getDatePublic(date) {
        let currentDate = new Date(date * 1000);

        let currentDayOfMonth = currentDate.getDate();

        if(currentDayOfMonth < 10) {
            currentDayOfMonth = "0" + currentDayOfMonth;
        }

        let currentMonth = currentDate.getMonth();

        currentMonth = currentMonth + 1;
        if(currentMonth < 10) {
            currentMonth = "0" + currentMonth;
        }

        let currentYear = currentDate.getFullYear();
        return currentDayOfMonth + "." + currentMonth + "." + currentYear;
    }

    viewContacts(userID) {

    }

    editTypeSearch(value) {
        this.typeSearch = value;
        if(value === "map") {
            this.getPins();
        }
    }

    getReduction(text) {
        return text.charAt(0);
    }

    setPage(page) {
        this.page = page;
        this.getAdvertsByFilter();
    }

    setLimit(limit) {
        this.limit = limit;
        this.getAdvertsByFilter();
    }

    showParametersToggler() {
        this.showParameters === false ? this.showParameters = true : this.showParameters = false;
    }

    filterCategories = [];

    getFilterCategory(id) {
        axios.get("/object/filtercategory/" + id)
            .then(success => {
                if(success.data.nameobject === 1) this.filterCategories.push(success.data);
            })
    }

    getAdvertName(item) {
        let name = "";
        if(item.name !== "" && item.parentid !== 0){
            return item.name;
        } else {
            if(this.types.type.length > 0){
                name += this.types.type.find(el => el.id === item.typeid).name
            }
            if(this.filterCategories.length > 0){
                this.filterCategories.map(f => {
                    let filter = item.filteradvert.find(el => el.filtercategoryid === f.id);
                    if(filter){
                        let filterID = filter.filtervalueid;
                        let filterName = this.getFilterName(filterID);
                        if(!name.includes(filterName)) name += " " + filterName;
                    }
                })
            }
            return name;
        }
    }

    dateConverter = (date) => {
        let dateTimestamp = date * 1000;
        let fullDate = new Date(dateTimestamp);
        let nowDate = new Date();
        let months = ['Янв','Фев','Мар','Апр','Мая','Июн','Июл','Авг','Сен','Окт','Ноя','Дек'];
        fullDate = {
            "year": fullDate.getFullYear(),
            "month": months[fullDate.getMonth()],
            "day": fullDate.getDate(),
            "hour": String(fullDate.getHours()).padStart(2, "0"),
            "minute": String(fullDate.getMinutes()).padStart(2, "0")
        }
        nowDate = {
            "year": nowDate.getFullYear(),
            "month": months[nowDate.getMonth()],
            "day": nowDate.getDate(),
        }
        if(fullDate.year === nowDate.year && fullDate.month === nowDate.month) {
            if(fullDate.day === nowDate.day){
                return "Сегодня, " + fullDate.hour + ":" + fullDate.minute;
            } else if(fullDate.day === (nowDate.day - 1)){
                return "Вчера, " + fullDate.hour + ":" + fullDate.minute;
            } else {
                return fullDate.day + " " + fullDate.month + ", " + fullDate.hour + ":" + fullDate.minute;
            }
        } else if(fullDate.year === nowDate.year) {
            return fullDate.day + " " + fullDate.month + ", " + fullDate.hour + ":" + fullDate.minute;
        } else {
            return fullDate.day + " " + fullDate.month + " " + fullDate.year + ", " + fullDate.hour + ":" + fullDate.minute;
        }
    }

    filterNames = [];

    getFiltersNames(categoryID) {
        axios.get("/object/filtervalue/" + categoryID)
            .then(success => {
                this.filterNames = success.data[0];
            });
    }

    getFilterName(id) {
        let filter = this.filterNames.find(el => el.id === id);
        if(filter){
            return filter.label;
        }
    }

    setAdvertArchive(id) {
        let facts = new FormData();
        facts.append("status", 2);

        axios.post("/object/objectdmin/" + id, facts)
            .then(success => {
                window.location.reload()
            });
    }
}

export default new AdvertStore();