import React from 'react';
import {Col, Row} from "antd";
import logo from "../images/logo.svg";

export default function Footer() {

    return(
        <>
            <footer>
                <Row className="template">
                    <Col xs={24} md={8}>
                        <Row gutter={[0, 10]}>
                            <Col xs={24}>
                                <a href="/about">О компании</a>
                            </Col>
                            <Col xs={24}>
                                <a href="/privacy">Политика конфиденциальности</a>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} md={8} className="footer-logo">
                        <a href="/"><img src={logo} alt="Недвижер" /></a>
                    </Col>
                    <Col xs={24} md={8} className="footer-credits">
                        <a href="https://appsgroup.ru/" target="_blank" rel="noreferrer">Дизайн и разработка сайта - <span>AppsGroup</span></a>
                    </Col>
                </Row>
            </footer>
            <Row className="footer-copyright">
                <Col xs={24}>
                    &copy; Недвижер 2020 - {new Date().getFullYear()} г.
                </Col>
            </Row>
        </>
    )
}