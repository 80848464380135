import {Input, Col, Row, Button, Space} from 'antd';
import HeaderPage from "../../components/Header";
import {observer} from "mobx-react-lite";
import registerStore from "../../store/registerStore";
import InputMask from "react-input-mask";
import React from "react";

const Forgot = observer(() => {

    return(
        <>
            <HeaderPage type={2}/>
            <div className="register">
                <form onSubmit={e => registerStore.restorePassword(e)}>
                    <Row justify="center" align="top">
                        <Col xs={24} sm={12} md={9} lg={8} xl={6} className="register-column">

                            <div className="register-row">
                                <h3>Восстановление пароля</h3>
                            </div>

                            <div className="register-row">
                                <div className="reg-buttons centered">
                                    <Button
                                        className={registerStore.auth.phoneAuth === true ? "white active" : "white"}
                                        onClick={() => registerStore.onChangeAuth("phoneAuth", true)}
                                    >
                                        По номеру телефона
                                    </Button>
                                    <Button
                                        className={registerStore.auth.phoneAuth === false ? "white active" : "white"}
                                        onClick={() => registerStore.onChangeAuth("phoneAuth", false)}
                                    >
                                        По email
                                    </Button>
                                </div>
                            </div>

                            {registerStore.auth.phoneAuth === false &&
                                <div className="register-row">
                                    <label className="label">Ваш email</label>
                                    <Input
                                        disabled={registerStore.waitForCode}
                                        placeholder="email@domain.ru"
                                        value={registerStore.auth.email}
                                        onChange={e => registerStore.onChangeAuth("email", e.target.value)}
                                    />
                                </div>
                            }

                            {registerStore.auth.phoneAuth === true &&
                                <div className="register-row">
                                    <label className="label">Ваш номер телефона</label>
                                    <InputMask
                                        disabled={registerStore.waitForCode}
                                        mask="+7 999-999-99-99"
                                        type="tel"
                                        className="ant-input"
                                        value={registerStore.auth.phone}
                                        onChange={(e) => registerStore.onChangeAuth("phone", e.target.value)}
                                    />
                                </div>
                            }

                            {registerStore.restoreCodeSend === true &&
                                <>
                                    <div className="register-row">
                                        <label className="label">Код подтверждения</label>
                                        <Input
                                            value={registerStore.auth.code}
                                            onChange={e => registerStore.onChangeAuth("code", e.target.value)}
                                        />
                                        <span className="require">Обязательное поле</span>
                                    </div>

                                    <div className="register-row">
                                        <label>Пароль</label>
                                        <Input.Password
                                            value={registerStore.pass.password}
                                            onChange={e => registerStore.onChangePass("password", e.target.value)}
                                        />
                                        <span className="require">Обязательное поле</span>
                                    </div>

                                    <div className="register-row">
                                    <label>Подтверждение пароля</label>
                                    <Input.Password
                                    value={registerStore.pass.confirm}
                                    onChange={e => registerStore.onChangePass("confirm", e.target.value)}
                                    />
                                    <span className="require">Обязательное поле</span>
                                    </div>
                                </>
                            }

                            {registerStore.auth.phoneAuth !== null &&
                                <div className="register-row">
                                    <div className="reg-buttons centered">
                                        <Button htmlType="submit">Восстановить</Button>
                                        <Button
                                            className="white"
                                            href={'/signin'}
                                        >
                                            Авторизация
                                        </Button>
                                    </div>
                                </div>
                            }

                        </Col>
                    </Row>
                </form>
            </div>
        </>
    );
})

export default Forgot