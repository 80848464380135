import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {observer} from "mobx-react-lite";
import {Row, Col, Button, Modal, Input, message, Select} from 'antd';
import store from "store";
import categoryStore from "../store/categoryStore";
import searchStore from "../public/search/searchStore";
import config from "../config.json"
import logo from "../images/logo.svg";
import {ReactComponent as LocPinNew} from "../images/location-pin-new.svg";
import {ReactComponent as MessageIcon} from "../images/message.svg";
import {ReactComponent as HeartIconNew} from "../images/heart_new.svg";
import { YMaps } from "react-yandex-maps";
import ContactForm from "./modules/ContactForm";
import advert from "../store/advert";
import userStore from "../store/userStore";
const { Option } = Select;

const HeaderPage = observer((props) => {
    useEffect(() => {
        if(document.location.pathname !== "/setpassword") {
            if(store.get('setpassword') === true) document.location.href="/setpassword"
        }
        userStore.getUser()
        advert.getProperties()
        categoryStore.getCategoriesTree()
        categoryStore.getAllCategories()
        advert.getFiltersNames(0)
        searchStore.getCityList()
    }, [])

    const [city, setCity] = useState([])

    const [header, setHeader] = useState({
        modal: false,
        enter: false
    });

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showContactForm = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const getPosition = (position) => {
        const latitude  = position.coords.latitude;
        const longitude = position.coords.longitude;

        axios.get("https://geocode-maps.yandex.ru/1.x/?format=json&results=1&geocode=" + longitude + "," + latitude + "&apikey=" + config.apiKeyYandexMaps)
            .then(success => {
                if(success.data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.Address.Components) {
                    let address = success.data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.Address.Components;

                    if(address.find(el => el.kind === "locality")) {
                        axios.get('/user/city?letter=' + address.find(el => el.kind === "locality").name)
                            .then(response => {
                                if(response.data.length > 0) {
                                    let city = response.data[0]
                                    setCityLocal(city.id, city.name)
                                }
                            })
                            .catch(error => {

                            })
                    }
                }
            })
    }

    const errorPosition = () => {
        // console.log("Извините, нет доступной позиции.");
    }

    const geo_options = {
        enableHighAccuracy: true,
        maximumAge        : 30000,
        timeout           : 27000
    };

    useEffect(() => {
        if(!store.get("info")) {
            if ("geolocation" in navigator) {
                navigator.geolocation.watchPosition(getPosition, errorPosition, geo_options);
            }
        }
    }, [])


    useEffect(() => {
        axios.get('/user/city/')
            .then(success => {
                setCity(success.data);
            })
            .catch(error => {
                message.error(error.response.data.message);
            });
    }, [header]);

    const logout = () => {
        store.clearAll();
        document.location.href="/";
    };

    useEffect(() => {
        if(localStorage.getItem('header') !== null && localStorage.getItem('header') === '1') {
            setHeader({ ...header, enter: true });
        } else if(localStorage.getItem('header') === null || localStorage.getItem('header') === '0') {
            setHeader({ ...header, enter: false });
        }
    }, [localStorage]);

    const HeaderForAuthorificate = () => {
        return(
            <Row align="middle" justify="center" className="header-top-row">
                <Col xs={4} md={12} lg={8} className="header-location">
                    <Button
                        onClick={() => setHeader({ ...header, modal: true })}
                        className="blank"
                    >
                        <LocPinNew />{store.get("info") && store.get("info").cityName ? store.get("info").cityName : config.cityName}
                    </Button>
                </Col>
                <Col xs={20} md={12} lg={8} className="page-links">
                    <a href="#" className="colored" onClick={showContactForm}>Бесплатная консультация</a>
                    <a href="/news">Новости</a>
                </Col>
                <Col xs={24} md={12} lg={8} className="page-links header-buttons">
                    <a href="/signin"><HeartIconNew /></a>
                    <a href="/signin"><MessageIcon /></a>
                    <a href="/checkin" className="button-blue"><span>Добавить объявление</span></a>
                    <a href="/signin">Войти</a>
                </Col>
            </Row>
        )
    };

    const HeaderForCreate = () => {
        return(
            <Row align="middle" justify="space-between" className="header-top-row">
                <Col xs={4} md={12} lg={8} className="header-location">
                    <Button
                        onClick={() => setHeader({ ...header, modal: true })}
                        className="blank"
                    >
                        <LocPinNew />{store.get("info") && store.get("info").cityName ? store.get("info").cityName : config.cityName}
                    </Button>
                </Col>
                <Col xs={20} md={12} lg={8} className="page-links">
                    <a href="#" className="colored" onClick={showContactForm}>Бесплатная консультация</a>
                    <a href="/news">Новости</a>
                </Col>
                <Col xs={24} md={12} lg={8} className="page-links header-buttons">
                    <a href="/signin"><HeartIconNew /></a>
                    <a href="/signin"><MessageIcon /></a>
                    <a href="/entity/new" className="button-blue"><span>Добавить объявление</span></a>
                    <a href="/signin">Войти</a>
                </Col>
            </Row>
        )
    };

    const HeaderNotAuthorificate = () => {
        return(
            <Row align="middle" justify="space-between" className="header-top-row">
                <Col xs={4} md={12} lg={8} className="header-location">
                    <Button
                        onClick={() => setHeader({ ...header, modal: true })}
                        className="blank"
                    >
                        <LocPinNew />{store.get("info") && store.get("info").cityName ? store.get("info").cityName : config.cityName}
                    </Button>
                </Col>
                <Col xs={20} md={12} lg={8} className="page-links">
                    <a href="#" className="colored" onClick={showContactForm}>Бесплатная консультация</a>
                    <a href="/news">Новости</a>
                </Col>
                <Col xs={24} md={12} lg={8} className="page-links header-buttons">
                    <a href="/signin"><HeartIconNew /></a>
                    <a href="/signin"><MessageIcon /></a>
                    <a href="/checkin" className="button-blue"><span>Добавить объявление</span></a>
                    <a href="/signin">Войти</a>
                </Col>
            </Row>
        )
    };

    const HeaderAuthorificate = () => {
        return(
            <Row align="middle" justify="space-between" className="header-top-row">
                <Col xs={4} md={12} lg={6} className="header-location">
                    <Button
                        onClick={() => setHeader({ ...header, modal: true })}
                        className="blank"
                    >
                        <LocPinNew />{store.get("info") && store.get("info").cityName ? store.get("info").cityName : config.cityName}
                    </Button>
                </Col>
                <Col xs={20} md={12} lg={6} className="page-links">
                    <a href="#" className="colored" onClick={showContactForm}>Бесплатная консультация</a>
                    <a href="/news">Новости</a>
                </Col>
                <Col xs={24} md={18} lg={12} className="page-links header-buttons">
                    <a href="/favorites"><HeartIconNew /></a>
                    <a href="/chat"><MessageIcon /></a>
                    <a href="/entity/new" className="button-blue"><span>Добавить объявление</span></a>
                    <a href="/entity">Мои объявления</a>
                    <a href="/setting">
                        Кабинет
                        <div className="header-name">{userStore.user.firstname}</div>
                    </a>
                    <a href="#" onClick={logout}>Выйти</a>
                </Col>
            </Row>
        )
    };

    const HeaderPage = () => {
        let item;
        switch (props.type) {
            case 0:
                if(store.get("user") && store.get("user").id > 0) {
                    item = <HeaderAuthorificate />;
                } else {
                    item = <HeaderNotAuthorificate />
                }
                break;
            case 2:
                item = <HeaderForAuthorificate />;
                break;
            case 3:
                item = <HeaderForCreate />;
                break;
        }

        return(
            <>
                {item}
            </>
        )
    };


    const setCityLocal = (id, name) => {
        store.set("info", { city: id, cityName: name });
        setHeader({ ...header, modal: false });
        window.location = "/";
    };

    const GetCityList = () => {
        let items = <p>К сожалению, по Вашему запросу ничего не найдено</p>

        if(city.length > 0) {
            items = city.map((item) => {
                return(
                    <Col xs={24} sm={12} md={8} lg={6} onClick={() => setCityLocal(item.id, item.name)} className="item">{item.name}</Col>
                )
            });
        }

        return(
            <>{items}</>
        )
    };

    const searchCity = (str) => {
        axios.get('/user/city?letter=' + str)
            .then(response => {
                    setCity(response.data);
            })
            .catch(error => {

            })
    };

    const locationUser = (ymaps) => {
        console.log("123132141324235234535634675785679")
        // let _this = this;
        ymaps.geolocation.get({
            provider: 'browser',
            mapStateAutoApply: true
        }).then(function (result) {
            console.log(result.geoObjects.get(0).geometry.getCoordinates());
            // _this.setCenter(result.geoObjects.get(0).geometry.getCoordinates());
        });
    }

    return(
        <header>
            <YMaps
                version={'2.1'}
                query={{
                    apikey: "50d6500a-1178-4721-9006-de55e27a6dc2",
                }}
                modules={["geoObject.addon.editor", "geolocation", "geocode"]}
                onLoad={ymaps => locationUser(ymaps)}
            />
            <HeaderPage />
            <Row align="middle" justify="space-between" className="header-bottom-row">
                <Col xs={24} md={4}>
                    <a href="/" className="header-logo"><img src={logo} alt="Недвижер"/></a>
                </Col>
                <Col xs={24} md={20}>
                    <form onSubmit={e => searchStore.toSearchPage(e)} className="header-search">
                        <Select
                            value={searchStore.searchQuery.type}
                            onChange={(e) => searchStore.onChange("type", e)}
                        >
                            <Option value="0">Любой тип сделки</Option>
                            {advert.types.type.map(item => (
                                <Option value={item.id}>{item.name}</Option>
                            ))}
                        </Select>
                        <Select
                            listHeight={400}
                            value={searchStore.searchQuery.category}
                            onChange={e => searchStore.onChange("category", e)}
                        >
                            <Option value="0">Все категории</Option>
                            {categoryStore.list.map(e => <Option value={e.id}>{e.name}</Option>)}
                        </Select>
                        <Input
                            value={searchStore.searchQuery.string}
                            onChange={e => searchStore.onChange("string", e.target.value)}
                            placeholder="Поиск по объявлениям"
                        />
                        <Select
                            showSearch
                            value={searchStore.searchQuery.city}
                            placeholder="Выберите город"
                            onChange={e => {
                                searchStore.onChange("city", e);
                            }}
                            filterOption={false}
                            onSearch={e => searchStore.filterCityList(e)}
                        >
                            <Option value="0">Любой город</Option>
                            {searchStore.cityListFiltered.map((item) => <Option value={item.id}>{item.name}</Option>)}
                        </Select>
                        <Button
                           htmlType="submit"
                           className="button-blue"
                        >
                            <span>Найти</span>
                        </Button>
                    </form>
                </Col>
            </Row>

            <Modal
                centered
                visible={isModalVisible}
                onCancel={handleCancel}
                width={600}
                footer={null}
            >
                <ContactForm />
            </Modal>

            <Modal
                centered
                visible={header.modal}
                width={1000}
                footer={null}
                onCancel={() => setHeader({ ...header, modal: false })}
            >
                <div className="template-modal">
                    <h3>Выберите свой город</h3>
                    <Input placeholder="Введите название города" onChange={(e) => searchCity(e.target.value)} className="search" />
                    <p className="alphabet">
                        <span onClick={() => searchCity('а')}>а</span>
                        <span onClick={() => searchCity('б')}>б</span>
                        <span onClick={() => searchCity('в')}>в</span>
                        <span onClick={() => searchCity('г')}>г</span>
                        <span onClick={() => searchCity('д')}>д</span>
                        <span onClick={() => searchCity('е')}>е</span>
                        <span onClick={() => searchCity('ж')}>ж</span>
                        <span onClick={() => searchCity('з')}>з</span>
                        <span onClick={() => searchCity('и')}>и</span>
                        <span onClick={() => searchCity('к')}>к</span>
                        <span onClick={() => searchCity('л')}>л</span>
                        <span onClick={() => searchCity('м')}>м</span>
                        <span onClick={() => searchCity('н')}>н</span>
                        <span onClick={() => searchCity('о')}>о</span>
                        <span onClick={() => searchCity('п')}>п</span>
                        <span onClick={() => searchCity('р')}>р</span>
                        <span onClick={() => searchCity('с')}>с</span>
                        <span onClick={() => searchCity('т')}>т</span>
                        <span onClick={() => searchCity('у')}>у</span>
                        <span onClick={() => searchCity('ф')}>ф</span>
                        <span onClick={() => searchCity('х')}>х</span>
                        <span onClick={() => searchCity('ц')}>ц</span>
                        <span onClick={() => searchCity('ч')}>ч</span>
                        <span onClick={() => searchCity('ш')}>ш</span>
                        <span onClick={() => searchCity('щ')}>щ</span>
                        <span onClick={() => searchCity('ы')}>ы</span>
                        <span onClick={() => searchCity('э')}>э</span>
                        <span onClick={() => searchCity('ю')}>ю</span>
                        <span onClick={() => searchCity('я')}>я</span>
                    </p>
                    <Row>
                        <GetCityList />
                    </Row>
                </div>
            </Modal>
        </header>
    )
});

export default HeaderPage;