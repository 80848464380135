import {Input, Col, Row, Button, Space} from 'antd';
import HeaderPage from "../../components/Header";
import {observer} from "mobx-react-lite";
import registerStore from "../../store/registerStore";
import store from "store";
import React from "react";

const SetPassword = observer(() => {
    if(!store.get("user") || !store.get('setpassword') || store.get('setpassword') === false){
        return document.location.href="/";
    }

    return(
        <>
            <HeaderPage type={2}/>
            <div className="register">
                <form onSubmit={e => registerStore.setPasword(e)}>
                    <Row justify="center" align="top">
                        <Col xs={24} sm={12} md={9} xl={8} className="register-column">

                            <div className="register-row">
                                <h3>Установка пароля</h3>
                            </div>

                            <div className="register-row">
                                <label>Пароль</label>
                                <Input.Password
                                    value={registerStore.pass.password}
                                    onChange={e => registerStore.onChangePass("password", e.target.value)}
                                />
                                <span className="require">Обязательное поле</span>
                            </div>

                            <div className="register-row">
                                <label>Подтверждение пароля</label>
                                <Input.Password
                                    value={registerStore.pass.confirm}
                                    onChange={e => registerStore.onChangePass("confirm", e.target.value)}
                                />
                                <span className="require">Обязательное поле</span>
                            </div>

                            <div className="register-row">
                                <Button htmlType="submit">Cохранить</Button>
                            </div>

                        </Col>
                    </Row>
                </form>
            </div>
        </>
    )
});

export default SetPassword;