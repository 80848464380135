import {observer} from "mobx-react-lite";
import {Map, ZoomControl, YMaps, ObjectManager} from "react-yandex-maps";
import advert from "../../store/advert";
import SingleAdvert from "./SingleAdvert";
import {useRef} from "react";


const AdvertsMap = observer(() => {
    const objectManager = useRef(null);
    const map = useRef(null);
    const ymaps = useRef(null);

    const getVisibleObjects = () => {
        if(objectManager.current) {
            let objects = [];
            objectManager.current.objects.each(obj => {
                const isShown = ymaps.current.util.bounds.containsPoint(
                    map.current.getBounds(),
                    obj.geometry.coordinates
                );
                if(isShown) objects.push(obj.id);
            });
            advert.getVisibleObjects(objects.slice(0, 15));
        }
    };


    return(
        <YMaps>
            {advert.visibleAdverts.length > 0 &&
                <div className="objects-list-map-container">
                    <div
                        className="objects-list-show"
                        onClick={_ => advert.showMapList = advert.showMapList !== true}
                    >
                        {advert.showMapList ? "Скрыть объявления" : "Показать объявления"}
                    </div>
                    <div className={advert.showMapList ? "objects-list-map visible" : "objects-list-map"}>
                        {advert.visibleAdverts.map(item => <SingleAdvert item={item}/>)}
                    </div>
                </div>
            }
            <Map
                className="objects-map"
                instanceRef={map}
                onBoundsChange={getVisibleObjects}
                onLoad={ymapsInstance => {
                    ymaps.current = ymapsInstance;
                }}
                modules={["util.bounds"]}
                width="100%"
                state={{center: advert.coordinates, zoom: 12}}
            >
                <ZoomControl options={{ position: {top: 140, right: 10} }} />
                <ObjectManager
                    instanceRef={objectManager}
                    options={{
                        clusterize: true,
                        gridSize: 32
                    }}
                    objects={{
                        openBalloonOnClick: true,
                        preset: "islands#greenDotIcon"
                    }}
                    clusters={{
                        preset: "islands#nightCircleDotIcon"
                    }}
                    defaultFeatures={advert.pins}
                />
            </Map>
        </YMaps>
    );
});

export default AdvertsMap;