import {createRef, useState} from 'react';
import {observer} from "mobx-react-lite";

const Zoom = observer((props) => {

    const [zoom, setZoom] = useState(false);
    const [mouseX, setMouseX] = useState(0);
    const [mouseY, setMouseY] = useState(0);
    const imageContainer = createRef();

    const mouseMovement = (e) => {
        const offsets = imageContainer.current.getBoundingClientRect();
        const x = (((e.pageX - offsets.left) / parseInt(offsets.width, 10)) * 100).toFixed(5);
        const y = (((e.pageY - offsets.top) / parseInt(offsets.height, 10)) * 100).toFixed(5);
        setMouseX(x);
        setMouseY(y);
    }

    return(
        <>
            <div
                className="image-container"
                onMouseOver={() => setZoom(true)}
                onMouseOut={() => setZoom(false)}
                onMouseMove={mouseMovement}
                ref={imageContainer}
            >
                <div
                    className="image"
                    style={{
                        transformOrigin: mouseX + "% " + mouseY + "%",
                        backgroundImage: "url('" + props.image + "')",
                        transform: zoom ? "scale(" + props.zoomScale + ")" : "scale(1.0)",
                    }}
                />
            </div>
        </>
    )
});

export default Zoom;