import React, {useEffect, useState} from 'react';
import NewsList from '../components/NewsList'
import {Button, Col, InputNumber, Row, Select, Radio, Input, Pagination, Modal} from "antd";
import config from "../config.json";
import store from "store";
import HeaderPage from "../components/Header";
import userStore from "../store/userStore";
import {YMaps, Map, Placemark} from 'react-yandex-maps';
import pin from "../images/pin.svg";
import pinWin from "../images/pinWin.svg";
import advert from "../store/advert";
import AdvertByList from "../components/AdvertByList";
import {observer} from "mobx-react-lite";
import homeBg from "../images/homebg.jpg";
import {ReactComponent as PlayIcon} from "../images/play.svg";
import {ReactComponent as CloseIcon} from "../images/close.svg";
import {ReactComponent as ListIcon} from "../images/list.svg";
import {ReactComponent as MapIcon} from "../images/map.svg";
import {ReactComponent as StepOneIcon} from "../images/step1.svg";
import {ReactComponent as StepTwoIcon} from "../images/step2.svg";
import {ReactComponent as StepThreeIcon} from "../images/step3.svg";
import {ReactComponent as StepFourIcon} from "../images/step4.svg";
import {ReactComponent as ArrowRight} from "../images/arrowright.svg";
import SingleQuestion from "../components/modules/SingleQuestion";

const { Option } = Select;

const svg1 = '<svg viewBox="64 64 896 896" focusable="false" data-icon="heart" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M923 283.6a260.04 260.04 0 00-56.9-82.8 264.4 264.4 0 00-84-55.5A265.34 265.34 0 00679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 00-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z"></path></svg>';

const ForOwners = observer(() => {

    useEffect(() => {
        if(advert.filter.type_advert > 0) {
            //advert.getAdvertsByFilter();
        }
        userStore.getQna();
    }, []);

    // const [isModalVisible, setIsModalVisible] = useState(false);
    // const showModal = () => {
    //     setIsModalVisible(true);
    // };
    // const handleCancel = () => {
    //     setIsModalVisible(false);
    // };

    const nowTime = new Date();

    let user = store.get("user");
    let buttonLink = "/entity/new";
    if(typeof user === "undefined"){
        buttonLink = "/signin";
    }

    return(
        <>
            <HeaderPage type={0}/>
            {/*<Modal*/}
            {/*    width={"inherit"}*/}
            {/*    title={false}*/}
            {/*    visible={isModalVisible}*/}
            {/*    onCancel={handleCancel}*/}
            {/*    footer={false}*/}
            {/*    className="video-modal"*/}
            {/*>*/}
            {/*    <div className="video-embed">*/}
            {/*        <iframe width="560" height="315" src="https://www.youtube.com/embed/CgjiN0_-PQU"*/}
            {/*            title="YouTube video player" frameBorder="0"*/}
            {/*            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
            {/*            allowFullScreen />*/}
            {/*    </div>*/}
            {/*</Modal>*/}
            <div className="home">
                <Row className="home-header fullSized">
                    <Col xs={24} md={12} xl={9} className="left-side">
                        <h1>Новый взгляд на поиск недвижимости</h1>
                        {/*<p>Оставьте заявку на подбор и получите подходящие варианты бесплатно! Недвижер – бесплатно, просто, удобно</p>*/}
                        <p>1. Посмотрите видео<ArrowRight/></p>
                        <p>2. Оставьте бесплатно заявку</p>
                        <p>3. Получите подходящие варианты</p>
                        <Button
                            type="primary"
                            className="rightSided"
                            //onClick={showModal}
                            href={buttonLink}
                        >
                            Оставить заявку<PlayIcon />
                        </Button>
                    </Col>
                    <Col xs={24} md={12} xl={15} className="home-bg">
                        <div className="video-embed">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/CgjiN0_-PQU"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen />
                        </div>
                        {/*<div className="bg-inner">*/}
                        {/*    <img src={homeBg} alt="Новый взгляд на поиск недвижимости"/>*/}
                        {/*</div>*/}
                    </Col>
                </Row>
                <Row className="home-filters">
                    <Col xs={12} xl={3} className="filter-item">
                        <Select
                            placeholder="Тип сделки"
                            value={advert.filter.type_advert}
                            onChange={(e) => advert.editFilter("type_advert", e)}
                        >
                            {advert.types.type.length > 0 && advert.types.type.map(item => (
                                <Option value={item.id}>{item.name}</Option>
                            ))}
                        </Select>
                    </Col>
                    {advert.filter.type_advert === 3 &&
                    <>
                        <Col xs={12} xl={5} className="filter-item">
                            <Select
                                placeholder="Что меняем?"
                                value={advert.filter.type_object2}
                                onChange={(e) => advert.editFilter("type_object2", e)}
                            >
                                {advert.types.typeProperty.length > 0 && advert.types.typeProperty.map(item => (
                                    <Option value={item.id}>{item.name.name} на</Option>
                                ))}
                            </Select>
                        </Col>
                    </>
                    }
                    <Col xs={12} xl={5} className="filter-item">
                        <Select
                            placeholder="Тип недвижимости"
                            value={advert.filter.type_object}
                            onChange={(e) => advert.editFilter("type_object", e)}
                        >
                            {advert.types.typeProperty.length > 0 && advert.types.typeProperty.map(item => (
                                <Option value={item.id}>{item.name.name}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={12} xl={5} className="filter-item">
                        <Select
                            placeholder="Выберите район"
                            onChange={(e) => advert.editFilter("region", e)}
                        >
                            {store.get("info") && store.get("info").city && store.get("info").city > 0
                                ?
                                <>
                                    <Select.Option value={null}>Все районы</Select.Option>
                                    {advert.types.region.filter(el => el.cityid === store.get("info").city).map(item => (
                                        <Select.Option value={item.id} key={"regionSelect" + item.id}>{item.name}</Select.Option>
                                    ))}
                                </>
                                :
                                <>
                                    <Select.Option value={null}>Все районы</Select.Option>
                                    {advert.types.region.filter(el => el.cityid === config.cityID).map(item => (
                                        <Select.Option value={item.id}>{item.name}</Select.Option>
                                    ))}
                                </>
                            }
                        </Select>
                    </Col>
                    <Col
                        xs={24}
                        xl={advert.filter.type_advert === 3 ? 6 : 11}
                        className="filter-buttons"
                    >
                        <Button
                            type="primary"
                            className="inverted rightSided"
                            onClick={() => advert.showParametersToggler()}
                        >
                            {advert.showParameters === true ? (
                                <>Скрыть<CloseIcon /></>
                            ) : (
                                <>Параметры</>
                            )}
                        </Button>
                        <Button type="primary" onClick={() => advert.getAdvertsByFilter()}>Найти заявку</Button>
                    </Col>
                    {advert.showParameters === true &&
                        <>
                            {advert.filter.type_advert === 3 &&
                            <>
                                {advert.byFilterRoom2.type === 1 &&
                                    <Col xs={24} sm={12} xl={4} className="filter-item border-top">
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            placeholder="К-во комнат (обмен)"
                                            value={advert.filter.room2}
                                            onChange={(e) => advert.editFilter("room", e)}
                                            disabled={advert.byFilterRoom2.disabled}
                                        >
                                            {advert.byFilterRoom.list.length > 0 && advert.byFilterRoom.list.map(item => (
                                                <Option value={item.id}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                }
                                {advert.byFilterRoom2.type === 2 &&
                                    <Col xs={24} xl={10} className="filter-item filter-inputs border-top">
                                        <span>Площадь (обмен)</span>
                                        <div>
                                            <span>От</span>
                                            <InputNumber
                                                min={0}
                                                placeholder="0"
                                                formatter={value => `${value} м. кв`}
                                                parser={value => value.replace(/(м. кв| м. кв)/, '')}
                                                onChange={(e) => advert.editFilter("valfrom2", e)}
                                            />
                                        </div>
                                        <div>
                                            <span>До</span>
                                            <InputNumber
                                                min={0}
                                                placeholder="0"
                                                formatter={value => `${value} м. кв`}
                                                parser={value => value.replace(/(м. кв| м. кв)/, '')}
                                                onChange={(e) => advert.editFilter("valto2", e)}
                                            />
                                        </div>
                                    </Col>
                                }
                                {advert.byFilterRoom2.type === 3 &&
                                <>
                                    <Col xs={24} sm={12} xl={5} className="filter-item filter-inputs border-top">
                                        <Input
                                            placeholder="Введите ориентир (обмен)"
                                            onChange={(e) => advert.editFilter("stringroom2", e)}
                                        />
                                    </Col>
                                </>
                                }
                            </>
                            }
                            {advert.byFilterRoom.type === 1 &&
                                <Col xs={24} sm={12} xl={4} className="filter-item border-top">
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        placeholder="К-во комнат"
                                        value={advert.filter.room}
                                        onChange={(e) => advert.editFilter("room", e)}
                                        disabled={advert.byFilterRoom.disabled}
                                    >
                                        {advert.byFilterRoom.list.length > 0 && advert.byFilterRoom.list.map(item => (
                                            <Option value={item.id}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Col>
                            }
                            {advert.byFilterRoom.type === 2 &&
                                <Col xs={24} xl={10} className="filter-item filter-inputs border-top">
                                    <span>Площадь</span>
                                    <div>
                                        <span>От</span>
                                        <InputNumber
                                            min={0}
                                            placeholder="0"
                                            formatter={value => `${value} м. кв`}
                                            parser={value => value.replace(/(м. кв| м. кв)/, '')}
                                            onChange={(e) => advert.editFilter("valfrom", e)}
                                        />
                                    </div>
                                    <div>
                                        <span>До</span>
                                        <InputNumber
                                            min={0}
                                            placeholder="0"
                                            formatter={value => `${value} м. кв`}
                                            parser={value => value.replace(/(м. кв| м. кв)/, '')}
                                            onChange={(e) => advert.editFilter("valto", e)}
                                        />
                                    </div>
                                </Col>
                            }
                            {advert.byFilterRoom.type === 3 &&
                            <>
                                <Col xs={24} sm={12} xl={5} className="filter-item filter-inputs border-top">
                                    <Input
                                        placeholder="Введите ориентир"
                                        onChange={(e) => advert.editFilter("stringroom", e)}
                                    />
                                </Col>
                            </>
                            }
                            <Col xs={24} xl={10} className="filter-item filter-inputs border-top">
                                <span>Стоимость</span>
                                <div>
                                    <span>От</span>
                                    <InputNumber
                                        min={0}
                                        placeholder="0"
                                        formatter={value => `${value} ₽`}
                                        parser={value => value.replace(/(₽| ₽)/, '')}
                                        onChange={(e) => advert.editFilter("price_from", e)}
                                    />
                                </div>
                                <div>
                                    <span>До</span>
                                    <InputNumber
                                        min={0}
                                        placeholder="0"
                                        formatter={value => `${value} ₽`}
                                        parser={value => value.replace(/(₽| ₽)/, '')}
                                        onChange={(e) => advert.editFilter("price_to", e)}
                                    />
                                </div>
                            </Col>
                            <Col className="filter-item border-top fillerCol">&nbsp;</Col>
                        </>
                    }
                </Row>
                <Row className="home-results-head home-block-head" align="middle">
                    <Col xs={24} md={12}>
                        <h2>Более 20 000 заявок в нашей базе</h2>
                    </Col>
                    <Col xs={24} md={12} className="results-filter">
                        {advert.adverts.length > 0 &&
                        <Radio.Group defaultValue="list" buttonStyle="solid" onChange={(e) => advert.editTypeSearch(e.target.value)}>
                            <Radio.Button value="list"><ListIcon />Список</Radio.Button>
                            <Radio.Button value="map"><MapIcon />Карта</Radio.Button>
                        </Radio.Group>
                        }
                    </Col>
                </Row>
                <Row justify="center">
                    {advert.typeSearch === "list" &&
                        <Col xs={24}>
                            {advert.adverts.length > 0 && advert.adverts.map(item =>
                                <AdvertByList item={item} />
                            )}
                            <Pagination
                                current={advert.page}
                                total={advert.count}
                                defaultPageSize={advert.limit}
                                onChange={e => advert.setPage(e)}
                                onShowSizeChange={(e, pageSize) => advert.setLimit(pageSize)}
                            />
                        </Col>
                    }
                    {advert.typeSearch === "map" &&
                        <Col xs={24} lg={18}>
                            <YMaps>
                                <Map
                                    state={{ center: advert.coordinates, zoom: 9 }}
                                    width={100 + "%"}
                                    height={570}
                                    modules={['geoObject.addon.balloon']}
                                >
                                    {advert.pins.length > 0 && advert.pins.map(item =>
                                        item.timepay > 0 && item.timepay > nowTime
                                            ? <Placemark
                                                geometry={[item.latitude, item.longitude]}
                                                options={{
                                                    iconLayout: 'default#image',
                                                    iconImageOffset: [-15, -28],
                                                    draggable: true,
                                                    iconImageHref: pinWin
                                                }}
                                                // properties={{
                                                //     balloonContent:
                                                //             {advert.getTypeAdvert(item.typeid)}
                                                //             {advert.getTypeObject(item.typepropertyid)}
                                                //             {advert.getTypeRoom(item.roomid)}
                                                //             <button onClick={(e) => advert.like(e, item.id)}>{svg1}</button>
                                                //
                                                // }}
                                                key={item.id}
                                                onClick={() => document.location.href="/user/" + item.userid + "/entity/" + item.id}
                                            />
                                            : <Placemark
                                                geometry={[item.latitude, item.longitude]}
                                                options={{
                                                    iconLayout: 'default#image',
                                                    iconImageOffset: [-15, -28],
                                                    draggable: true,
                                                    iconImageHref: pin
                                                }}
                                                // properties={{
                                                //     balloonContent:
                                                //             {advert.getTypeAdvert(item.typeid)}
                                                //             {advert.getTypeObject(item.typepropertyid)}
                                                //             {advert.getTypeRoom(item.roomid)}
                                                //             <button onClick={(e) => advert.like(e, item.id)}>{svg1}</button>
                                                //
                                                // }}
                                                key={item.id}
                                                onClick={() => document.location.href="/user/" + item.userid + "/entity/" + item.id}
                                            />

                                    )}
                                </Map>
                            </YMaps>
                        </Col>
                    }
                </Row>
                <Row className="fullSized home-how" align="stretch" gutter={[20, 20]}>
                    <Col xs={24} className="home-block-head">
                        <h2>Как найти подходящую именно Вам недвижимость бесплатно?</h2>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <div className="how-single-item">
                            <div className="item-head">
                                <StepOneIcon />
                                <span className="step-circle">&nbsp;</span>
                                <span className="step-circle">&nbsp;</span>
                                <span className="step-circle">&nbsp;</span>
                                <span className="step-circle">&nbsp;</span>
                                <span className="step-circle">&nbsp;</span>
                                <span className="step-circle">&nbsp;</span>
                            </div>
                            <div className="item-content">Оставить заявку в сервисе «Недвижер»</div>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <div className="how-single-item">
                            <div className="item-head">
                                <StepTwoIcon />
                                <span className="step-circle">&nbsp;</span>
                                <span className="step-circle">&nbsp;</span>
                                <span className="step-circle">&nbsp;</span>
                                <span className="step-circle">&nbsp;</span>
                                <span className="step-circle">&nbsp;</span>
                                <span className="step-circle">&nbsp;</span>
                            </div>
                            <div className="item-content">Получить несколько предложений в течении 2х дней</div>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <div className="how-single-item">
                            <div className="item-head">
                                <StepThreeIcon />
                                <span className="step-circle">&nbsp;</span>
                                <span className="step-circle">&nbsp;</span>
                                <span className="step-circle">&nbsp;</span>
                                <span className="step-circle">&nbsp;</span>
                                <span className="step-circle">&nbsp;</span>
                                <span className="step-circle">&nbsp;</span>
                            </div>
                            <div className="item-content">Провести переговоры с собственниками</div>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <div className="how-single-item">
                            <div className="item-head">
                                <StepFourIcon />
                            </div>
                            <div className="item-content">Заключить сделку</div>
                        </div>
                    </Col>
                    <Col xs={24} className="how-button">
                        {store.get("user") && store.get("user").id && store.get("user").id > 0 ? (
                            <>
                                <Button type="primary" href="/entity/new">Добавить заявку</Button>
                            </>
                        ) : (
                            <>
                                <Button type="primary" href="/signin">Добавить заявку</Button>
                            </>
                        )}
                    </Col>
                </Row>
                <Row className="home-block-head" align="middle">
                    <Col xs={24}>
                        <h2>Вопросы и ответы</h2>
                    </Col>
                </Row>
                <Row gutter={[20, 20]}>
                    {userStore.qnaList.map(item => <SingleQuestion item={item} /> )}
                </Row>
                <NewsList all={true} pagination={false} count={4}/>
            </div>
        </>
    )
});

export default ForOwners;