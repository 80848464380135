import {observer} from "mobx-react-lite";
import HeaderPage from "./Header";
import React from "react";
import {Row, Col} from "antd";

const pricePage = observer(() => {

    return(
        <>
            <HeaderPage type={0}/>
            <Row className="advert" gutter={[30, 0]}>
                <Col xs={24} sm={8} className="page-header-block">
                    <h1 className="favorites-h1">Стоимость оказываемых услуг</h1>
                </Col>
                <Col xs={24}>
                    <p>Стоимость выделения заявки на 7 дней - 49 рублей.</p>
                    <p>Стоимость выделения и поднятия заявки в топ на 7 дней - 99 рублей.</p>
                </Col>
            </Row>
        </>
    )
});

export default pricePage;